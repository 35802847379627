import { Box } from "@mui/material";
import { Create, useNotify } from "react-admin";
import { handleHttpError } from "shared/src/utils/errorHandler";

export const RaCreateView = (props) => {
    const notify = useNotify();
    const mutationOptions = props.mutationOptions || {};

    if (!mutationOptions.onError) {
        mutationOptions.onError = (error) => {
            handleHttpError(error, notify);
        };
    }

    return (
        <Box marginTop={5} position={"relative"}>
            <Create {...props} mutationOptions={ mutationOptions }>{props.children}</Create>
        </Box>
    );
};
