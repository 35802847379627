import {
    ShowButton,
    usePermissions,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { RaButtonWrapper } from "./RaButtonWrapper";

export const RaShowButton = () => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (
        isLoading ||
        !permissions.vote({
            resource,
            attribute: "read",
            subject: record,
        })
    ) {
        return <></>;
    }

    return (
        <RaButtonWrapper type="show" title={translate("equip.show")}>
            <ShowButton record={record} />
        </RaButtonWrapper>
    );
};
