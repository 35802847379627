import {convertFileToBase64} from "../utils/uploadHandler";
import dayjs from 'dayjs';

const mediaConverter = {
    convertOut: (object) => {
        // Freshly dropped file is a File object and must be converted to base64 string
        if (object.files) {
            return new Promise((resolve) => {
                convertFileToBase64(object.files[0]).then((base64File) => {
                    resolve({
                        ...object,
                        files: [base64File.split(",")[1]],
                    });
                });
            });
        }
        return object;
    },
    convertIn: (responseData) => {
        if (responseData._links?.download) {
            responseData.url = responseData._links.download;
        }

        if (responseData.size) {
            responseData.size = formatBytes(responseData.size);
        }

        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }
        
        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        return responseData;
    },
};

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default mediaConverter;
