import { SelectInput, useTranslate } from "react-admin";
import { Box } from "@mui/material";

export const RaSelectInput = ({ label, source, ...props }) => {
    const translate = useTranslate();

    const OptionText = (option) => {
        return (
            <Box
                sx={{ width: "100%" }}
                title={translate(option.name.toString())}
            >
                {translate(option.name.toString())}
            </Box>
        );
    };

    return (
        <SelectInput
            label={label || `equip.${source}`}
            source={source}
            title={translate(label || `equip.${source}`)}
            optionText={OptionText}
            {...props}
        />
    );
};
