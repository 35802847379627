import {
    DateInput,
    required,
    SimpleForm,
    TextInput,
    usePermissions,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaCreateView } from "../components/views/RaCreateView";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";

export const RentalCreate = (props) => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaCreateView {...props} actions={<ToolbarWithHistory />}>
            <SimpleForm redirect="show">
                <TextInput
                    title={translate("equip.name")}
                    label="equip.name"
                    source="name"
                    validate={[required()]}
                />
                <DateInput
                    title={translate("equip.startDate")}
                    label="equip.startDate"
                    source="startDate"
                    validate={[required()]}
                />
                <DateInput
                    title={translate("equip.endDate")}
                    label="equip.endDate"
                    source="endDate"
                    validate={[required()]}
                />
            </SimpleForm>
        </RaCreateView>
    );
};
