import { ReferenceManyField, useRecordContext } from "react-admin";

/**
 * A wrapper around React-Admin ReferenceManyField component that will pass the record
 * in the filter when calling the dataProvider, giving it access to the _embedded and _links properties.
 */
export const ReferenceManyFieldWithLinkSupport = (props) => {
    const { filter, ...rest } = props;
    const record = useRecordContext();

    return (
        <ReferenceManyField
            {...rest}
            filter={{
                // Use a very special key to ensure it does not conflict with any other filter
                "@@record": record,
                ...filter,
            }}
        />
    );
};
