import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Form, TextInput, required, useTranslate } from "react-admin";

export const AddCommentDialog = ({ record, dialog, closeDialog, submit }) => {
    const translate = useTranslate();

    return (
        <Dialog open={dialog} fullWidth>
            <DialogContent>
                <Form onSubmit={(e) => submit(e, false)}>
                    <Typography>{translate("equip.addComments")}</Typography>
                    <TextInput
                        defaultValue={record?.comment}
                        rows={3}
                        source="comment"
                        validate={[required()]}
                        multiline
                        fullWidth
                        title={translate("equip.comment")}
                    />
                    <Box display="flex" justifyContent={"space-between"}>
                        <Button
                            title={translate("equip.add")}
                            aria-label={translate("equip.add")}
                            variant="contained"
                            type="submit"
                        >
                            {translate("equip.add")}
                        </Button>
                        <Button
                            title={translate("equip.cancel")}
                            aria-label={translate("equip.cancel")}
                            onClick={closeDialog}
                        >
                            {translate("equip.cancel")}
                        </Button>
                    </Box>
                </Form>
            </DialogContent>
        </Dialog>
    );
};
