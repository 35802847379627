import {
    CreateButton,
    usePermissions,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { RaButtonWrapper } from "./RaButtonWrapper";

export const RaCreateButton = () => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (
        isLoading ||
        !permissions.vote({
            resource,
            attribute: "create",
            subject: record,
        })
    ) {
        return <></>;
    }

    return (
        <RaButtonWrapper type="create" title={translate("equip.create")}>
            <CreateButton />
        </RaButtonWrapper>
    );
};
