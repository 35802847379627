import {
    NumberInput,
    ReferenceInput,
    TextInput,
    Form,
    Toolbar,
    DateInput,
    useCreate,
    useUpdate,
    useDelete,
    useTranslate,
    useRefresh,
    usePermissions,
    useNotify,
} from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { RaAutocompleteInput } from "../../../../components/inputs/Ra/RaAutocompleteInput";

export const EditMassArrival = (props) => {
    const [update] = useUpdate();
    const [create] = useCreate();
    const [deleteOne] = useDelete("arrivals", {
        id: props.arrivalData?.id,
        previousData: props.arrivalData,
    });
    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();
    const { isLoading, permissions } = usePermissions();

    const deleteArrival = (e) => {
        deleteOne();
        props.setArrivalData();
        props.setDeliveryWindow();
    };

    const submit = (data) => {
        const newData = {
            ...(!props.arrivalData && { product: data.id }),
            ...(!props.arrivalData && { supplier: data.supplierId }),
            purchaseOrderNumber: data.purchaseOrderNumber,
            orderDate: data.orderDate,
            deliveryDate: data.deliveryDate,
            quantity: data.quantity,
            defectQuantity: data.defectQuantity,
            purchasePrice: data.purchasePrice,
            comment: data.comment,
        };
        if (props.arrivalData) {
            update(
                "arrivals",
                {
                    id: props.arrivalData.id,
                    data: newData,
                    previousData: props.arrivalData,
                },
                {
                    onSettled: () => {
                        refresh();
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
        } else {
            create(
                "arrivals",
                {
                    data: newData,
                },
                {
                    onSettled: () => {
                        refresh();
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
        }
        props.setArrivalData();
        props.setDeliveryWindow();
    };

    const validateDate = (values) => {
        const errors = {};
        if (values.deliveryDate < values.orderDate) {
            errors.deliveryDate = translate("equip.validateDeliveryDate");
            errors.orderDate = translate("equip.validateOrderDate");
        }
        if (!values.supplierId) {
            errors.supplierId = translate("equip.error_empty_supplierId");
        }
        return errors;
    };

    if (isLoading) {
        return <></>;
    }
    return (
        <Form onSubmit={submit} record={props.arrivalData} validate={validateDate}>
            <Box p="1em" sx={{ paddingTop: 3 }}>
                <Box
                    sx={{
                        display: "flex",
                        pb: 2,
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="p" sx={{ paddingLeft: 1 }}>
                        {translate(props.arrivalData ? "equip.editArrival" : "equip.newDelivery")}
                    </Typography>
                    {props.arrivalData && (
                        <Button
                            title={translate("equip.show")}
                            aria-label={translate("equip.show")}
                            sx={{ marginRight: 1 }}
                            size="small"
                            variant="text"
                            onClick={() => props.setDeliveryWindow("show")}
                        >
                            {translate("equip.show")}
                        </Button>
                    )}
                </Box>

                <Box display="flex" sx={{ paddingX: 1 }}>
                    <Box flex={1} mr="1em">
                        <TextInput
                            title={translate("equip.purchaseOrderNumber")}
                            label="equip.purchaseOrderNumber"
                            source="purchaseOrderNumber"
                            fullWidth
                            required
                            defaultValue={props.arrivalData?.purchaseOrderNumber}
                        />

                        <DateInput
                            title={translate("equip.orderDate")}
                            label="equip.orderDate"
                            source="orderDate"
                            required
                            defaultValue={props.arrivalData?.orderDate}
                        />
                        <DateInput
                            title={translate("equip.deliveryDate")}
                            label="equip.deliveryDate"
                            source="deliveryDate"
                            defaultValue={props.arrivalData?.deliveryDate}
                            required
                        />

                        <NumberInput
                            title={translate("equip.purchasePricePerItem")}
                            label="equip.purchasePricePerItem"
                            source="purchasePrice"
                            fullWidth
                            defaultValue={props.arrivalData?.purchasePrice}
                            required
                        />
                        <NumberInput
                            title={translate("equip.deliveredOk")}
                            label="equip.deliveredOk"
                            source="quantity"
                            fullWidth
                            defaultValue={props.arrivalData?.quantity}
                            required
                        />
                        <NumberInput
                            title={translate("equip.deliveredDefect")}
                            label="equip.deliveredDefect"
                            source="defectQuantity"
                            fullWidth
                            defaultValue={
                                props.arrivalData?.defectQuantity
                                    ? props.arrivalData?.defectQuantity
                                    : 0
                            }
                            required
                        />
                    </Box>

                    <Box flex={1} ml="1em">
                        {!props.arrivalData && (
                            <ReferenceInput
                                record={props.arrivalData}
                                resource="arrivals"
                                source="supplierId"
                                reference="suppliers"
                                sort={{ field: "name", order: "ASC" }}
                            >
                                <RaAutocompleteInput
                                    label="equip.supplier"
                                    fullWidth
                                    isRequired={true}
                                    field="name"
                                />
                            </ReferenceInput>
                        )}
                        <TextInput
                            label="equip.comment"
                            title={translate("equip.comment")}
                            source="comment"
                            fullWidth
                            rows={3}
                            multiline
                        />
                    </Box>
                </Box>
            </Box>
            <Toolbar
                sx={{
                    background: "inherit",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        title={translate("ra.action.save")}
                        aria-label={translate("ra.action.save")}
                    >
                        {translate("ra.action.save")}
                    </Button>
                    {props.arrivalData &&
                        permissions.vote({
                            resource: "arrivals",
                            attribute: "delete",
                            subject: props.arrivalData,
                        }) && (
                            <Button
                                variant="text"
                                color="error"
                                size="small"
                                startIcon={<DeleteIcon />}
                                onClick={deleteArrival}
                                title={translate("ra.action.delete")}
                                aria-label={translate("ra.action.delete")}
                            >
                                {translate("ra.action.delete")}
                            </Button>
                        )}
                </Box>
                <Box>
                    <Button
                        onClick={() => props.setDeliveryWindow(false, props.setArrivalData())}
                        title={translate("equip.close")}
                        aria-label={translate("equip.close")}
                    >
                        {translate("equip.close")}
                    </Button>
                </Box>
            </Toolbar>
        </Form>
    );
};
