import {
    ImageField,
    TextField,
    useRecordContext,
    RichTextField,
    Show,
    SimpleShowLayout,
    useTranslate,
    usePermissions,
    useNotify,
} from "react-admin";
import { Button, Grid } from "@mui/material";
import { downloadFile } from "shared/src/utils/downloadHandler";
import { Box } from "@mui/system";

export const ShowView = ({ mediaTools, setMediaTools }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }
    return (
        <Show resource="media" id={mediaTools.id}>
            <Grid container sx={{ p: 2 }}>
                <Grid item sm={mediaTools.record?._links.thumbnail ? 4 : 12}>
                    <SimpleShowLayout>
                        <TextField source="type" />
                        <TextField label="equip.name" source="shortname" />
                        {mediaTools.type === "text" && (
                            <RichTextField
                                sx={{ padding: "0 25px 0 0", overflowWrap: "anywhere" }}
                                source="content"
                            />
                        )}
                        {mediaTools.type === "link" && <ExternalLink />}
                    </SimpleShowLayout>
                </Grid>
                <Grid item sm={mediaTools.record?._links.thumbnail ? 7 : 0}>
                    {mediaTools.record?.mimetype !== "application/pdf" && (
                        <SimpleShowLayout>
                            {mediaTools.record?._links.thumbnail && (
                                <ImageField label="equip.preview" source="_links.thumbnail" />
                            )}
                        </SimpleShowLayout>
                    )}
                </Grid>
            </Grid>
            <Box sx={{ px: 4, pb: 2, display: "flex", justifyContent: "space-between" }}>
                {mediaTools.type === "file" &&
                permissions.vote({
                    resource: "media",
                    attribute: "download",
                    subject: mediaTools.record,
                }) ? (
                    <Button
                        onClick={() => {
                            downloadFile(
                                mediaTools.record._links.download,
                                null,
                                mediaTools.record.shortname,
                                notify
                            );
                        }}
                        title={translate("equip.download")}
                        aria-label={translate("equip.download")}
                    >
                        {translate("equip.download")}
                    </Button>
                ) : (
                    <Box />
                )}
                <Button
                    onClick={() =>
                        setMediaTools({
                            edit: null,
                            type: null,
                            id: null,
                            record: null,
                        })
                    }
                    title={translate("equip.close")}
                    aria-label={translate("equip.close")}
                >
                    {translate("equip.close")}
                </Button>
            </Box>
        </Show>
    );
};

const ExternalLink = () => {
    const record = useRecordContext();
    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={record.url} target="_blank">
            {record.url}
        </a>
    );
};
