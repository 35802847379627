import { useEffect } from "react";
import { Datagrid, useListContext } from "react-admin";
import { useNavigate } from "react-router";
import { queryToNoHashLink } from "shared/src/utils/decodeQuery";

export const DatagridWithHistory = ({
    listItems,
    setListItems,
    rowClick,
    ...props
}) => {
    const navigate = useNavigate();
    const { resource } = useListContext();
    const { data } = useListContext();

    const redirectWithHistory = (id) => {
        navigate({
            pathname: `/${resource}/${id}/show`,
            search: `?from=${queryToNoHashLink()}`,
        });
    };

    useEffect(() => {
        if (listItems) {
            if (data && listItems !== data) {
                setListItems(data);
            }
        }
    }, [listItems, data, setListItems]);

    if (!rowClick) {
        rowClick = redirectWithHistory;
    }

    return <Datagrid {...props} rowClick={rowClick} />;
};
