import {
    queryFromHrefToFilter,
    queryToNoHashLink,
    queryToView,
} from "./decodeQuery";
import { handleHttpError } from "./errorHandler";

export const extractBreadcrumbs = async ({
    record,
    locale,
    notify,
    dataProvider,
}) => {
    const categoryFilters = localStorage["RaStore.products.listParams"];
    const links = queryToNoHashLink().split("from=").reverse();
    const arr = [];

    const requestCategoryData = async (
        id,
        categories,
        allBreadcrumbs,
        hashElements
    ) => {
        let hashParts = hashElements;
        if (!hashElements) {
            hashParts = [];
            const categoryHash = allBreadcrumbs[0].href;

            switch (true) {
                case categoryHash.split(`%22:%22${id}`).length === 2:
                    hashParts[0] =
                        categoryHash.split(`%22:%22${id}`)[0] + `%22:%22`;
                    hashParts[1] = categoryHash.split(`%22:%22${id}`)[1];
                    break;
                default:
                    hashParts[0] = `#/categories?filter=%7B"category":"`;
                    hashParts[1] = `"%7D&order=ASC`;
            }
        }
        try {
            const response = await dataProvider.getOne("categories", {
                id: id,
            });
            categories.push({
                name: response.data.name,
                href: hashParts[0] + id + hashParts[1],
                item: response.data.name,
                view: "equip.viewAll",
            });

            if (response.data.hasParent) {
                try {
                    const parentResponse = await dataProvider.getParentNode(
                        "categories",
                        {
                            childId: id,
                        }
                    );
                    return requestCategoryData(
                        parentResponse.data.id,
                        categories,
                        allBreadcrumbs,
                        hashParts
                    );
                } catch (error) {
                    handleHttpError(error, notify);
                }
            } else {
                return [
                    allBreadcrumbs[0],
                    ...categories.reverse(),
                    ...allBreadcrumbs.splice(1, allBreadcrumbs.length),
                ];
            }
        } catch (error) {
            handleHttpError(error, notify);
        }
    };

    const finishItemRequests = async (
        allBreadcrumbs,
        params,
        i,
        isCategory
    ) => {
        const categoryId = queryFromHrefToFilter(allBreadcrumbs[0].href)
            ?.category;

        if (i === allBreadcrumbs.length - 1) {
            if (isCategory) {
                if (categoryId) {
                    return await requestCategoryData(
                        categoryId,
                        [],
                        allBreadcrumbs
                    );
                } else {
                    return [
                        allBreadcrumbs[0],
                        {
                            name: "equip.all",
                            href: allBreadcrumbs[0].href,
                            view: "equip.viewAll",
                        },
                        ...allBreadcrumbs.splice(1, allBreadcrumbs.length),
                    ];
                }
            } else {
                return allBreadcrumbs;
            }
        }
    };

    const extractBreadcrumbs = (hash, index) => {
        const resource = hash.split("/")[1]?.split("/")[0].split("?")[0];
        const firstParam = hash.split("/")[2]?.split("/")[0].split("?")[0];

        const id = parseInt(firstParam);
        let hrefParts = links.slice(0, index + 1).reverse();

        const view = queryToView(hash);
        if (view === "ra.action.edit") {
            hrefParts[0] = `/${resource}/${id}/show?`;
        }
        return {
            resource: resource,
            href: "#" + hrefParts.join("from="),
            view: view,
            id: id,
            name: "equip." + resource,
        };
    };

    const getItemsData = async (allBreadcrumbs) => {
        const isCategory =
            allBreadcrumbs[0].resource === "categories" ? true : false;
        for (let i = 0; i < allBreadcrumbs.length; i++) {
            const params = {
                resource: allBreadcrumbs[i].resource,
                id: allBreadcrumbs[i].id,
            };
            try {
                if (params.id) {
                    const linkTitle =
                        allBreadcrumbs[i].resource === "categories"
                            ? allBreadcrumbs[i].name
                            : getItemName(params.resource, record, locale);
                    allBreadcrumbs[i].item = linkTitle;
                }
                const breadcrumbs = await finishItemRequests(
                    allBreadcrumbs,
                    params,
                    i,
                    isCategory
                );
                if (breadcrumbs) {
                    return breadcrumbs;
                }
            } catch (error) {
                const breadcrumbs = await finishItemRequests(
                    allBreadcrumbs,
                    params,
                    i,
                    isCategory
                );
                if (i === allBreadcrumbs.length - 1) {
                    return breadcrumbs;
                }
            }
        }
    };

    links.forEach((link, index) => {
        arr.push(extractBreadcrumbs(link, index));
    });
    return await getItemsData(arr);
};

const getItemName = (resource, data, locale) => {
    let name;
    switch (resource) {
        case "users":
            name = data.firstname.charAt(0) + ". " + data.lastname;
            break;
        case "skus":
            name = data.labelNumber;
            break;
        case "products":
            name = data.name[locale];
            break;
        case "eliminations":
            name = JSON.parse(data._embedded.product.data.name)[locale];
            break;
        default:
            name = data.name;
            break;
    }
    return name?.length > 20 ? name.substring(0, 20) + "..." : name;
};
