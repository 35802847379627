import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import {
    List,
    TextField,
    Pagination,
    BooleanField,
    SingleFieldList,
    ReferenceArrayField,
    ChipField,
    useLocaleState,
    usePermissions,
    useResourceContext,
    FunctionField,
} from "react-admin";
import { Grid, Box } from "@mui/material";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import debounce from "debounce";
import { queryToAttribute, queryToFilter } from "shared/src/utils/decodeQuery";
import { CategoryTree } from "./components/CategoryTree";
import { CategoryListFilter } from "./components/CategoryListFilter";
import { AccessRightsError } from "../components/AccessRightsError";
import { CategoryToolbar } from "./components/CategoryToolbar";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { BulkActions } from "./components/BulkActions";

export const CategoryList = () => {
    const [listItems, setListItems] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [filter, setFilter] = useState(queryToFilter(window.location.hash));
    const navigate = useNavigate();
    const location = useLocation();
    const [locale] = useLocaleState();
    const resource = useResourceContext();
    const attribute = queryToAttribute();

    const { isLoading, permissions } = usePermissions();

    //Prevent excessive data requests while typing
    const handleFilterChange = (filterInput, expandedInput) => {
        Object.keys(filterInput).forEach((key) => {
            if (!filterInput[key]) {
                delete filterInput[key];
            }
        });

        const filterToStr = JSON.stringify(filterInput);
        const expandedToStr = JSON.stringify(expandedInput ? expandedInput : []);

        const encodedFilter = encodeURI(`filter=${filterToStr}`);
        const encodedExpanded = encodeURI(`expanded=${expandedToStr}`);

        navigate({
            pathname: "/categories",
            search: `?${encodedFilter}&order=ASC&${encodedExpanded}`,
        });
        setFilter(filterInput);
    };

    const handleFilterTimer = debounce(handleFilterChange, 500);

    useEffect(() => {
        setFilter(queryToFilter(window.location.hash));
    }, [location]);

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <Grid
            id="categoryList"
            container
            spacing={1}
            mt={8}
            position={"relative"}
            sx={{ maxWidth: "1900px" }}
        >
            <Box
                sx={{
                    position: "absolute",
                    left: 8,
                    top: -49,
                }}
            >
                <Breadcrumbs />
            </Box>
            <Grid item xs={2}>
                <Box marginTop={6}>
                    <CategoryToolbar filter={filter} />
                    <CategoryTree
                        handleFilterChange={handleFilterTimer}
                        filter={filter}
                        id={filter?.category}
                    />
                </Box>
            </Grid>
            <Grid item xs={10}>
                <List
                    className="listlistlist"
                    resource="products"
                    filter={filter}
                    perPage={100}
                    hasCreate={filter?.category ? true : false}
                    pagination={<Pagination rowsPerPageOptions={[100, 200, 500]} />}
                    empty={
                        <CategoryListFilter
                            checkedItems={checkedItems}
                            handleFilterChange={handleFilterTimer}
                            filter={filter}
                            setCheckedItems={setCheckedItems}
                        />
                    }
                    actions={
                        <ToolbarWithHistory
                            createLabel="equip.createProduct"
                            create={true}
                            export={true}
                            breadcrumbs={false}
                        />
                    }
                >
                    <Box>
                        <CategoryListFilter
                            checkedItems={checkedItems}
                            handleFilterChange={handleFilterTimer}
                            filter={filter}
                            setCheckedItems={setCheckedItems}
                        />
                        <DatagridWithHistory
                            listItems={listItems}
                            setListItems={setListItems}
                            bulkActionButtons={<BulkActions />}
                        >
                            <FunctionField
                                label="ID"
                                render={(record) => {
                                    return record.labelNumber
                                        ? record.id + " / " + record.labelNumber
                                        : record.id;
                                }}
                            />
                            <TextField source={`name.${locale}`} label="equip.name" />
                            <TextField source={`description.${locale}`} label="equip.description" />

                            <BooleanField source="isMassProduct" label="equip.isMassProduct" />

                            <ReferenceArrayField
                                label="equip.tags"
                                reference="tags"
                                source="tags"
                                sortable={false}
                            >
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <TextField label="equip.manufacturer" source="manufacturer.name" />
                            <TextField label="equip.supplier" source="supplier.name" />
                            {!filter?.category && (
                                <TextField label="equip.category" source="category.name" />
                            )}
                            <TextField label="equip.inStock" source="quantity" />
                            <TextField label="equip.actualQuantity" source="actualQuantity" />
                        </DatagridWithHistory>
                    </Box>
                </List>
            </Grid>
        </Grid>
    );
};
