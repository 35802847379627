import {
    DeleteButton,
    DeleteWithConfirmButton,
    usePermissions,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { RaButtonWrapper } from "./RaButtonWrapper";

export const RaDeleteButton = ({ confirmDelete = false, ...props }) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const { isLoading, permissions } = usePermissions();
    const translate = useTranslate();

    if (
        isLoading ||
        !permissions.vote({
            resource,
            attribute: "delete",
            subject: record,
        })
    ) {
        return <></>;
    }

    return (
        <RaButtonWrapper title={translate("equip.delete")}>
            {confirmDelete ? <DeleteWithConfirmButton redirect={false} {...props} /> : <DeleteButton {...props} />}
        </RaButtonWrapper>
    );
};
