import {
    usePermissions,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { Button } from "@mui/material";

export const EliminateButton = ({ setEliminate }) => {
    const { isLoading, permissions } = usePermissions();
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();

    const canEliminate = () => {
        if (resource === "products") {
            return record.quantity > 0 && record.isMassProduct;
        }
        return record.status === "deactivated";
    };

    if (
        isLoading ||
        !permissions.vote({
            resource: resource,
            attribute: "eliminate",
        }) ||
        !canEliminate()
    ) {
        return <></>;
    }
    return (
        <Button
            color="error"
            variant="contained"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEliminate(true);
            }}
            title={translate("equip.eliminate")}
            aria-label={translate("equip.eliminate")}
        >
            {translate("equip.eliminate")}
        </Button>
    );
};
