import {
    ExportButton,
    usePermissions,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { RaButtonWrapper } from "./RaButtonWrapper";

export const RaExportButton = () => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (
        isLoading ||
        !permissions.vote({
            resource,
            attribute: "export",
            subject: record,
        })
    ) {
        return <></>;
    }

    return (
        <RaButtonWrapper title={translate("equip.export")}>
            <ExportButton />
        </RaButtonWrapper>
    );
};
