import {
    TextField,
    EmailField,
    Filter,
    TextInput,
    usePermissions,
    FunctionField,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { Chip } from "@mui/material";
import { DefaultPagination } from "../components/DefaultPagination";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaEditButton } from "../components/buttons/RaEditButton";
import { RaListView } from "../components/views/RaListView";

const UserListFilter = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput title={translate("equip.firstname")} label="equip.firstname" source="firstname" alwaysOn/>
            <TextInput title={translate("equip.lastname")} label="equip.lastname" source="lastname" alwaysOn/>
            <TextInput title={translate("equip.userIdentifier")} label="equip.userIdentifier" source="userIdentifier" alwaysOn/>
        </Filter>
    );
};

export const UserList = (props) => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaListView
            {...props}
            filters={<UserListFilter />}
            pagination={<DefaultPagination />}
            perPage={100}
            actions={
                <ToolbarWithHistory
                    create={permissions.vote({
                        resource,
                        attribute: "create",
                    })}
                />
            }
        >
            <DatagridWithHistory bulkActionButtons={false}>
                <TextField label="ID" source="id" />
                <TextField label="equip.firstname" source="firstname" />
                <TextField label="equip.lastname" source="lastname" />
                <EmailField label="equip.email" source="email" type="email" />
                <TextField label="equip.userIdentifier" source="userIdentifier" />
                <FunctionField
                    source="status"
                    label="equip.status"
                    render={(record) => translate(`equip.${record.status}`)}
                />
                <FunctionField
                    label="equip.roles"
                    render={(record) =>
                        record.roles.map((role, index) => (
                            <Chip key={record.id + index} label={role} sx={{my: 0.5, mr: 1}} />
                        ))
                    }
                />
                <RaEditButton title={translate("equip.edit")} />
            </DatagridWithHistory>
        </RaListView>
    );
};
