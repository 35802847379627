import {
    required,
    TextInput,
    useResourceContext,
    usePermissions,
    useTranslate,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { SimpleFormWithPermissions } from "../components/SimpleFormWithPermissions";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaEditView } from "../components/views/RaEditView";

export const TagEdit = (props) => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaEditView
            {...props}
            mutationMode="pessimistic"
            actions={<ToolbarWithHistory back={true} show={true} />}
        >
            <SimpleFormWithPermissions redirect="show">
                <TextInput
                    title={translate("equip.name")}
                    label="equip.name"
                    source="name"
                    validate={[required()]}
                />
            </SimpleFormWithPermissions>
        </RaEditView>
    );
};
