import {
    useResourceContext,
    usePermissions,
    FunctionField,
    useTranslate,
    TextField,
    Filter,
    TextInput,
    Datagrid,
} from "react-admin";
import { DefaultPagination } from "../components/DefaultPagination";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaDateField } from "../components/fields/Ra/RaDateField";
import Link from "@mui/material/Link";
import { RaListView } from "../components/views/RaListView";

const RentalLogsFilter = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                title={translate("equip.edispo.id")}
                label="equip.edispo.id"
                source="edispoId"
                alwaysOn
            />
        </Filter>
    );
};

export const RentalLogsDatagrid = () => {
    const translate = useTranslate();

    return (
        <Datagrid
            bulkActionButtons={false}
            rowClick={(id, resource, record) =>
                `/rentals/${record.rental.id}/show`
            }
        >
            <TextField label="equip.rental" source="rental.name" />
            <TextField label="equip.edispo.id" source="edispoId" />
            <FunctionField
                label="equip.action"
                render={(record) =>
                    translate(`equip.rental_log.${record.action}`)
                }
            />
            <FunctionField
                label="equip.reference"
                render={(record) => {
                    if (!record.referenceType) {
                        return '';
                    }

                    const type = record.referenceType.split(`App\\Entity\\`)[1];
                    const text =`${type} ${record.referenceId}`;

                    return <Link
                        href={`#/${type.toLowerCase()}s/${record.referenceId}/show`}
                        title={text}
                        aria-label={text}
                    >
                        {text}
                    </Link>
                }}
            />
            <TextField label="equip.quantity" source="rentalItemReturn.quantity" />
            <TextField label="equip.comment" source="rentalItemReturn.comment" />
            <RaDateField source="createdAt" label="equip.date" showTime={true} />
            <FunctionField
                label="equip.user"
                render={(record) =>
                    record.createdBy ? record.createdBy.fullName : "System"
                }
            />
        </Datagrid>
    );
};

export const RentalLogsList = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaListView
            {...props}
            actions={<ToolbarWithHistory export={true} />}
            pagination={<DefaultPagination />}
            perPage={100}
            filters={<RentalLogsFilter />}
            sort={{ field: "createdAt", order: "DESC" }}
        >
            <RentalLogsDatagrid />
        </RaListView>
    );
};
