import {
    FunctionField,
    TextField,
    NumberField,
    ReferenceInput,
    Filter,
    TextInput,
    usePermissions,
    useResourceContext,
    useTranslate,
    useLocaleState,
} from "react-admin";
import { DefaultPagination } from "../components/DefaultPagination";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaAutocompleteInput } from "../components/inputs/Ra/RaAutocompleteInput";
import { RaEditButton } from "../components/buttons/RaEditButton";
import { RaListView } from "../components/views/RaListView";

const SkuListFilter = (props) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <TextInput
                title={translate("equip.labelNumber")}
                label="equip.labelNumber"
                source="labelNumber"
                alwaysOn
            />
            <TextInput
                title={translate("equip.name")}
                label="equip.name"
                source="name"
                alwaysOn
            />
            <TextInput
                title={translate("equip.orderNumber")}
                label="equip.orderNumber"
                source="purchaseOrderNumber"
                alwaysOn
            />
            <RaSelectInput
                alwaysOn
                source="status"
                choices={[
                    {
                        id: "active",
                        name: translate("equip.sku_status_active"),
                    },
                    {
                        id: "deactivated",
                        name: translate("equip.sku_status_deactivated"),
                    },
                    {
                        id: "eliminated",
                        name: translate("equip.sku_status_eliminated"),
                    },
                ]}
            />
            <RaSelectInput
                alwaysOn
                source="availability"
                choices={[
                    {
                        id: "available",
                        name: translate("equip.sku_availability_available"),
                    },
                    {
                        id: "rented_out",
                        name: translate("equip.sku_availability_rented_out"),
                    },
                    {
                        id: "defect",
                        name: translate("equip.sku_availability_defect"),
                    },
                    {
                        id: "lost",
                        name: translate("equip.sku_availability_lost"),
                    },
                ]}
            />
            <ReferenceInput
                alwaysOn
                source="manufacturer"
                reference="manufacturers"
                sort={{ field: "name", order: "ASC" }}
            >
                <RaAutocompleteInput
                    label="equip.manufacturer"
                    field="name"
                />
            </ReferenceInput>
            <ReferenceInput
                alwaysOn
                source="supplier"
                reference="suppliers"
                sort={{ field: "name", order: "ASC" }}
            >
                <RaAutocompleteInput label="equip.supplier" field="name" />
            </ReferenceInput>
            <TextInput
                title={translate("equip.serialNumber")}
                label="equip.serialNumber"
                source="serialNumber"
                alwaysOn
            />
        </Filter>
    );
};

export const SkuList = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();
    const [locale] = useLocaleState();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaListView
            {...props}
            actions={
                <ToolbarWithHistory
                    export={permissions.vote({
                        resource,
                        attribute: "export",
                    })}
                />
            }
            filters={<SkuListFilter />}
            pagination={<DefaultPagination />}
            perPage={100}
        >
            <DatagridWithHistory bulkActionButtons={false}>
                <TextField label="equip.labelNumber" source="labelNumber" />
                <TextField
                    label="equip.name"
                    source={`product.name.${locale}`}
                />
                <TextField
                    label="equip.manufacturer"
                    source="manufacturer.name"
                />
                <TextField label="equip.supplier" source="supplier.name" />
                <NumberField
                    label="equip.purchasePrice"
                    source="arrival.purchasePrice"
                    options={{ style: "currency", currency: "EUR" }}
                />
                <NumberField
                    label="equip.currentPrice"
                    source="currentPrice"
                    options={{ style: "currency", currency: "EUR" }}
                    sortable={false}
                />
                <NumberField
                    label="equip.weight"
                    source="weight"
                    options={{
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "unit",
                        unit: "kilogram",
                    }}
                />
                <TextField label="equip.serialNumber" source="serialNumber" />
                <FunctionField
                    label="equip.status"
                    render={(record) => {
                        return (
                            translate(`equip.sku_status_${record.status}`) +
                            " / " +
                            translate(
                                `equip.sku_availability_${record.availability}`
                            )
                        );
                    }}
                />
                <RaEditButton />
            </DatagridWithHistory>
        </RaListView>
    );
};
