import dayjs from 'dayjs';

const arrivalConverter = {
    convertIn: (responseData) => {
        if (responseData.orderDate) {
            responseData.orderDate = dayjs(responseData.orderDate);
        }

        if (responseData.deliveryDate) {
            responseData.deliveryDate = dayjs(responseData.deliveryDate);
        }

        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }
        
        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        return responseData;
    },
};

export default arrivalConverter;
