/* eslint-disable no-fallthrough */
import {
    Box,
    Button,
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
    useDataProvider,
    useLocaleState,
    useNotify,
    useRecordContext,
    useTranslate,
} from "react-admin";
import { handleHttpError } from "shared/src/utils/errorHandler";
import Link from "@mui/material/Link";

export const EdispoDiffTable = ({ equipData }) => {
    const [locale] = useLocaleState();
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const rental = useRecordContext();
    const [isOldData, setIsOldData] = useState(true);
    const [edispoItems, setEdispoItems] = useState(rental.edispoItemData || []);
    const [tableData, setTableData] = useState([]);

    const mergeData = useCallback(
        (edispoData, equipData) => {
            if (!rental.edispoId) {
                setTableData(equipData);

                return;
            }

            let myEdispoData = [...edispoData];
            const myEquipData = equipData.map((equipItem) => {
                delete equipItem.id;
                equipItem.status = [];

                if (!equipItem.product.edispoId) {
                    equipItem.status = ["noEdispoProduct"];

                    return equipItem;
                }

                let edispoItem = null;
                myEdispoData.forEach((item, index) => {
                    if (
                        item.id !== equipItem.product.edispoId ||
                        item.edispoType !== equipItem.product.edispoType
                    ) {
                        return;
                    }

                    item.foundItem = true;
                    edispoItem = item;

                    if (edispoItem.added === edispoItem.required) {
                        equipItem.status.push("isFulfilled");
                    }

                    if (
                        edispoItem.added > 0 &&
                        edispoItem.added < edispoItem.required
                    ) {
                        equipItem.status.push("fulfillmentInProgress");
                    }

                    if (edispoItem.dateDifferences.length > 0) {
                        equipItem.status.push("hasDateDifferences");
                    }

                    if (edispoItem.added > edispoItem.required) {
                        equipItem.status.push("scannedTooMany");
                    }
                });

                if (edispoItem) {
                    return {
                        ...equipItem,
                        ...edispoItem,
                    };
                }

                if (equipItem.product.edispoId) {
                    equipItem.status.push("isNotInEdispoList");
                }

                return equipItem;
            });

            myEdispoData = myEdispoData.filter((item, index) => {
                if (!item.foundItem) {
                    item.status = ["missingItem"];

                    return true;
                }

                return false;
            });

            setTableData([...myEdispoData, ...myEquipData]);
        },
        [setTableData, rental.edispoId]
    );

    const getEdispoData = () => {
        if (!isOldData) {
            setEdispoItems(rental.edispoItemData);
            setIsOldData(true);
            return;
        }

        dataProvider
            .get(`/edispo/actualEdispoData/${rental.id}`, "rentals")
            .then((response) => {
                setEdispoItems(response.data.edispoItemData);
                setIsOldData(false);
            })
            .catch((e) => {
                handleHttpError(e, notify);
            });
    };

    const columns = [
        {
            id: `product`,
            label: translate("equip.name"),
            format: (value) => value.name[locale],
        },
        { id: "labelNumber", label: translate("equip.labelNumber") },
        { id: "quantity", label: translate("equip.quantity") },
        {
            id: "rentalProcess",
            label: translate("equip.rentedOut"),
            format: (value) => {
                return (
                    <Link
                        href="#"
                        onClick={(event) => {
                            event.preventDefault();
                            dataProvider.getDownload("rentalProcesses", {
                                id: value.id,
                                contentType: "application/pdf",
                            });
                        }}
                        title={
                            translate("equip.downloadPdf") +
                            " (" +
                            value.renter?.fullName +
                            ")"
                        }
                        label={
                            translate("equip.downloadPdf") +
                            " (" +
                            value.renter?.fullName +
                            ")"
                        }
                    >
                        {value.signatureDate?.format("DD.MM.YYYY HH:mm:ss")}
                    </Link>
                );
            },
        },
        {
            id: "earliestPlannedReturnDate",
            label: translate("equip.plannedReturnDate"),
            format: (value) => value.format("DD.MM.YYYY"),
        },
        { id: "returnedQuantity", label: translate("equip.returned") },
        { id: "openQuantity", label: translate("equip.openQuantity") },
        { id: "lostQuantity", label: translate("equip.lostQuantity") },
        { id: "defectQuantity", label: translate("equip.defectQuantity") },
        { id: "delayedQuantity", label: translate("equip.delayedQuantity") },
        {
            id: "status",
            label: translate("equip.status"),
            format: (value) =>
                value.map((status, index) => (
                    <Chip
                        key={index}
                        sx={{
                            backgroundColor: statusInfo[status].color,
                            ...(index > 0 && { marginTop: "3px" }),
                        }}
                        label={statusInfo[status].label}
                    />
                )),
        },
    ];

    if (rental.edispoId) {
        columns.unshift(
            { id: "name", label: translate("equip.name") },
            { id: "id", label: translate("ID") },
            { id: "edispoType", label: translate("equip.edispo.type") },
            { id: "required", label: translate("equip.quantity") },
            {
                id: "returnDate",
                label: translate("equip.returnDate"),
                format: (value) => value.format("DD.MM.YYYY"),
            }
        );
    }

    const statusInfo = {
        noEdispoProduct: {
            color: "#4699ff52",
            label: translate("equip.noEdispoProduct"),
        },
        isNotInEdispoList: {
            color: "#ff4d4d70",
            label: translate("equip.notInEdispoList"),
        },
        isFulfilled: {
            color: "#86ff4d70",
            label: translate("equip.fulfilled"),
        },
        fulfillmentInProgress: {
            color: "#ff9f4d70",
            label: translate("equip.fulfillmentInProgress"),
        },
        hasDateDifferences: {
            color: "#ff9f4d70",
            label: translate("equip.dateDifferences"),
        },
        scannedTooMany: {
            color: "#ff4d4d70",
            label: translate("equip.scannedTooMany"),
        },
        missingItem: {
            color: "#ff4d4d70",
            label: translate("equip.missingItem"),
        },
    };

    useEffect(() => {
        mergeData(edispoItems, equipData);
    }, [edispoItems, equipData, mergeData]);

    return (
        <>
            {rental.edispoId && (
                <Button onClick={getEdispoData}>
                    {translate(
                        isOldData
                            ? "equip.compareWithActualData"
                            : "equip.compareWithOldData"
                    )}
                </Button>
            )}
            <Paper sx={{ margin: 1.5 }} elevation={2}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            {rental.edispoId && (
                                <TableRow key={0}>
                                    <TableCell
                                        sx={{
                                            borderRight: "1px solid #d0cdcc",
                                            fontWeight: "bolder",
                                        }}
                                        align="center"
                                        colSpan={5}
                                        key={1}
                                    >
                                        Edispo
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontWeight: "bolder" }}
                                        align="center"
                                        colSpan={11}
                                        key={0}
                                    >
                                        Equip
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow key={1}>
                                {columns.map((column) => {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            sx={{
                                                ...(column.id ===
                                                    "returnDate" && {
                                                    borderRight:
                                                        "1px solid #d0cdcc",
                                                }),
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row, index) => (
                                <TableRow hover tabIndex={-1} key={index}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                sx={{
                                                    ...(column.id ===
                                                        "returnDate" && {
                                                        borderRight:
                                                            "1px solid #d0cdcc",
                                                    }),
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        ...(column.id ===
                                                            "status" && {
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }),
                                                    }}
                                                >
                                                    {value === undefined
                                                        ? ""
                                                        : column.format
                                                        ? column.format(value)
                                                        : value}
                                                </Box>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};
