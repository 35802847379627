import dayjs from 'dayjs';

const eliminationConverter = {
    convertIn: (responseData) => {
        if (responseData.date) {
            responseData.date = dayjs(responseData.date);
        }

        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }
        
        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        return responseData;
    },
};

export default eliminationConverter;
