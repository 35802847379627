import Chart from "react-google-charts";
import { barStylesCallback } from "./utils/barStylesCallback";
import { useState } from "react";
import { RentalDetailsModal } from "./RentalDetailModal";
import { rowStylesCallback } from "./utils/rowStylesCallback";
import { useTheme } from "react-admin";

export const TimelineChart = ({
    chartOptions,
    chartData,
    rentalItemGroupsRef,
}) => {
    const [selectedRental, setSelectedRental] = useState(null);
    const [theme] = useTheme();

    const getRentalOnRowClick = (row) => {
        if (row !== null) {
            const rental = rentalItemGroupsRef.current[row].rentalInfo;
            setSelectedRental(rental);
        }
    };

    return (
        <>
            {selectedRental && (
                <RentalDetailsModal
                    rental={selectedRental}
                    onClose={() => setSelectedRental(null)}
                />
            )}
            <Chart
                options={chartOptions}
                chartType="Timeline"
                data={chartData}
                width="100%"
                height="100%"
                chartEvents={[
                    {
                        eventName: "ready",
                        callback: ({ chartWrapper }) => {
                            barStylesCallback({
                                chartWrapper: chartWrapper,
                                isMouseOver: false,
                            });
                            rowStylesCallback({ chartWrapper, theme });
                        },
                    },
                    {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                            const chart = chartWrapper.getChart();
                            google.visualization.events.addListener(
                                chart,
                                "onmouseover",
                                (e) => {
                                    barStylesCallback({
                                        chartWrapper: chartWrapper,
                                        isMouseOver: true,
                                    });
                                }
                            );
                        },
                    },
                    {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                            const chart = chartWrapper.getChart();
                            google.visualization.events.addListener(
                                chart,
                                "onmouseout",
                                (e) => {
                                    barStylesCallback({
                                        chartWrapper: chartWrapper,
                                        isMouseOver: false,
                                    });
                                }
                            );
                        },
                    },
                    {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                            const chart = chartWrapper.getChart();
                            google.visualization.events.addListener(
                                chart,
                                "select",
                                (e) => {
                                    const selection = chart.getSelection();
                                    const row = selection[0].row;
                                    getRentalOnRowClick(row);
                                }
                            );
                        },
                    },
                ]}
            />
        </>
    );
};
