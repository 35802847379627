import { Layout, usePermissions } from "react-admin";
import AppBar from "./AppBar";
import { useNavigate } from "react-router";

const AppLayout = (props) => {
    const { permissions } = usePermissions();
    const navigate = useNavigate();
    const guest =
        permissions?.user.roles.includes("ROLE_GUEST") &&
        permissions?.user.roles.length === 1;

    if (guest) {
        window.addEventListener("load", () => {
            // hide menu button
            document.getElementsByClassName(
                "RaAppBar-menuButton"
            )[0].style.display = "none";
        });

        if (!window.location.href.includes("product_info")) {
            navigate("/product_info");
        }
    }

    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={guest ? MySidebar : undefined}
        />
    );
};

const MySidebar = () => <></>;

export default AppLayout;
