import dayjs from "dayjs";

const rentalLogConverter = {
    convertIn: (responseData) => {
        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }
        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        return responseData;
    },
};

export default rentalLogConverter;
