import rentalItemConverter from "./rentalItemConverter";
import dayjs from "dayjs";

const rentalConverter = {
    convertIn: (responseData) => {
        if (responseData._embedded?.rentalItems) {
            responseData.rentalItems = responseData._embedded.rentalItems.data;
        }

        for (const index in responseData.rentalItems || []) {
            responseData.rentalItems[index] = rentalItemConverter.convertIn(
                responseData.rentalItems[index]
            );
        }

        for (const edispoItem of responseData.edispoItemData || []) {
            if (edispoItem.returnDate) {
                edispoItem.returnDate = dayjs(edispoItem.returnDate);
            }
        }

        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }

        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        if (responseData.startDate) {
            responseData.startDate = dayjs(responseData.startDate);
        }

        if (responseData.endDate) {
            responseData.endDate = dayjs(responseData.endDate);
        }

        if (responseData.actualRentalProcess?.signatureDate) {
            responseData.actualRentalProcess.signatureDate = dayjs(responseData.actualRentalProcess.signatureDate);
        }

        if (responseData.actualRentalProcess?.updatedAt) {
            responseData.actualRentalProcess.updatedAt = dayjs(responseData.actualRentalProcess.updatedAt);
        }

        return responseData;
    },
    convertOut: (object) => {
        delete object.id;
        delete object.edispoId;
        delete object.weight;
        delete object.itemCount;
        delete object.daysOverdue;
        delete object.daysOverdueDunning;
        delete object.edispoItemData;
        delete object.rentalItems;
        delete object.dunning;
        delete object.actualRentalProcess;

        return {
            ...object,
            ...(object?.renter && { renter: object.renter ? object.renter.id : null }),
        };
    },
};

export default rentalConverter;
