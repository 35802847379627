import {
    EditButton,
    usePermissions,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { RaButtonWrapper } from "./RaButtonWrapper";

export const RaEditButton = () => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (
        isLoading ||
        !permissions.vote({
            resource,
            attribute: "edit",
            subject: record,
        })
    ) {
        return <></>;
    }

    return (
        <RaButtonWrapper type="edit" title={translate("equip.edit")}>
            <EditButton />
        </RaButtonWrapper>
    );
};
