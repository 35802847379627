import { Box } from "@mui/material";
import { List } from "react-admin";

export const RaListView = (props) => {
    return (
        <Box marginTop={5} position={"relative"}>
            <List {...props}>{props.children}</List>
        </Box>
    );
};
