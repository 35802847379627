import {
    SimpleShowLayout,
    useNotify,
    usePermissions,
    useRecordContext,
    useResourceContext,
    useGetList,
} from "react-admin";
import RentalItems from "./RentalItems";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { Spacer } from "shared/src/components/Spacer";
import { EdispoDiffTable } from "./components/edispoDiffTable.js/EdispoDiffTable";
import { RaShowView } from "../components/views/RaShowView";
import { handleHttpError } from "shared/src/utils/errorHandler";

export const RentalShow = () => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }

    return (
        <RaShowView
            actions={
                <ToolbarWithHistory back={true} edit={true} delete={true} />
            }
        >
            <SimpleShowLayout>
                <ContentWrapper />
            </SimpleShowLayout>
        </RaShowView>
    );
};

const ContentWrapper = () => {
    const rental = useRecordContext();
    const notify = useNotify();
    const { data: rentalItems, isLoading, error } = useGetList("rentalItems", {
        filter: { rental: rental.id },
        pagination: { page: 1, perPage: 999 },
        sort: {
            field: "id",
            order: "ASC",
        },
    });

    if (error || isLoading) return <></>;

    if (error) {
        handleHttpError(error, notify);
    }

    return (
        <>
            <RentalItems rentalItems={rentalItems || []} />
            <Spacer y={2} />
            <EdispoDiffTable equipData={rentalItems || []} />
        </>
    );
};
