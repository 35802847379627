import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDataProvider, useTranslate, usePermissions, useNotify } from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { queryToNoHashLink } from "shared/src/utils/decodeQuery";

export const CategoryToolbar = ({ filter }) => {
    const [category, setCategory] = useState();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const translate = useTranslate();
    const notify = useNotify();
    const { isLoading, permissions } = usePermissions();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (filter?.category) {
            dataProvider
                .getOne("categories", { id: filter.category })
                .then((response) => {
                    setCategory(response.data);
                })
                .catch((e) => {
                    setCategory();
                    handleHttpError(e, notify);
                });
        } else {
            setCategory();
        }
    }, [filter, dataProvider, notify]);

    if (isLoading) {
        return <></>;
    }
    return (
        <Box position="absolute" top={2}>
            <Button
                title={translate("equip.categoryToolbar")}
                disabled={category?.id === 1}
                id="categoty-toolbar-button"
                aria-controls={open ? "category-toolbar-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="outlined"
            >
                {translate("equip.categoryToolbar")}
                {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
            <Menu
                id="category-toolbar-menu"
                MenuListProps={{
                    "aria-labelledby": "categoty-toolbar-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {permissions.vote({
                    resource: "categories",
                    attribute: "create",
                }) && (
                    <MenuItem
                        onClick={() => {
                            navigate(
                                `create/${
                                    filter?.category ? filter.category : "0"
                                }?from=${queryToNoHashLink()}`
                            );
                        }}
                    >
                        <Button
                            startIcon={<AddIcon />}
                            size="small"
                            title={
                                filter?.category
                                    ? translate("equip.addSubCategory")
                                    : translate("equip.addMainCategory")
                            }
                            aria-label={
                                filter?.category
                                    ? translate("equip.addSubCategory")
                                    : translate("equip.addMainCategory")
                            }
                        >
                            <Typography fontSize={13} sx={{ textTransform: "uppercase" }}>
                                {filter?.category
                                    ? translate("equip.addSubCategory")
                                    : translate("equip.addMainCategory")}
                            </Typography>
                        </Button>
                    </MenuItem>
                )}
                {category?.name &&
                    permissions.vote({
                        resource: "categories",
                        attribute: "edit",
                    }) && (
                        <MenuItem
                            onClick={() => {
                                navigate(`${filter.category}?from=${queryToNoHashLink()}`);
                            }}
                        >
                            <Button
                                startIcon={<EditIcon />}
                                size="small"
                                title={`${translate("ra.action.edit")} ${translate(
                                    "equip.category"
                                )}:`}
                                aria-label={`${translate("ra.action.edit")} ${translate(
                                    "equip.category"
                                )}:`}
                            >
                                <Typography fontSize={13} sx={{ textTransform: "uppercase" }}>
                                    {`${translate("ra.action.edit")}
                                    ${translate("equip.category")}: `}
                                    <strong>{category?.name}</strong>
                                </Typography>
                            </Button>
                        </MenuItem>
                    )}
            </Menu>
        </Box>
    );
};
