import {
    Show,
    SimpleShowLayout,
    TextField,
    usePermissions,
    useResourceContext,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { AccessRightsError } from "../components/AccessRightsError";

export const CategoryShow = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <Show {...props} actions={<ToolbarWithHistory edit={true} />}>
            <SimpleShowLayout>
                <TextField label="equip.name" source="name" />
            </SimpleShowLayout>
        </Show>
    );
};
