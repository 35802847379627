import { useRecordContext, useResourceContext } from "react-admin";
import { useNavigate, useLocation } from "react-router";
import { queryToNoHashLink } from "shared/src/utils/decodeQuery";
import { cloneElement } from "react";

export const RaButtonWrapper = ({ children, title, type, ...props }) => {
    const navigate = useNavigate();
    const resource = useResourceContext();
    const record = useRecordContext();
    const location = useLocation();
    const redirect = ["create", "edit", "show"].includes(type);

    const redirectOnClick = (e, type) => {
        const locationHistory = window.location.href?.split("?from=").slice(1);

        const currentSearch = locationHistory.join("?from=");

        const search =
            location.pathname.includes(resource) === false
                ? `?from=${queryToNoHashLink()}`
                : currentSearch
                ? `?from=${currentSearch}`
                : "";

        e.preventDefault();
        e.stopPropagation();
        // eslint-disable-next-line default-case
        switch (type) {
            case "create":
                navigate({
                    pathname: `/${resource}/create`,
                    search: search,
                });
                break;
            case "edit":
                navigate({
                    pathname: `/${resource}/${record?.id}`,
                    search: search,
                });
                break;
            case "show":
                navigate({
                    pathname: `/${resource}/${record?.id}/show`,
                    search: search,
                });
                break;
        }
    };

    return (
        <span title={title}>
            {cloneElement(children, {
                ...(redirect && { onClick: (e) => redirectOnClick(e, type) }),
                ...props,
            })}
        </span>
    );
};
