import { useState } from "react";
import {
    TextField,
    useRecordContext,
    Datagrid,
    ReferenceManyField,
    useDataProvider,
    useTranslate,
    usePermissions,
    useNotify,
} from "react-admin";
import { MenuItem, Menu, Button } from "@mui/material";
import { handleHttpError } from "shared/src/utils/errorHandler";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { RaDateField } from "../../../components/fields/Ra/RaDateField";
import { MediaDialog } from "./MediaDialog";
import { Box } from "@mui/system";


export const MediaManagement = (props) => {
    const { filter, ...rest } = props;
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { isLoading, permissions } = usePermissions();

    const [mediaTools, setMediaTools] = useState({
        edit: null,
        type: null,
        id: null,
        record: null,
    });

    const openRecord = (id, resource, record) => {
        setMediaTools({
            edit: false,
            type: record.type,
            id: id,
        });

        const getRecord = () => {
            dataProvider
                .getOne("media", { id: id })
                .then((response) => {
                    setMediaTools({
                        edit: false,
                        type: record.type,
                        id: id,
                        record: response.data,
                    });
                })
                .catch((e) => {
                    handleHttpError(e, notify);
                });
        };
        getRecord();
    };
    if (isLoading) {
        return <></>;
    }
    return (
        <Box>
            {mediaTools.type ? (
                <MediaDialog
                    {...props}
                    mediaTools={mediaTools}
                    setMediaTools={setMediaTools}
                />
            ) : null}
            {permissions.vote({
                resource: "media",
                attribute: "create",
                subject: mediaTools.record,
            }) && <Toolbar setMediaTools={setMediaTools} />}
            <ReferenceManyField
                {...rest}
                filter={{
                    // Use a very special key to ensure it does not conflict with any other filter
                    "@@record": record,
                    ...filter,
                }}
                reference="media"
                target="media"
            >
                <Datagrid rowClick={openRecord} bulkActionButtons={false}>
                    <TextField label="equip.type" source="type" />
                    <TextField label="equip.name" source="shortname" />
                    <RaDateField  label="equip.created" source="createdAt" />
                    <TextField label="equip.size" source="size" />
                </Datagrid>
            </ReferenceManyField>
        </Box>
    );
};

const Toolbar = ({ setMediaTools }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const translate = useTranslate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const selectMenuOption = (e) => {
        setMediaTools({
            edit: true,
            type: e.target.dataset.menuitem,
        });
        handleClose();
    };

    return (
        <Box my={2}>
            <Button
                id="add-media-button"
                aria-controls={open ? "add-media-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="text"
                onClick={handleClick}
                startIcon={<AddPhotoAlternateIcon fontSize="large" />}
                size="small"
                title={translate("equip.addMedia")}
            >
                {translate("equip.addMedia")}
            </Button>
            <Menu
                id="add-media-menu"
                MenuListProps={{
                    "aria-labelledby": "add-media-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem
                    title={translate("equip.uploadMedia")}
                    aria-label={translate("equip.uploadMedia")}
                    data-menuitem={"file"}
                    onClick={selectMenuOption}
                >
                    {translate("equip.uploadMedia")}
                </MenuItem>
                <MenuItem
                    title={translate("equip.linkToExternalResource")}
                    aria-label={translate("equip.linkToExternalResource")}
                    data-menuitem={"link"}
                    onClick={selectMenuOption}
                >
                    {translate("equip.linkToExternalResource")}
                </MenuItem>
                <MenuItem
                    title={translate("equip.addText")}
                    aria-label={translate("equip.addText")}
                    data-menuitem={"text"}
                    onClick={selectMenuOption}
                >
                    {translate("equip.addText")}
                </MenuItem>
            </Menu>
        </Box>
    );
};
