import ReportIcon from "@mui/icons-material/Report";
import { useTranslate } from "react-admin";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

export const AccessRightsError = () => {
    const translate = useTranslate();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                color: "rgba(0, 0, 0, 0.54)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "20px",
                    margin: "0 auto 0 auto",
                }}
            >
                <ReportIcon
                    sx={{
                        fontSize: "45px",
                        marginRight: 1,
                    }}
                />
                <Typography variant="h1">{translate("equip.permissionsError")}</Typography>
            </Box>
            <Typography variant="p" sx={{ margin: "0 auto 0 auto" }}>
                {translate("equip.permissionsErrorDetails")}
            </Typography>
        </Box>
    );
};
