import {hexToRgb} from "@mui/material";

export const barStylesCallback = ({
    chartWrapper,
    isMouseOver,
}) => {
    const graphContainer = chartWrapper.visualization.container;
    const rectElementsHtml = graphContainer.getElementsByTagName("rect");
    const rectElements = Array.from(rectElementsHtml);
    const chartBarElements = rectElements.filter(
        (el) => JSON.parse(el.logicalname).type === "bar"
    );

    let chartBars = chartBarElements;
    if (isMouseOver) {
       // chartBars = [chartBarElements[chartBarElements.length - 1]];
    }

    chartBars.forEach((bar, index) => {
        assignRowColor({
            index,
            bar
        });
    });
};

const assignRowColor = ({
    index,
    bar,
}) => {
    const isGradientStart = bar.getAttribute('fill-opacity') === "0.1" || bar.getAttribute('fill-opacity') === "0.5" || bar.dataset.isGradientStart === 'true';
    const isGradientEnd = bar.getAttribute('fill-opacity') === "0.9" ||  bar.getAttribute('fill-opacity') === "0.5" || bar.dataset.isGradientEnd === 'true';

    if (!isGradientStart && !isGradientEnd) {
        return;
    }

    bar.setAttribute('fill-opacity', 1);
    bar.dataset.isGradientStart = isGradientStart ? 'true' : 'false';
    bar.dataset.isGradientEnd = isGradientEnd ? 'true' : 'false';

    const color = hexToRgb(bar.getAttribute('fill')).replace(')', '');

    const opacityStart = isGradientStart ? `.25` : `1`;
    const opacityEnd = isGradientEnd ? `.25` : `1`;
    const opacityMid = `1`;

    bar.style.fill = `url(#grad${index})`;
    const element = document.getElementById(`grad${index}`);
    element?.remove();

    bar.parentElement.insertAdjacentHTML(
        "beforeend",
        `<linearGradient id="grad${index}">` +
            `<stop offset="0%" stop-color="${color}, ${opacityStart})"/>` +
            `<stop offset="25%" stop-color="${color}, ${opacityMid})"/>` +
            `<stop offset="75%" stop-color="${color}, ${opacityMid})"/>` +
            `<stop offset="100%" stop-color="${color}, ${opacityEnd})" />` +
            "</linearGradient>"
    );
};
