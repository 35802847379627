import { useState } from "react";
import { useRecordContext, usePermissions } from "react-admin";
import { Dialog } from "@mui/material";
import { ViewMassArrival } from "./mass/ViewMassArrival";
import { EditMassArrival } from "./mass/EditMassArrival";
import { CreateSkuArrivalForm } from "./sku/CreateSkuArrivalForm";
import { EditSkuForm } from "./sku/EditSkuForm";
import { CreateSkuForm } from "./sku/CreateSkuForm";
import { ViewSkuArrival } from "./sku/ViewSkuArrival";

export const NewDelivery = ({ deliveryWindow, ...props }) => {
    const record = useRecordContext();

    return (
        <Dialog  open={deliveryWindow ? true : false} fullWidth maxWidth="lg">
            {record.isMassProduct ? (
                <MassArrivalsManagement deliveryWindow={deliveryWindow} {...props} />
            ) : (
                <SkuArrivalsManagement deliveryWindow={deliveryWindow} {...props} />
            )}
        </Dialog>
    );
};

const MassArrivalsManagement = (props) => {
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }
    switch (true) {
        case props.deliveryWindow === "show" &&
            permissions.vote({
                resource: "arrivals",
                attribute: "read",
                subject: props.arrivalData,
            }):
            return <ViewMassArrival {...props} />;
        case props.deliveryWindow === "edit" &&
            permissions.vote({
                resource: "arrivals",
                attribute: props.arrivalData ? "edit" : "create",
                subject: props.arrivalData,
            }):
            return <EditMassArrival {...props} />;
        default:
            return <>Error</>;
    }
};

const SkuArrivalsManagement = (props) => {
    const [skuRecord, setSkuRecord] = useState();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }

    switch (true) {
        case props.deliveryWindow === "edit" &&
            permissions.vote({
                resource: "arrivals",
                attribute: props.arrivalData ? "edit" : "create",
                subject: props.arrivalData,
            }):
            return <CreateSkuArrivalForm {...props} />;
        case props.deliveryWindow === "editSku" &&
            permissions.vote({
                resource: "arrivals",
                attribute: props.arrivalData ? "edit" : "create",
                subject: props.arrivalData,
            }):
            return <EditSkuForm setSkuRecord={setSkuRecord} {...props} />;
        case props.deliveryWindow === "createSku" &&
            permissions.vote({
                resource: "skus",
                attribute: props.skuRecord ? "edit" : "create",
                subject: props.skuRecord,
            }):
            return <CreateSkuForm skuRecord={skuRecord} setSkuRecord={setSkuRecord} {...props} />;
        case props.deliveryWindow === "show" &&
            permissions.vote({
                resource: "arrivals",
                attribute: "read",
                subject: props.arrivalData,
            }):
            return <ViewSkuArrival {...props} />;
        default:
            return <>Error</>;
    }
};
