import { Box, Button, Card, Dialog, DialogContent } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
    Form,
    useRefresh,
    Toolbar,
    ReferenceArrayInput,
    useCreate,
    useTranslate,
    useUpdateMany,
    TranslatableInputs,
    useUpdate,
    useNotify,
    useListContext,
    useUnselectAll,
} from "react-admin";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { RaAutocompleteArrayInput } from "../../components/inputs/Ra/RaAutocompleteArrayInput";
import { RaTextInput } from "../../components/inputs/Ra/RaTextInput";
import { RaNumberInput } from "../../components/inputs/Ra/RaNumberInput";

export const BulkActionsPopup = ({ menu, setMenu }) => {
    const { data: records, selectedIds } = useListContext();
    const unselectAll = useUnselectAll("products");
    const [updateMany] = useUpdateMany();
    const [update] = useUpdate();
    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();

    const validate = (data) => {
        const errors = {};
        switch (menu) {
            case "editName":
                Object.keys(data.name).forEach((key) => {
                    if (!data.name[key]) {
                        errors.name = errors.name || {};
                        errors.name[key] = translate(
                            "error.product.empty_name"
                        );
                        return;
                    }
                });
                break;
            case "editDescription":
                Object.keys(data.description).forEach((key) => {
                    if (!data.description[key]) {
                        errors.description = errors.description || {};
                        errors.description[key] = translate(
                            "error.product.empty_description"
                        );
                        return;
                    }
                });
                break;
            case "editPrice":
                if (!data.carnetPrice) {
                    const errors = {};
                    errors.carnetPrice = translate(
                        "error.product.empty_carnetPrice"
                    );
                    return errors;
                }
                break;
            case "addTags":
                if (0 === data.tags.length) {
                    const errors = {};
                    errors.tags = translate("error.product.empty_tags");
                    return errors;
                }
                break;
            default:
                break;
        }

        return errors;
    };

    const submit = ({ name, description, carnetPrice, tags }) => {
        let data = {
            ...(menu === "editName" && { name }),
            ...(menu === "editDescription" && { description }),
            ...(menu === "editPrice" && { carnetPrice }),
            ...(menu === "addTags" && { tags }),
        };

        if (menu === "addTags") {
            const selectedRecords = records.filter((record) =>
                selectedIds.includes(record.id)
            );
            selectedRecords.forEach((checkedItem, index) => {
                const tags = [
                    ...data.tags,
                    ...checkedItem.tags.filter(
                        (tag) => !data.tags.includes(tag)
                    ),
                ];
                update(
                    "products",
                    {
                        id: checkedItem.id,
                        data: { tags },
                    },
                    {
                        onSettled: () => {
                            if (index === selectedIds.length - 1) {
                                unselectAll();
                                setMenu();
                                refresh();
                            }
                        },
                        onError: (e) => {
                            handleHttpError(e, notify);
                        },
                    }
                );
            });
        } else {
            updateMany(
                "products",
                {
                    ids: selectedIds,
                    data,
                },
                {
                    onSettled: () => {
                        unselectAll();
                        setMenu();
                        refresh();
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
        }
    };

    return (
        <Dialog open={true} fullWidth>
            <Card elevation={10} sx={{ paddingBottom: "10px" }}>
                <Form validate={validate} onSubmit={submit}>
                    <Box sx={{ marginTop: "1em", padding: "2em" }}>
                        {menu === "editName" && <EditName />}
                        {menu === "editDescription" && <EditDescription />}
                        {menu === "editPrice" && <EditPrice />}
                        {menu === "addTags" && <AddTags />}
                    </Box>
                    <Toolbar
                        sx={{
                            justifyContent: "space-between",
                            background: "inherit",
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            type="submit"
                            startIcon={<SaveIcon />}
                            title={translate("equip.save")}
                        >
                            {translate("equip.save")}
                        </Button>
                        <Button
                            title={translate("equip.close")}
                            size="medium"
                            onClick={() => setMenu()}
                        >
                            {translate("equip.close")}
                        </Button>
                    </Toolbar>
                </Form>
            </Card>
        </Dialog>
    );
};

const EditPrice = () => {
    const translate = useTranslate();
    return (
        <Box>
            <DialogContent sx={{ paddingLeft: 0, fontSize: 16 }}>
                {translate("equip.editPrice")}
            </DialogContent>
            <RaNumberInput
                source="carnetPrice"
                fullWidth
                options={{ style: "currency", currency: "EUR" }}
                required
            />
        </Box>
    );
};

const EditName = () => {
    const translate = useTranslate();
    return (
        <Box>
            <DialogContent sx={{ paddingLeft: 0, fontSize: 16 }}>
                {translate("equip.editName")}
            </DialogContent>
            <TranslatableInputs locales={["de", "en"]}>
                <RaTextInput source="name" fullWidth />
            </TranslatableInputs>
        </Box>
    );
};

const EditDescription = () => {
    const translate = useTranslate();
    return (
        <Box>
            <DialogContent sx={{ paddingLeft: 0, fontSize: 16 }}>
                {translate("equip.editDescription")}
            </DialogContent>
            <TranslatableInputs locales={["de", "en"]}>
                <RaTextInput
                    source="description"
                    fullWidth
                    multiline
                    rows={3}
                />
            </TranslatableInputs>
        </Box>
    );
};

const AddTags = () => {
    const [create] = useCreate();
    const translate = useTranslate();
    return (
        <Box>
            <DialogContent sx={{ paddingLeft: 0, fontSize: 16 }}>
                {translate("equip.addTags")}
            </DialogContent>
            <ReferenceArrayInput
                source="tags"
                reference="tags"
                label="equip.tags"
                sort={{ field: "name", order: "ASC" }}
                fullWidth
            >
                <RaAutocompleteArrayInput
                    onCreate={(value) => {
                        return create(
                            "tags",
                            { data: { name: value } },
                            { returnPromise: true }
                        ).then((response) => {
                            return response;
                        });
                    }}
                    label={translate("equip.tags")}
                    field="name"
                    multiple={true}
                />
            </ReferenceArrayInput>
        </Box>
    );
};
