import { useState, useEffect, useRef, useCallback } from "react";
import { Paper } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { useDataProvider, useTranslate, useNotify } from "react-admin";
import { queryToExpanded } from "shared/src/utils/decodeQuery";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";

export const CategoryTree = ({ handleFilterChange, id }) => {
    const [rootNodes, setRootNodes] = useState([]);
    const [expanded, setExpanded] = useState(
        queryToExpanded(window.location.hash)
    );
    const filterCategory = id;
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const { current: expandable } = useRef([]);
    const notify = useNotify();

    let filterDataInit = {
        select: false,
        expandable: false,
        id: null,
        expanded: expanded,
    };
    let filterData = filterDataInit;
    const doubleHandle = (id, type) => {
        if (type === "select") {
            filterData.select = true;
            filterData.id = id;
            if (!expandable.includes(id)) {
                filterData.expandable = true;
            }
        }
        if (type === "toggleExpand") {
            filterData.expandable = true;
            const expand = !expanded.includes(id);
            const expandedIds = expand
                ? [...expanded, id]
                : expanded.filter((el) => el !== id);
            filterData.expanded = expandedIds;
            setExpanded(expandedIds);
        }
        if (filterData.select && filterData.expandable) {
            updateFilter(filterData);
        }
    };

    const updateFilter = (data) => {
        handleFilterChange(
            { category: data.id === "-1" ? "" : data.id },
            data.expanded
        );
        filterData = filterDataInit;
    };

    const addExpandable = useCallback(
        (id) => {
            if (!expandable.includes(id)) {
                expandable.push(id);
            }
        },
        [expandable]
    );

    useEffect(() => {
        if (rootNodes.length > 0) {
            return;
        }
        const requestRootNodes = () => {
            dataProvider
                .getRootNodes("categories")
                .then((response) => {
                    response.data.forEach((node) => {
                        addExpandable(node.id);
                    });
                    setRootNodes(response.data);
                })
                .catch((e) => {
                    handleHttpError(e, notify);
                });
        };
        requestRootNodes();
    }, [addExpandable, dataProvider, notify, rootNodes]);

    return (
        <Paper
            sx={{
                width: "100%",
                height: "90vh",
                padding: "15px 0",
                marginTop: "16px",
            }}
            elevation={1}
        >
            <SimpleTreeView
                slots={{
                    collapseIcon: IndeterminateCheckBoxIcon,
                    expandIcon: AddBoxIcon,
                }}
                aria-label={translate("equip.productCategoryTree")}
                expandedItems={expanded}
                onItemExpansionToggle={(e, id) =>
                    doubleHandle(id.toString(), "toggleExpand")
                }
                selected={filterCategory}
                onItemSelectionToggle={(e, id) => doubleHandle(id, "select")}
                selectedItems={[...expanded, filterCategory]}
                sx={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <TreeItem
                    itemId={"-1"}
                    id={"-1"}
                    label={"--" + translate("equip.all") + "---"}
                />

                {rootNodes.map((node, index) => (
                    <ParentNode
                        key={index}
                        node={node}
                        expandedItems={expanded}
                        itemId={node.id.toString()}
                        addExpandable={addExpandable}
                    />
                ))}
            </SimpleTreeView>
        </Paper>
    );
};

const ParentNode = ({ node, expandedItems, addExpandable }) => {
    const dataProvider = useDataProvider();
    const [childrenNodes, setChildrenNodes] = useState(null);
    const children = node?.children.length > 0;
    const notify = useNotify();
    const translate = useTranslate();

    const getChildren = useCallback(() => {
        dataProvider
            .getChildNodes("categories", { parentId: node.id })
            .then((response) => {
                response.data.forEach((node) => {
                    addExpandable(node.id);
                });
                setChildrenNodes(response.data);
            })
            .catch((e) => {
                handleHttpError(e, notify);
            });
    }, [node.id, dataProvider, addExpandable, notify]);

    useEffect(() => {
        if (childrenNodes) {
            return;
        }
        if (expandedItems?.includes(node.id.toString()) && !childrenNodes) {
            getChildren();
        }
    }, [expandedItems, getChildren, node.id, childrenNodes]);

    if (children) {
        return (
            <TreeItem
                id={`$tree-item-${node.id}`}
                itemId={`${node.id}`}
                label={node.name}
                onClick={getChildren}
            >
                {childrenNodes ? (
                    childrenNodes.map((childNode, index) => {
                        return childNode.children.length > 0 ? (
                            <ParentNode
                                key={index}
                                node={childNode}
                                itemId={`${childNode.id}`}
                                expandedItems={expandedItems}
                                addExpandable={addExpandable}
                            />
                        ) : (
                            <TreeItem
                                key={index}
                                itemId={`${childNode.id}`}
                                label={childNode.name}
                            />
                        );
                    })
                ) : (
                    <TreeItem itemId={`0-${node.id}`} />
                )}
            </TreeItem>
        );
    }
    return (
        <TreeItem
            id={`tree-item-${node.id}`}
            itemId={`${node.id}`}
            label={node.id === 1 ? translate("equip.unordered") : node.name}
        />
    );
};

CategoryTree.displayName = "CategoryTree";
ParentNode.displayName = "ParentNode";
