import productConverter from "./productConverter";
import dayjs from "dayjs";

const skuConverter = {
    convertIn: (responseData) => {
        if (responseData.product) {
            responseData.product = productConverter.convertIn(
                responseData.product
            );
        }

        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }

        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        if (responseData.arrival?.createdAt) {
            responseData.arrival.createdAt = dayjs(
                responseData.arrival.createdAt
            );
        }

        if (responseData.arrival?.updatedAt) {
            responseData.arrival.updatedAt = dayjs(
                responseData.arrival.updatedAt
            );
        }

        if (responseData.arrival?.deliveryDate) {
            responseData.arrival.deliveryDate = dayjs(
                responseData.arrival.deliveryDate
            );
        }

        if (responseData.arrival?.orderDate) {
            responseData.arrival.orderDate = dayjs(
                responseData.arrival.orderDate
            );
        }

        return responseData;
    },
    convertOut: (object) => {
        delete object.id;
        delete object.currentPrice;

        return {
            ...object,
            ...(object.arrival && {
                arrival: object.arrival ? object.arrival.id : null,
            }),
            ...(object.product && {
                product: object.product ? object.product.id : null,
            }),
            ...(object.manufacturer && {
                manufacturer: object.manufacturer
                    ? object.manufacturer.id
                    : null,
            }),
            ...(object.supplier && {
                supplier: object.supplier ? object.supplier.id : null,
            }),
            ...(object.storageLocation && {
                storageLocation: object.storageLocation
                    ? object.storageLocation.id
                    : null,
            }),
            ...(object.serialNumber && {
                serialNumber: object.serialNumber
                    ? `${object.serialNumber}`
                    : null,
            }),
        };
    },
};

export default skuConverter;
