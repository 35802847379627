import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import Link from "@mui/material/Link";

export const RentalDetailsModal = ({ rental, onClose }) => {
    const translate = useTranslate();
    return (
        <Dialog fullWidth onClose={onClose} open={true}>
            <DialogTitle textAlign={"center"}>
                <Link href={rental.link ?? `#/rentals/${rental.id}/show`} target='_blank'>
                    {rental.name}
                </Link>
            </DialogTitle>
            <DialogContent>
                <Typography>{`${translate("equip.startDate")}: ${new Date(
                    rental.startDate
                ).toLocaleDateString("de-De")}`}</Typography>
                <Typography>{`${translate("equip.endDate")}: ${new Date(
                    rental.endDate
                ).toLocaleDateString("de-De")}`}</Typography>
                <Typography>{`${translate("equip.weight")}: ${
                    rental.weight.toFixed(2)
                }`}</Typography>
                <Typography>{`${translate("equip.quantity")}: ${
                    rental.itemCount
                }`}</Typography>
                {rental.actualRentalProcess?.renter && <Typography>{`${translate("equip.renter")}: ${
                    rental.actualRentalProcess.renter.firstname
                } ${rental.actualRentalProcess.renter.lastname}`}</Typography>}
            </DialogContent>
        </Dialog>
    );
};
