import CancelIcon from "@mui/icons-material/Cancel";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useDataProvider, useNotify, useRefresh, Button, useTranslate } from "react-admin";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { DialogContent } from "@mui/material";

export const RentalDunning = ({ rental }) => {
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const translate = useTranslate();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const sendDunningEmail = () => {
        dataProvider
            .performAction("rentals", {
                id: rental.id,
                actionName: rental.dunning === null ? "sendDunningEmail" : "sendDunningEmail3",
            })
            .then(() => {
                notify(translate("equip.emailSent"), {
                    type: "success",
                });
                setShowDialog(false);
                refresh();
            })
            .catch((e) => {
                handleHttpError(e, notify);
            });
    };

    return (
        <>
            <Button
                title={translate(
                    "equip.dunning.send_level_" + (rental.dunning ? rental.dunning.level + 1 : 1)
                )}
                label={
                    "equip.dunning.send_level_" + (rental.dunning ? rental.dunning.level + 1 : 1)
                }
                onClick={handleClick}
            >
                <ForwardToInboxIcon />
            </Button>
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
                <DialogTitle>
                    {translate(
                        "equip.dunning.send_level_" +
                            (rental.dunning ? rental.dunning.level + 1 : 1)
                    )}
                </DialogTitle>
                <DialogContent>{translate("equip.dunning.send_dunning_email_text")}</DialogContent>
                <DialogActions sx={{ justifyContent: "space-between", mx: 2 }}>
                    <Button
                        variant="contained"
                        onClick={() => sendDunningEmail()}
                        title={"OK"}
                        label="OK"
                        color={"primary"}
                        startIcon={<ForwardToInboxIcon />}
                    />
                    <Button
                        title={translate("ra.action.cancel")}
                        label="ra.action.cancel"
                        onClick={handleCloseClick}
                        startIcon={<CancelIcon />}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RentalDunning;
