import LockIcon from "@mui/icons-material/Lock";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {
    useDataProvider,
    useNotify,
    useRefresh,
    Button,
    useTranslate,
    useResourceContext,
    usePermissions,
} from "react-admin";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { DialogContent } from "@mui/material";

export const RentalClose = ({ rental }) => {
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const translate = useTranslate();
    const resource = useResourceContext();
    const { permissions } = usePermissions();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const closeRental = () => {
        dataProvider
            .performAction("rentals", {
                id: rental.id,
                actionName: "closeRental",
            })
            .then(() => {
                notify(translate("equip.close_rental_success"), {
                    type: "success",
                });
                setShowDialog(false);
                refresh();
            })
            .catch((e) => {
                handleHttpError(e, notify);
            });
    };

    return (
        <>
            {permissions.vote({
                resource,
                attribute: "edit",
                subject: rental,
            }) && (
                <Button
                    title={translate("equip.close_rental")}
                    label="equip.close_rental"
                    onClick={handleClick}
                >
                    <LockIcon />
                </Button>
            )}
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
                <DialogTitle>{translate("equip.close_rental")}</DialogTitle>
                <DialogContent>{translate("equip.close_rental_text")}</DialogContent>
                <DialogActions sx={{ justifyContent: "space-between", mx: 2 }}>
                    <Button
                        variant="contained"
                        onClick={() => closeRental()}
                        title={"OK"}
                        label="OK"
                        color={"primary"}
                        startIcon={<LockIcon />}
                    />
                    <Button
                        title={translate("ra.action.cancel")}
                        label="ra.action.cancel"
                        onClick={handleCloseClick}
                        startIcon={<CancelIcon />}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RentalClose;
