import {
    Datagrid,
    ListContextProvider,
    TextField,
    useGetList,
    useNotify,
    useRecordContext,
} from "react-admin";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { RaDateField } from "../../../components/fields/Ra/RaDateField";

export const Rentals = ({ showLabelNumber }) => {
    const record = useRecordContext();
    const notify = useNotify();

    const { data, total, isLoading, error } = useGetList("rentalItems", {
        filter: {
            product: record.id,
            _embed: "rental",
        },
        pagination: {
            page: 1,
            perPage: 999,
        },
        sort: { field: "id", order: "DESC" },
    });

    if (isLoading) {
        return <></>;
    }

    if (error) {
        handleHttpError(error, notify);
        return <></>;
    }

    return (
        <ListContextProvider
            value={{
                data: data,
                total: total,
                page: 1,
                perPage: 999,
                sort: { field: "id", order: "DESC" },
            }}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="rental.name" label="equip.rental" />
                { !record.isMassProduct && <TextField source="sku.labelNumber" label="equip.sku" /> }
                <RaDateField source="createdAt" label="equip.date" />
                <RaDateField source="plannedReturnDate" label="equip.plannedReturnDate" />
                <TextField source="quantity" label="equip.quantity" />
                <TextField source="state" label="equip.state" />
            </Datagrid>
        </ListContextProvider>
    );
};
