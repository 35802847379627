import {
    Form,
    TextInput,
    required,
    SaveButton,
    FileInput,
    FileField,
    useDelete,
    regex,
    useTranslate,
    usePermissions,
} from "react-admin";
import { Button, Box } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import DeleteIcon from "@mui/icons-material/Delete";

export const EditView = ({ createMedia, mediaTools, setMediaTools }) => {
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();
    const [deleteOne] = useDelete("media", {
        id: mediaTools.record?.id,
        previousData: mediaTools.record,
    });

    const deleteMedia = (e) => {
        deleteOne();
        setMediaTools({
            edit: null,
            type: null,
            id: null,
            record: null,
        });
    };

    if (isLoading) {
        return <></>;
    }
    return (
        <Form onSubmit={createMedia}>
            <Box pt={1} px={4}>
                {mediaTools.type !== "file" && (
                    <TextInput
                        title={translate("equip.title")}
                        label="equip.title"
                        source="shortname"
                        validate={[required()]}
                        fullWidth
                        defaultValue={mediaTools.record?.shortname}
                    />
                )}

                {mediaTools.type === "file" && !mediaTools.id && (
                    <FileInput
                        source="files"
                        title={translate("equip.allowedFileTypes")}
                        label="equip.allowedFileTypes"
                        accept={{
                                "image/jpeg": [".jpeg"],
                                "image/png": [".png"],
                                "image/gif": [".gif"],
                                "application/pdf": [".pdf"],
                            }}
                        multiple={false}
                        maxSize={209715200}
                        required
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                )}

                {mediaTools.type === "link" && (
                    <TextInput
                        title={translate("equip.insertLink")}
                        label="equip.insertLink"
                        source="url"
                        validate={[
                            required(),
                            /* eslint-disable no-useless-escape */
                            regex(
                                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                                "Must be a valid url"
                            ),
                        ]}
                        fullWidth
                        defaultValue={mediaTools.record && mediaTools.record.url}
                    />
                )}

                {mediaTools.type === "text" && (
                    <RichTextInput
                        source="content"
                        label={" "}
                        defaultValue={mediaTools.record?.content}
                        fullWidth
                        title={translate("equip.content")}
                    />
                )}
                <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
                    <Box>
                        <SaveButton />
                        {mediaTools.record &&
                            permissions.vote({
                                resource: "media",
                                attribute: "delete",
                                subject: mediaTools.record,
                            }) && (
                                <Button
                                    variant="text"
                                    color="error"
                                    size="small"
                                    onClick={(e) => deleteMedia(e)}
                                    startIcon={<DeleteIcon />}
                                    title={translate("equip.delete")}
                                    aria-label={translate("equip.delete")}
                                    sx={{ ml: 3 }}
                                >
                                    {translate("ra.action.delete")}
                                </Button>
                            )}
                    </Box>
                    <Button
                        onClick={() =>
                            setMediaTools({
                                edit: null,
                                type: null,
                                id: null,
                                record: null,
                            })
                        }
                        title={translate("equip.close")}
                        aria-label={translate("equip.close")}
                    >
                        {translate("equip.close")}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
};
