import {
    Filter,
    TextField,
    TextInput,
    usePermissions,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { DefaultPagination } from "../components/DefaultPagination";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaEditButton } from "../components/buttons/RaEditButton";
import { RaListView } from "../components/views/RaListView";

const TagListFilter = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                title={translate("equip.name")}
                label="equip.name"
                source="name"
                alwaysOn
            />
        </Filter>
    );
};

export const TagList = (props) => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaListView
            {...props}
            actions={<ToolbarWithHistory create={true} export={true} />}
            filters={<TagListFilter />}
            pagination={<DefaultPagination />}
            perPage={100}
        >
            <DatagridWithHistory bulkActionButtons={false}>
                <TextField label="equip.name" source="name" />
                {permissions.vote({ resource, attribute: 'edit' }) && <RaEditButton title={translate("equip.edit")} />}
            </DatagridWithHistory>
        </RaListView>
    );
};
