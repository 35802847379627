import { handleHttpError } from "./errorHandler";

export function downloadFile(
    url,
    accept = null,
    filename = null,
    notify = null
) {
    return fetch(url, {
        method: "GET",
        headers: {
            Accept: accept,
        },
    })
        .then((response) => {
            const headers = response.headers;

            if (filename && !filename.includes(".")) {
                const type = headers.get("Content-Type").split("/")[1];
                filename = `${filename}.${type}`;
            }

            const contentDisposition = headers.get("Content-Disposition");
            if (!filename && contentDisposition) {
                const parts = contentDisposition.replace(/"/g, "").split(";");
                filename = parts[1].split("=")[1];
                filename = filename.split("/");
                filename = filename[filename.length - 1];
            }

            if (response.status === 200) {
                return response.blob();
            }

            if (notify) {
                handleHttpError(response, notify);
            }

            throw new Error(response.statusText);
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            link.click();
        });
}
