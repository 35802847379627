import { useTranslate, Button, useRecordContext, useResourceContext } from "react-admin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { queryToRedirectBack, queryToView, queryToNoHashLink } from "shared/src/utils/decodeQuery";
import { useLocation, useNavigate } from "react-router";
import { RaButtonWrapper } from "./RaButtonWrapper";

export const BackButton = () => {
    const translate = useTranslate();
    const location = useLocation();
    const navigate = useNavigate();
    const resource = useResourceContext();
    const record = useRecordContext();

    const view = translate(queryToView(location.pathname));

    const hasHistory = location.search.includes("from=");

    const locationHistory = window.location.href?.split("?from=").slice(1);

    const currentSearch =
        locationHistory?.length > 1
            ? locationHistory.join("?from=")
            : locationHistory.join("?from=");

    const search =
        location.pathname.includes(resource) === false
            ? `?from=${queryToNoHashLink()}`
            : currentSearch
            ? `?from=${currentSearch}`
            : "";

    const redirectable = view === "Edit" || hasHistory;

    const redirectBack = () => {
        if (view === "Edit") {
            navigate({
                pathname: `/${resource}/${record?.id}/show`,
                search: search,
            });
        } else {
            queryToRedirectBack();
        }
    };

    return redirectable ? (
        <RaButtonWrapper title={translate("equip.back")}>
            <Button
                starticon={<ArrowBackIcon />}
                variant="text"
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 15,
                }}
                onClick={(e) => {
                    redirectBack();
                }}
                label={translate("equip.back")}
            />
        </RaButtonWrapper>
    ) : (
        <></>
    );
};
