import { Box } from "@mui/material";
import { Edit, useNotify } from "react-admin";
import { handleHttpError } from "shared/src/utils/errorHandler";

export const RaEditView = (props) => {
    const notify = useNotify();
    const mutationOptions = props.mutationOptions || {};

    if (!mutationOptions.onError) {
        mutationOptions.onError = (error) => {
            handleHttpError(error, notify);
        };
    }

    return (
        <Box marginTop={5} position={"relative"}>
            <Edit {...props} mutationOptions={ mutationOptions }>{props.children}</Edit>
        </Box>
    );
};
