import {
    SimpleForm,
    TextInput,
    PasswordInput,
    SelectArrayInput,
    usePermissions,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { roles } from "shared/src/authProvider";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaCreateView } from "../components/views/RaCreateView";

export const UserCreate = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }

    return (
        <RaCreateView
            redirect={"list"}
            {...props}
        >
            <SimpleForm>
                <TextInput
                    title={translate("equip.firstname")}
                    label="equip.firstname"
                    source="firstname"
                />
                <TextInput
                    title={translate("equip.lastname")}
                    label="equip.lastname"
                    source="lastname"
                />
                <TextInput
                    title={translate("equip.email")}
                    label="equip.email"
                    source="email"
                    type="email"
                />
                <PasswordInput
                    title={translate("equip.password")}
                    label="equip.password"
                    source="password"
                />
                <SelectArrayInput
                    title={translate("equip.roles")}
                    label="equip.roles"
                    source="roles"
                    choices={roles}
                />
                <RaSelectInput
                    label="equip.language"
                    source="locale"
                    choices={[
                        {
                            id: "de_DE",
                            name: translate("equip.language_german"),
                        },
                        {
                            id: "en_EN",
                            name: translate("equip.language_english"),
                        },
                    ]}
                />
            </SimpleForm>
        </RaCreateView>
    );
};
