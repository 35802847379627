import { useState } from "react";
import {
    BooleanField,
    ChipField,
    NumberField,
    ReferenceArrayField,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    SimpleShowLayout,
    useRecordContext,
    useLocaleState,
    usePermissions,
    useResourceContext,
    useTranslate,
    FunctionField,
} from "react-admin";
import { Grid, Box } from "@mui/material";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { ProductTabContentInventory } from "./components/arrivals/mass/ProductTabContentInventory";
import { ProductTabContentSKU } from "./components/arrivals/sku/ProductTabContentSKU";
import { ArrivalsList } from "./components/arrivals/ArrivalsList";
import { NewDelivery } from "./components/arrivals/NewDelivery";
import { MediaManagement } from "./components/media/MediaManagement";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { ChangeLogs } from "./components/changeLogs/ChangeLogs";
import { PrintLabels } from "../components/PrintLabels";
import { ProductImageManagement } from "./components/productImage/ProductImageManagement";
import { EliminateForm } from "../components/EliminateForm";
import { Rentals } from "./components/rentals/Rentals";
import { EliminateButton } from "../components/buttons/EliminateButton";
import { RaShowView } from "../components/views/RaShowView";

const ProductTab = (props) => {
    const record = useRecordContext();
    const translate = useTranslate();
    return (
        <Tab
            {...props}
            type={record.isMassProduct ? "Inventory" : "SKUs"}
            label={record.isMassProduct ? "equip.inventory" : "equip.skus"}
            title={translate(record.isMassProduct ? "equip.inventory" : "equip.skus")}
        />
    );
};

const ProductTabContent = (props) => {
    const record = useRecordContext();
    return record.isMassProduct ? (
        <ProductTabContentInventory {...props} />
    ) : (
        <ProductTabContentSKU {...props} />
    );
};

export const ProductShow = (props) => {
    const [printLabels, setPrintLabels] = useState(false);
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }

    return (
        <RaShowView
            title={translate("equip.products")}
            actions={
                <ToolbarWithHistory
                    back={true}
                    edit={true}
                    // download={true}
                    // downloadLabel={translate("equip.downloadPdf")}
                    // downloadLink="pdf"
                    // email={true}
                    // emailLabel={translate("equip.sendEmail")}
                    // emailLink="send_pdf"
                    print={true}
                    setPrintLabels={setPrintLabels}
                />
            }
            {...props}
        >
            <ProductShowContent
                permissions={permissions}
                printLabels={printLabels}
                setPrintLabels={setPrintLabels}
            />
        </RaShowView>
    );
};

const ProductShowContent = ({ permissions, printLabels, setPrintLabels }) => {
    const [arrivalData, setArrivalData] = useState();
    const [deliveryWindow, setDeliveryWindow] = useState();
    const translate = useTranslate();
    const [eliminate, setEliminate] = useState(false);
    const [locale] = useLocaleState();
    const record = useRecordContext();

    if (!record) {
        return <></>;
    }

    return (
        <Box flex={1}>
            {(deliveryWindow || arrivalData) && (
                <NewDelivery
                    arrivalData={arrivalData}
                    setArrivalData={setArrivalData}
                    deliveryWindow={deliveryWindow}
                    setDeliveryWindow={setDeliveryWindow}
                    setPrintLabels={setPrintLabels}
                />
            )}
            {printLabels && (
                <PrintLabels arrivalData={arrivalData} setPrintLabels={setPrintLabels} />
            )}
            <Grid justifyContent="flex-start" container>
                <Grid sx={{ padding: "10px 0" }} item m={6} marginLeft={0}>
                    <Box marginLeft={6}>
                        <SimpleShowLayout>
                            <TextField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.category"
                                source="category.path"
                            />
                        </SimpleShowLayout>
                    </Box>
                    <Box display={"flex"} marginTop={3}>
                        <ProductImageManagement />
                        {eliminate && <EliminateForm setEliminate={setEliminate} />}
                        <SimpleShowLayout>
                            <TextField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.name"
                                source={`name.${locale}`}
                            />
                            <TextField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.description"
                                source={`description.${locale}`}
                            />
                            <TextField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.manufacturer"
                                source="manufacturer.name"
                            />
                        </SimpleShowLayout>
                    </Box>
                    <Box marginLeft={7} marginTop={3}>
                        <SimpleShowLayout>
                            {record.isMassProduct && (
                                <TextField
                                    emptyText={translate("equip.notAvailable")}
                                    label="equip.labelNumber"
                                    source="labelNumber"
                                />
                            )}
                            <TextField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.edispo.id"
                                source="edispoId"
                            />
                            <FunctionField
                                label="equip.edispo.type"
                                render={(record) =>
                                    translate(
                                        record.edispoType
                                            ? `equip.edispo.${record.edispoType}`
                                            : "equip.notAvailable"
                                    )
                                }
                            />
                            <BooleanField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.isMassProduct"
                                source="isMassProduct"
                            />
                            <EliminateButton
                                sx={{ padding: "10px 0" }}
                                setEliminate={setEliminate}
                            />
                        </SimpleShowLayout>
                    </Box>
                </Grid>
                <Grid item m={6}>
                    <Box>
                        <SimpleShowLayout>
                            <TextField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.supplier"
                                source="supplier.name"
                            />
                            <NumberField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.weight"
                                source="weight"
                                options={{
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    style: "unit",
                                    unit: "kilogram",
                                }}
                            />
                            <TextField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.originCountry"
                                source="originCountry"
                            />
                        </SimpleShowLayout>
                    </Box>
                    <Box marginTop={3}>
                        <SimpleShowLayout>
                            <NumberField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.writeOffYears"
                                source="writeOffYears"
                            />
                            <NumberField
                                emptyText={translate("equip.notAvailable")}
                                label="equip.carnetPrice"
                                source="carnetPrice"
                                options={{
                                    style: "currency",
                                    currency: "EUR",
                                }}
                            />
                        </SimpleShowLayout>
                    </Box>
                    <Box marginTop={3}>
                        <SimpleShowLayout>
                            {record.tags.length && (
                                <ReferenceArrayField
                                    label="equip.tags"
                                    reference="tags"
                                    source="tags"
                                >
                                    <SingleFieldList sx={{ marginTop: 1 }}>
                                        <ChipField source="name" />
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            )}
                        </SimpleShowLayout>
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TabbedShowLayout>
                        <ProductTab>
                            <ProductTabContent
                                arrivalData={arrivalData}
                                setArrivalData={setArrivalData}
                                deliveryWindow={deliveryWindow}
                                setDeliveryWindow={setDeliveryWindow}
                                setPrintLabels={setPrintLabels}
                            />
                        </ProductTab>
                        <Tab label="equip.media" title={translate("equip.media")}>
                            <MediaManagement />
                        </Tab>
                        <Tab label="equip.rentals" title={translate("equip.rentals")}>
                            {<Rentals />}
                        </Tab>
                        {permissions.vote({
                            resource: "arrivals",
                            attribute: "read",
                        }) && (
                            <Tab
                                label="equip.arrivalHistory"
                                title={translate("equip.arrivalHistory")}
                            >
                                <ArrivalsList
                                    arrivalData={arrivalData}
                                    setArrivalData={setArrivalData}
                                    deliveryWindow={deliveryWindow}
                                    setDeliveryWindow={setDeliveryWindow}
                                />
                            </Tab>
                        )}
                        {permissions.vote({
                            resource: "changeLogs",
                            attribute: "read",
                        }) && (
                            <Tab label="equip.changeLogs" title={translate("equip.changeLogs")}>
                                {<ChangeLogs />}
                            </Tab>
                        )}
                    </TabbedShowLayout>
                </Grid>
            </Grid>
        </Box>
    );
};
