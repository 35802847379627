import dayjs from "dayjs";

const categoryConverter = {
    convertIn: (responseData) => {
        if (responseData._embedded?.children) {
            responseData.children = responseData._embedded.children.data;
        }
        if (responseData._embedded?.parent) {
            responseData.parent = responseData._embedded.parent.data;
        }
        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }
        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }
        
        return responseData;
    },
    convertOut: (object) => {
        delete object.id;
        delete object.path;
        delete object.hasChildren;
        delete object.hasParent;
        delete object.children;

        return object;
    },
};

export default categoryConverter;
