import Config from "./config";

export default class PermissionVoter {
    constructor(userObj) {
        this.user = userObj;
        this.config = new Config();
        this.isAdmin = this.user.roles.includes("ROLE_ADMIN");
        this.isUser = this.user.roles.includes("ROLE_USER");
        this.isHelpingHand = this.user.roles.includes("ROLE_HELPING_HAND");
    }
    vote({ resource, attribute, subject = null }) {
        if (resource === "users") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return false;
                case "export":
                    return this.isAdmin;
            }
        }

        if (resource === "tags") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isAdmin;
                case "delete":
                    return this.isAdmin;
                case "export":
                    return this.isUser || this.isAdmin;
            }
        }

        if (resource === "suppliers") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return this.isUser || this.isAdmin;
                case "export":
                    return this.isUser || this.isAdmin;
            }
        }

        if (resource === "storageLocations") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isAdmin;
                case "edit":
                    return this.isAdmin;
                case "delete":
                    return this.isAdmin;
                case "export":
                    return this.isAdmin;
            }
        }

        if (resource === "skus") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return this.isAdmin;
                case "export":
                    return this.isUser || this.isAdmin;
                case "eliminate":
                    return this.isUser || this.isAdmin;
                case "print":
                    return this.isUser || this.isAdmin || this.isHelpingHand;
            }
        }

        if (resource === "skuNotes") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                case "delete":
                    return this.isAdmin || subject?.createdBy?.id === this.user.userId;
            }
        }

        if (resource === "rentals") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return subject?.createdBy?.id === this.user.userId && ["rental_inprogress"].includes(subject?.state);
                case "export":
                    return this.isUser || this.isAdmin;
                case "dunning":
                    return this.isAdmin && ["open", "partially_returned"].includes(subject.state) && null !== subject.renter && ((null === subject.dunning && subject.daysOverdue > 0) || (subject.daysOverdueDunning >= 10 && 2 === subject.dunning.level));
            }
        }

        if (resource === "rentalItems") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin || this.isHelpingHand;
                case "edit":
                case "delete":
                    return this.isAdmin || subject?.createdBy?.id === this.user.userId;
            }
        }

        if (resource === "rentalItemReturns") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin || this.isHelpingHand;
                case "edit":
                case "delete":
                    return this.isAdmin || subject?.createdBy?.id === this.user.userId;
            }
        }

        if (resource === "manufacturers") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return this.isAdmin;
                case "export":
                    return this.isUser || this.isAdmin;
            }
        }

        if (resource === "products") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "eliminate":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return this.isAdmin;
                case "export":
                    return this.isUser || this.isAdmin;
                case "print":
                    return subject && subject.isMassProduct && (this.isUser || this.isAdmin || this.isHelpingHand);
            }
        }

        if (resource === "categories") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return this.isAdmin;
                case "export":
                    return this.isUser || this.isAdmin;
            }
        }

        if (resource === "arrivals") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return subject && subject.quantity === 0 && (this.isUser || this.isAdmin || this.isHelpingHand);
                case "export":
                    return this.isUser || this.isAdmin;
            }
        }

        if (resource === "media") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return true;
                case "create":
                    return this.isUser || this.isAdmin;
                case "edit":
                    return this.isUser || this.isAdmin;
                case "delete":
                    return this.isUser || this.isAdmin;
                case "export":
                    return true;
                case "download":
                    return true;
            }
        }

        if (resource === "changeLogs") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return this.isUser || this.isAdmin;
                case "export":
                    return this.isAdmin;
            }
        }

        if (resource === "rentalLogs") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return this.isUser || this.isAdmin;
                case "export":
                    return this.isAdmin;
            }
        }

        if (resource === "eliminations") {
            // eslint-disable-next-line default-case
            switch (attribute) {
                case "read":
                    return this.isUser || this.isAdmin;
            }
        }
        // resource: resourcename
        // attribute: what you want to do with the resource like "read", "write", "delete"
        // subject should be the data object that we are planning to access or null in case of create
        // soemthings like 'if this.user.roles has "admin" return true'
        return false;
    }
    userMaySeeMenu(menuitemname) {
        if (this.user.roles.includes("ROLE_ADMIN")) {
            return true;
        }

        if (this.user.roles.includes("ROLE_USER")) {
            return !["users", "changeLogs", "eliminations"].includes(menuitemname);
        }

        if (this.user.roles.includes("ROLE_HELPING_HAND")) {
            return ["rentals", "rentalsTimeline", "categories", "products", "skus"].includes(menuitemname);
        }

        return false;
    }
}
