import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import {
    TextField,
    Pagination,
    BooleanField,
    SingleFieldList,
    ReferenceArrayField,
    ChipField,
    useLocaleState,
    usePermissions,
    useResourceContext,
    useTranslate,
    ReferenceInput,
    TextInput,
    Filter,
} from "react-admin";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import { queryToAttribute, queryToFilter } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaAutocompleteInput } from "../components/inputs/Ra/RaAutocompleteInput";
import { RaListView } from "../components/views/RaListView";

const ProductInfoFilter = (props) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <TextInput
                title={translate("equip.name")}
                label="equip.name"
                source="name"
                alwaysOn
            />
            <TextInput
                title={translate("equip.description")}
                label="equip.description"
                source="description"
                alwaysOn
            />
            <RaSelectInput
                alwaysOn
                label="equip.type"
                source="isMassProduct"
                choices={[
                    {
                        id: "0",
                        name: translate("equip.individualSkus"),
                    },
                    {
                        id: "1",
                        name: translate("equip.massProduct"),
                    },
                ]}
            />
            <ReferenceInput
                alwaysOn
                source="labelNumber"
                reference="skus"
                sort={{ field: "labelNumber", order: "ASC" }}
            >
                <RaAutocompleteInput
                    label="equip.labelNumber"
                    field="sku"
                    optionText={(data) => data.labelNumber}
                />
            </ReferenceInput>

            <ReferenceInput
                alwaysOn
                source="manufacturer"
                reference="manufacturers"
                sort={{ field: "name", order: "ASC" }}
            >
                <RaAutocompleteInput
                    label="equip.manufacturer"
                    field="name"
                />
            </ReferenceInput>
            <ReferenceInput
                alwaysOn
                source="supplier"
                reference="suppliers"
                sort={{ field: "name", order: "ASC" }}
            >
                <RaAutocompleteInput label="equip.supplier" field="name" />
            </ReferenceInput>
            <ReferenceInput
                alwaysOn
                source="category"
                reference="categories"
                sort={{ field: "name", order: "ASC" }}
            >
                <RaAutocompleteInput label="equip.category" field="name" />
            </ReferenceInput>
        </Filter>
    );
};

export const ProductInfo = () => {
    const [listItems, setListItems] = useState([]);
    const [filter, setFilter] = useState(queryToFilter(window.location.hash));
    const location = useLocation();
    const [locale] = useLocaleState();
    const resource = useResourceContext();
    const attribute = queryToAttribute();

    const { isLoading, permissions } = usePermissions();

    useEffect(() => {
        setFilter(queryToFilter(window.location.hash));
    }, [location]);

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaListView
            className="listlistlist"
            resource="products"
            filters={<ProductInfoFilter />}
            perPage={100}
            pagination={<Pagination rowsPerPageOptions={[100, 200, 500]} />}
            actions={<ToolbarWithHistory />}
        >
            <DatagridWithHistory
                listItems={listItems}
                setListItems={setListItems}
                bulkActionButtons={false}
            >
                <TextField source="id" />
                <TextField source={`name.${locale}`} label="equip.name" />
                <TextField
                    source={`description.${locale}`}
                    label="equip.description"
                />

                <BooleanField
                    source="isMassProduct"
                    label="equip.isMassProduct"
                />

                <ReferenceArrayField
                    label="equip.tags"
                    reference="tags"
                    source="tags"
                >
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField
                    label="equip.manufacturer"
                    source="manufacturer.name"
                />
                <TextField label="equip.supplier" source="supplier.name" />
                {!filter?.category && (
                    <TextField label="equip.category" source="category.name" />
                )}
                <TextField label="equip.inStock" source="quantity" />
                <TextField
                    label="equip.actualQuantity"
                    source="actualQuantity"
                />
            </DatagridWithHistory>
        </RaListView>
    );
};
