import {
    SimpleForm,
    Toolbar,
    SaveButton,
    useRecordContext,
    useResourceContext,
    usePermissions,
    useTranslate,
} from "react-admin";
import { RaDeleteButton } from "./buttons/RaDeleteButton";

export const SimpleFormWithPermissions = (props) => {
    return <SimpleForm {...props} toolbar={<ToolbarWithPermissions {...props} />} />;
};

const ToolbarWithPermissions = (props) => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const attribute = "delete";
    const { isLoading, permissions } = usePermissions();
    const translate = useTranslate();

    if (isLoading) {
        return <></>;
    }
    return (
        <Toolbar {...props} sx={{ display: "flex", justifyContent: "space-between" }}>
            <SaveButton title={translate("equip.save")} />
            {permissions.vote({
                resource,
                attribute,
                subject: record,
            }) && <RaDeleteButton mutationMode="pessimistic" />}
        </Toolbar>
    );
};
