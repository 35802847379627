import { usePermissions, useRecordContext, useResourceContext, useTranslate } from "react-admin";
import { RaButtonWrapper } from "./RaButtonWrapper";
import PrintIcon from "@mui/icons-material/Print";
import { Button } from "@mui/material";

export const PrintButton = ({ setPrintLabels }) => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const record = useRecordContext();
    const { isLoading, permissions } = usePermissions();

    if (
        isLoading ||
        !record ||
        (!permissions.vote({
            resource,
            attribute: "print",
            subject: record,
        }))
    ) {
        return <></>;
    }

    return (
        <RaButtonWrapper title={translate("equip.printLabels")}>
            <Button
                onClick={() => setPrintLabels(true)}
                size="small"
                variant="text"
                startIcon={<PrintIcon />}
                sx={{ display: "flex", alignItems: "center", pb: 0.3 }}
            >
                {translate("equip.printLabels")}
            </Button>
        </RaButtonWrapper>
    );
};
