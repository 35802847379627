import { Box, Typography } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";

export const rentalItemGroupsToChartData = (
    rentalItemGroups,
    startDate,
    endDate,
    translate,
    theme
) => {
    const columns = [
        { type: "string", id: "RentalId" },
        { type: "string", id: "Name" },
        { type: "string", role: "tooltip" },
        { type: "string", role: "style" },
        { type: "date", id: "Start" },
        { type: "date", id: "End" },
    ];

    return rentalItemGroups
        ? [
              columns,
              ...rentalItemGroups.map((rentalItemGroup) => {
                  let sDate = rentalItemGroup.startDate;
                  let eDate = rentalItemGroup.endDate;

                  if (eDate > endDate) {
                      eDate = endDate;
                  }
                  if (sDate < startDate) {
                      sDate = startDate;
                  }

                  const tooltip = document.createElement("div");
                  const tooltipHtml = renderToStaticMarkup(
                      <Tooltip
                          rentalItemGroup={rentalItemGroup}
                          translate={translate}
                          theme={theme}
                      />
                  );

                  let styles = "color: ";
                  switch (rentalItemGroup.rentalImpact) {
                      case "light":
                          styles += "#4285F4";
                          break;
                      case "medium":
                          styles += "#F46400";
                          break;
                      case "heavy":
                          styles += "#DB4437";
                          break;
                      default:
                          styles += "#fff";
                  }

                  if (rentalItemGroup.startDate < startDate && rentalItemGroup.endDate > endDate) {
                      styles += ";fill-opacity: .5";
                  } else if (rentalItemGroup.startDate < startDate) {
                      styles += ";fill-opacity: .1";
                  } else if (rentalItemGroup.endDate > endDate) {
                      styles += ";fill-opacity: .9";
                  }

                  tooltip.innerHTML = tooltipHtml;
                  return [
                      `${rentalItemGroup.rentalInfo.name} (${rentalItemGroup.rentalInfo.id})`,
                      rentalItemGroup.rentalInfo.name.replace(/^\d{7}[A-Z]{3}\s/, ""),
                      `${tooltip.innerHTML}`,
                      styles,
                      sDate.toDate(),
                      eDate.toDate(),
                  ];
              }),
          ]
        : [];
};

const Tooltip = ({ rentalItemGroup, translate, theme }) => {
    const name = rentalItemGroup.rentalInfo.name;
    const startDate = rentalItemGroup.startDate.format("DD.MM.YYYY");
    const endDate = rentalItemGroup.endDate.format("DD.MM.YYYY");
    const weight = rentalItemGroup.weight.toFixed(2);

    if (!theme) {
        return;
    }

    return (
        <Box id={`google-chart-tooltip-${theme}`} p={1} width={200}>
            <Typography textAlign={"center"} fontWeight={"bolder"}>
                {name}
            </Typography>
            <Box textAlign={"left"}>
                <Typography sx={{ color: "black!important" }}>
                    {translate("equip.startDate") + `: ${startDate}`}
                </Typography>
                <Typography>{translate("equip.endDate") + `: ${endDate}`}</Typography>
                <Typography>{translate("equip.weight") + `: ${weight}`}</Typography>
                <Typography>
                    {translate("equip.quantity") +
                        `: ${rentalItemGroup.itemCount} ` +
                        translate("equip.items") +
                        (rentalItemGroup.rentalImpactCount !== rentalItemGroup.itemCount
                            ? ` (${rentalItemGroup.rentalImpactCount})`
                            : "")}
                </Typography>
            </Box>
        </Box>
    );
};
