import { Box } from "@mui/material";
import { Show } from "react-admin";

export const RaShowView = (props) => {
    return (
        <Box marginTop={5} position={"relative"}>
            <Show {...props}>{props.children}</Show>
        </Box>
    );
};
