import { Box } from "@mui/material";
import { useTheme as useRaTheme } from "react-admin";
import { useTheme } from "@emotion/react";

export const Spacer = ({ x, y, children, ...rest }) => {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            width={x ? theme.spacing(x) : undefined}
            height={y ? theme.spacing(y) : undefined}
        >
            {children}
        </Box>
    );
};

export const Container = ({ ml, mr, mt, mb, pl, pr, pt, pb, children, ...rest }) => {
    const theme = useTheme();
    return (
        <Box
            marginLeft={ml && theme.spacing(ml)}
            marginRight={mr && theme.spacing(mr)}
            marginTop={mt && theme.spacing(mt)}
            marginBottom={mb && theme.spacing(mb)}
            paddingLeft={pl && theme.spacing(pl)}
            paddingRight={pr && theme.spacing(pr)}
            paddingTop={pt && theme.spacing(pt)}
            paddingBottom={pb && theme.spacing(pb)}
            {...rest}
        >
            {children}
        </Box>
    );
};

export const ButtonContainer = ({ children, flexDirection, display }) => {
    const [theme, setTheme] = useRaTheme();

    return (
        <Container sx={{width: "100%", paddingTop: 12}}>
            <Container
                display={display && display}
                flexDirection={flexDirection && flexDirection}
                sx={{
                    width: "100%",
                    position: "fixed",
                    bottom: 0,
                    margin: "auto auto",
                    left: 0,
                    right: 0,
                    backgroundColor: theme === "dark" ? "#313131" : "#fafafb",
                    zIndex: 2,
                }}
                pl={2}
                pr={2}
                pt={2}
                pb={2}
            >
                {children}
            </Container>
        </Container>
    );
};
