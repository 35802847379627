import {useRedirect} from "react-admin";
import {useEffect} from "react";

export const ProductList = (props) => {
    const redirect = useRedirect();

    useEffect(() => {
        redirect("/categories");
    });

    return <></>;
};
