import {
    Button,
    useLocaleState,
    useNotify,
    usePermissions,
    useRecordContext,
    useResourceContext,
} from "react-admin";
import { RaButtonWrapper } from "./RaButtonWrapper";
import DownloadIcon from "@mui/icons-material/GetApp";
import { downloadFile } from "shared/src/utils/downloadHandler";

export const DownloadButton = ({ downloadLink, downloadLabel }) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const { isLoading, permissions } = usePermissions();
    const [locale] = useLocaleState();
    const notify = useNotify();

    if (
        !record?.id ||
        !record._links[downloadLink] ||
        isLoading ||
        !permissions.vote({
            resource,
            attribute: "read",
            subject: record,
        })
    ) {
        return <></>;
    }

    return (
        <RaButtonWrapper title={downloadLabel}>
            <Button
                href={`${record._links[downloadLink]}?_locale=${locale}`}
                onClick={(event) => {
                    event.preventDefault();
                    downloadFile(event.target.href, null, null, notify);
                }}
                label={downloadLabel}
                color={"primary"}
            >
                <DownloadIcon />
            </Button>
        </RaButtonWrapper>
    );
};
