import Link from "@mui/material/Link";
import { Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";
import {
    useDataProvider,
    useLocaleState,
    useNotify,
    useTranslate,
} from "react-admin";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { extractBreadcrumbs } from "shared/src/utils/extractBreadcrumbs";
import { useEffect, useState } from "react";

export const Breadcrumbs = ({ record }) => {
    const url = window.location.href;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [locale] = useLocaleState();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const loadBreadcrumbs = async () => {
            const links = await extractBreadcrumbs({
                record,
                locale,
                notify,
                dataProvider,
            }) || [];
            setBreadcrumbs([...links]);
        };
        loadBreadcrumbs();
    }, [record, locale, notify, dataProvider, url]);

    const categoryCRUD = (index) => {
        return breadcrumbs[index].resource === "categories" && index > 0;
    };

    if (breadcrumbs.length === 1 && breadcrumbs[0].resource !== "categories") {
        return (
            <MuiBreadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Link
                    href={`#/${breadcrumbs[0].resource}`}
                    title={translate(breadcrumbs[0].name)}
                    aria-label={translate(breadcrumbs[0].name)}
                >
                    {translate(breadcrumbs[0].name)}
                </Link>
                {breadcrumbs[0].view === "ra.action.edit" && (
                    <Link
                        href={`#/${breadcrumbs[0].resource}`}
                        title={breadcrumbs[0].item}
                        aria-label={breadcrumbs[0].item}
                    >
                        {breadcrumbs[0].item}
                    </Link>
                )}
                <Typography color="text.primary">
                    {breadcrumbs[0].view === "ra.action.show"
                        ? breadcrumbs[0].item
                        : translate(breadcrumbs[0].view)}
                </Typography>
            </MuiBreadcrumbs>
        );
    }

    return (
        <MuiBreadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {breadcrumbs.map((el, index) => {
                return (
                    !categoryCRUD(index) &&
                    (el.view !== "ra.action.show" ||
                        index !== breadcrumbs.length - 1) && (
                        <Link
                            title={el.item ? el.item : translate(el.name)}
                            aria-label={el.item ? el.item : translate(el.name)}
                            key={index}
                            href={
                                index > 0
                                    ? el.href
                                    : `#/${breadcrumbs[0].resource}`
                            }
                        >
                            {el.item ? el.item : translate(el.name)}
                        </Link>
                    )
                );
            })}

            <Typography color="text.primary">
                {breadcrumbs[breadcrumbs.length - 1]?.view === "ra.action.show"
                    ? breadcrumbs[breadcrumbs.length - 1]?.item
                    : translate(
                          breadcrumbs[breadcrumbs.length - 1]?.view ?? ""
                      )}
            </Typography>
        </MuiBreadcrumbs>
    );
};
