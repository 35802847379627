import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslate } from "react-admin";
import { Button } from "@mui/material";

export const ConfirmationDialog = ({
    fullHeight,
    buttonSize = "medium",
    confirmButton = true,
    cancelButton = true,
    cancelColor = "error",
    confirmColor = "primary",
    onConfirm,
    onCancel,
    label,
    cancelLabel,
    confirmLabel,
    content,
    confirmDisabled,
    cancelDisabled,
    dangerouslySetInnerHTML,
    open = true,
}) => {
    const translate = useTranslate();

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{label}</DialogTitle>
            <DialogContent
                sx={{
                    height: fullHeight && "100vh",
                    px: 2,
                }}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            >
                {content}
            </DialogContent>
            <List sx={{ pt: 0, mb: 0 }}>
                {confirmButton && (
                    <ListItem button onClick={onConfirm}>
                        <ListItemText
                            sx={{ pb: 0, mb: 0 }}
                            primary={
                                <Button
                                    fullWidth
                                    disabled={confirmDisabled}
                                    size={buttonSize}
                                    color={confirmColor}
                                    variant={"contained"}
                                    aria-label={
                                        confirmLabel
                                            ? confirmLabel
                                            : translate(
                                                  "equip.confirm"
                                              ).toUpperCase()
                                    }
                                    title={
                                        confirmLabel
                                            ? confirmLabel
                                            : translate(
                                                  "equip.confirm"
                                              ).toUpperCase()
                                    }
                                >
                                    {confirmLabel
                                        ? confirmLabel
                                        : translate(
                                              "equip.confirm"
                                          ).toUpperCase()}
                                </Button>
                            }
                        />
                    </ListItem>
                )}
                {cancelButton && (
                    <ListItem button onClick={onCancel} sx={{ pt: 0, mt: 0 }}>
                        <ListItemText
                            sx={{ pt: 0, mt: 0 }}
                            color="text.primary"
                            primary={
                                <Button
                                    disabled={cancelDisabled}
                                    fullWidth
                                    size={buttonSize}
                                    color={cancelColor}
                                    variant={"contained"}
                                    aria-label={
                                        cancelLabel
                                            ? cancelLabel
                                            : translate(
                                                  "equip.cancel"
                                              ).toUpperCase()
                                    }
                                    title={
                                        cancelLabel
                                            ? cancelLabel
                                            : translate(
                                                  "equip.cancel"
                                              ).toUpperCase()
                                    }
                                >
                                    {cancelLabel
                                        ? cancelLabel
                                        : translate(
                                              "equip.cancel"
                                          ).toUpperCase()}
                                </Button>
                            }
                        />
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
};
