import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGetList, useTranslate, useDataProvider, useTheme } from "react-admin";
import { TimeframeDatepicker } from "./TimefameDatePickers";
import { TimeframePagination } from "./TimefamePagination";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { rentalItemGroupsToChartData } from "./utils/rentalItemGroupsToChartData";
import { rentalsToRentalItemGroups } from "./utils/rentalsToRentalItemGroups";
import { useNavigate } from "react-router-dom";
import { queryFromHrefToFilter } from "shared/src/utils/decodeQuery";
import { useLocation } from "react-router";
import { TimelineChart } from "./TimelineChart";
import { Breadcrumbs } from "../components/Breadcrumbs";

export const RentalsTimeline = () => {
    const [theme] = useTheme();
    const dataProvider = useDataProvider();
    const location = useLocation();
    const navigate = useNavigate();
    const translate = useTranslate();

    const [chartData, setChartData] = useState(null);
    const [period, setPeriod] = useState(7); //in days
    const [chartOptions, setChartOptions] = useState();

    const startDateRef = useRef(dayjs().startOf("day"));
    const endDateRef = useRef(dayjs().add(7, "day").endOf("day"));
    const chartDataRef = useRef([]);
    const timeframeRef = useRef({
        startDate: startDateRef.current,
        endDate: endDateRef.current,
    });
    const rentalItemGroupsRef = useRef([]);
    const edispoDataRef = useRef([]);

    const [chartRenderer, setChartRenderer] = useState({ theme: theme, render: true });

    const { data: rentals, isLoading } = useGetList(
        "rentals",
        {
            pagination: { page: 1, perPage: 999 },
            sort: { field: "endDate", order: "ASC" },
            filter: {
                state: "open",
                _embed: "rentalItems",
            },
        },
        {
            onSettled: (response) => {
                updateEdispoData(startDateRef.current, endDateRef.current, response.data);
            },
        }
    );

    const updateEdispoData = useCallback(
        (sDate, eDate, rentals) => {
            if (!rentals) {
                return;
            }

            dataProvider
                .get(
                    `/edispo/rentalJournal/${sDate.format("YYYY-MM-DD")}/${eDate.format(
                        "YYYY-MM-DD"
                    )}`
                )
                .then((response) => {
                    const newRentalItemsData = rentalsToRentalItemGroups(
                        [...rentals, ...response.data.journalData],
                        sDate,
                        eDate
                    );

                    const newChartData = rentalItemGroupsToChartData(
                        newRentalItemsData,
                        sDate,
                        eDate,
                        translate,
                        theme
                    );

                    rentalItemGroupsRef.current = newRentalItemsData;
                    chartDataRef.current = newChartData;
                    edispoDataRef.current = response.data.journalData;
                    setChartData(newChartData);
                });
        },
        [dataProvider, translate, theme]
    );

    const updateUrl = useCallback(
        (sDate, eDate) => {
            const jsonFilter = JSON.stringify({
                startDate: sDate.format("YYYY-MM-DD"),
                endDate: eDate.format("YYYY-MM-DD"),
            });

            const encodedFilter = encodeURI(`filter=${jsonFilter}`);
            navigate({
                pathname: "/rentalsTimeline",
                search: `?${encodedFilter}`,
            });
            updateEdispoData(sDate, eDate, rentals);
        },
        [navigate, rentals, updateEdispoData]
    );

    const prepareData = useCallback(
        (sDate, eDate) => {
            if (!rentals) {
                return;
            }

            setChartOptions({
                hAxis: {
                    format: "dd.MM.YY",
                    minValue: sDate,
                    maxValue: eDate,
                },
                timeline: {
                    showRowLabels: false,
                },
            });

            timeframeRef.current = {
                startDate: sDate,
                endDate: eDate,
            };

            rentalItemGroupsRef.current = rentalsToRentalItemGroups(rentals, sDate, eDate);

            chartDataRef.current = rentalItemGroupsToChartData(
                rentalItemGroupsRef.current,
                sDate,
                eDate,
                translate
            );
            if (chartDataRef.current.length > 0) {
                setChartData([...chartDataRef.current]);
            }
        },
        [rentals, translate]
    );

    useEffect(() => {
        let sDate = startDateRef.current;
        let eDate = endDateRef.current;

        const hash = window.location.hash;
        const filter = queryFromHrefToFilter(hash);
        const filterStartDate = filter?.startDate
            ? dayjs(filter?.startDate).startOf("day")
            : dayjs();

        const filterEndDate = filter?.endDate ? dayjs(filter?.endDate).endOf("day") : dayjs();

        if (!filterStartDate && !filterEndDate) {
            updateUrl(sDate, eDate);
            return;
        }

        const isCorrectDateState =
            filterStartDate.format("DD.MM.YYY") === startDateRef.current.format("DD.MM.YYY") &&
            filterEndDate.format("DD.MM.YYY") === endDateRef.current.format("DD.MM.YYY");

        sDate = filterStartDate;
        eDate = filterEndDate;

        if (!isCorrectDateState || !chartData) {
            startDateRef.current = sDate;
            endDateRef.current = eDate;
            setPeriod(endDateRef.current.diff(startDateRef.current, "day"));
            prepareData(sDate, eDate);
        }
    }, [location, chartData, prepareData, updateUrl]);

    useEffect(() => {
        if (theme !== chartRenderer.theme && chartRenderer.render) {
            setChartRenderer({ theme: theme, render: false });
        } else if (!chartRenderer.render) {
            setChartRenderer({ theme: theme, render: true });
        }
    }, [theme, chartRenderer]);

    if (isLoading) {
        return <></>;
    }

    return (
        <>
            <Box marginTop={2}>
                <Breadcrumbs />
            </Box>
            <Box marginBottom={1} display="flex" justifyContent="left">
                <TimeframePagination
                    startDate={startDateRef.current}
                    updateUrl={updateUrl}
                    period={period}
                />
                <TimeframeDatepicker
                    startDate={startDateRef.current}
                    endDate={endDateRef.current}
                    updateUrl={updateUrl}
                    period={period}
                />
            </Box>
            <Typography fontSize={18} fontWeight={500} textAlign={"center"}>
                {translate("equip.rentalsTimeline")}
            </Typography>
            {chartRenderer.render &&
                (chartData?.length > 1 ? (
                    <TimelineChart
                        chartOptions={chartOptions}
                        chartData={chartData}
                        rentalItemGroupsRef={rentalItemGroupsRef}
                    />
                ) : (
                    <Typography textAlign={"center"} marginTop={3}>
                        {translate("equip.rentalsTimeline_noData")}
                    </Typography>
                ))}
        </>
    );
};
