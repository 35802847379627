import {
    useRecordContext,
    ListContextProvider,
    useNotify,
    useGetList,
} from "react-admin";
import { ChangeLogsDatagrid } from "../../changeLogs/ChangeLogsList";
import { handleHttpError } from "shared/src/utils/errorHandler";

export const SkuChangeLogs = () => {
    const record = useRecordContext();
    const notify = useNotify();

    const { data, total, isLoading, error } = useGetList("changeLogs", {
        filter: {
            referenceType: `App\\Entity\\Sku`,
            referenceId: record.id,
        },
        pagination: {
            page: 1,
            perPage: 999,
        },
        sort: { field: "id", order: "DESC" },
    });

    const sort = { field: "createdAt", order: "DESC" };
    if (isLoading) {
        return <></>;
    }
    if (error) {
        handleHttpError(error, notify);
        return <></>;
    }
    return (
        <ListContextProvider
            value={{
                data: data,
                total: total,
                page: 1,
                perPage: 999,
                sort,
            }}
        >
            <ChangeLogsDatagrid />
        </ListContextProvider>
    );
};
