import { Button, useTranslate } from "react-admin";
import { BulkActionsPopup } from "./BulkActionsPopup";
import { useState } from "react";
import { Box } from "@mui/system";

export const BulkActions = (props) => {
    const [menu, setMenu] = useState();
    const translate = useTranslate();

    return (
        <Box
            sx={{
                "& button": {
                    ml: 1,
                },
            }}
        >
            {menu && (
                <BulkActionsPopup {...props} menu={menu} setMenu={setMenu} />
            )}
            <Button
                onClick={() => setMenu("editName")}
                variant={"contained"}
                label={translate("equip.editName")}
            />
            <Button
                onClick={() => setMenu("editDescription")}
                variant={"contained"}
                label={translate("equip.editDescription")}
            />
            <Button
                onClick={() => setMenu("editPrice")}
                variant={"contained"}
                label={translate("equip.editPrice")}
            />
            <Button
                onClick={() => setMenu("addTags")}
                variant={"contained"}
                label={translate("equip.addTags")}
            />
        </Box>
    );
};
