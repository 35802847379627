// https://marmelab.com/react-admin/Authentication.html
import PermissionVoter from "./PermissionVoter";

export const roles = [
    { id: "ROLE_ADMIN", name: "ROLE_ADMIN" },
    { id: "ROLE_USER", name: "ROLE_USER" },
    { id: "ROLE_HELPING_HAND", name: "ROLE_HELPING_HAND" },
    { id: "ROLE_GUEST", name: "ROLE_GUEST" },
    { id: "ROLE_ADB", name: "ROLE_ADB" },
];

const logout = () => {
    const request = new Request("/logout", {
        method: "GET",
        headers: new Headers({ "Accept": "application/json" }),
    });

    return fetch(request)
        .finally((response) => {
            localStorage.removeItem("auth");
            location.hash = '#/login';
        });
}

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request("/jsonlogin", {
            method: "POST",
            body: JSON.stringify({ username: username, password: password }),
            headers: new Headers({ "Content-Type": "application/json", "Accept": "application/json" }),
        });
        return fetch(request)
            .then(async (response) => {
                if (response.status < 200 || response.status >= 400) {
                    const body = await response.json();
                    throw new Error(body.error);
                }
                return response.json();
            })
            .then((auth) => {
                localStorage.setItem("auth", JSON.stringify(auth.data));
            });
    },
    checkError: async (error) => {
        const status = error.status;
        if (status === 401) {
            await logout();

            return Promise.reject();
        }

        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),
    logout: async () => {
        await logout();
    },
    getIdentity: () => {
        try {
            const { id, firstname, lastname } = JSON.parse(
                localStorage.getItem("auth")
            );
            return Promise.resolve({ id, firstname, lastname });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        const authStr = localStorage.getItem("auth");
        if (authStr === null) {
            return Promise.reject("no auth");
        }
        const auth = JSON.parse(authStr);
        if (
            typeof auth === "object" &&
            auth !== null &&
            auth.hasOwnProperty("roles")
        ) {
            const roles = auth.roles;
            return roles
                ? Promise.resolve(new PermissionVoter(auth))
                : Promise.reject("no roles");
        } else {
            return Promise.reject("not a json string");
        }
    },
};

export default authProvider;
