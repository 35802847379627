import dayjs from "dayjs";

export const rentalsToRentalItemGroups = (rentals, startDate, endDate) => {
    let rentalItemGroups = {};

    rentals.forEach((rental) => {
        const rentalItems = rental.rentalItems;
        if (rentalItems) {
            rentalItems.forEach((rentalItem) => {
                if (rentalItem) {
                    const key = `${rental.id}-${rental.startDate}-${rental.endDate}}`;
                    if (rentalItemGroups[key]) {
                        rentalItemGroups[key].weight += rentalItem.weight;
                        rentalItemGroups[key].itemCount += rentalItem.quantity;
                        rentalItemGroups[key].rentalImpactCount +=
                            rentalItem.quantity;
                    } else {
                        rentalItemGroups[key] = {
                            rentalInfo: {
                                ...rental,
                                startDate: dayjs(rental.startDate),
                                endDate: rentalItem.plannedReturnDate,
                            },
                            itemCount: rentalItem.quantity,
                            weight: rentalItem.weight,
                            rentalImpactCount: rentalItem.quantity,
                            endDate: rentalItem.plannedReturnDate?.endOf("day"),
                            startDate: dayjs(rental.startDate).startOf("day"),
                        };
                    }
                }
            });
        } else {
            const key = `${rental.id}-${rental.startDate}-${rental.endDate}}`;
            rentalItemGroups[key] = {
                rentalInfo: {
                    ...rental,
                    startDate: dayjs(rental.startDate),
                    endDate: dayjs(rental.endDate),
                },
                weight: rental.weight,
                itemCount: rental.itemCount,
                rentalImpactCount: rental.rentalImpactCount,
                endDate: dayjs(rental.endDate).endOf("day"),
                startDate: dayjs(rental.startDate).startOf("day"),
            };
        }
    });

    let sortedRentalItemGroups = Object.keys(rentalItemGroups)
        .sort((a, b) => {
            const groupA = rentalItemGroups[a];
            const groupB = rentalItemGroups[b];
            return groupA.rentalInfo.name !== groupB.rentalInfo.name
                ? 0
                : groupA.endDate - groupB.endDate;
        })
        .map((key) => rentalItemGroups[key]);

    sortedRentalItemGroups.forEach((rentalItemGroup, index) => {
        let rentalImpact = "light";
        if (rentalItemGroup.rentalImpactCount > 500) {
            rentalImpact = "heavy";
        } else if (rentalItemGroup.rentalImpactCount > 100) {
            rentalImpact = "medium";
        }

        rentalItemGroup.rentalImpact = rentalImpact;
    });

    return sortedRentalItemGroups.filter((rental) => {
        return rental.startDate <= endDate && rental.endDate >= startDate;
    });
};
