import { useRef, useState } from "react";
import {
  NumberInput,
  ReferenceInput,
  TextInput,
  required,
  Form,
  Toolbar,
  useUpdate,
  useTranslate,
  useRecordContext,
  useRefresh,
  useDataProvider,
  useNotify,
} from "react-admin";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { RaAutocompleteInput } from "../../../../components/inputs/Ra/RaAutocompleteInput";
import ContentAdd from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export const CreateSkuForm = (props) => {
  const [update] = useUpdate();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const productRecord = useRecordContext();
  const dataProvider = useDataProvider();
  const refInput = useRef();
  const [serialNumbers, setSerialNumbers] = useState([]);

  const submit = (e) => {
    if (props.skuRecord) {
      update(
        "skus",
        {
          id: props.skuRecord.id,
          data: {
            ...props.skuRecord,
            product: productRecord,
            sku: e.sku,
            manufacturer: e.manufacturer,
            supplier: props.arrivalData.supplierId,
            storageLocation: e.storageLocation,
            weight: e.weight,
            serialNumber: e.serialNumber,
          },
          previousData: props.skuRecord,
        },
        {
          onSettled: () => {
            props.setDeliveryWindow("editSku");
            props.setSkuRecord();
          },
          onError: (e) => {
            handleHttpError(e, notify);
          },
        }
      );
    } else {
      const requestData = [];

      for (let i = 0; i < serialNumbers.length; i++) {
        requestData.push({
          arrival: props.arrivalData,
          sku: e.sku,
          product: productRecord,
          manufacturer: e.manufacturer,
          supplier: { id: props.arrivalData.supplierId },
          storageLocation: e.storageLocation,
          weight: e.weight,
          serialNumber: serialNumbers[i],
        });
      }

      dataProvider
        .createMany("skus", {
          data: requestData,
        })
        .then(() => {
          refresh();
          props.setDeliveryWindow("editSku");
        })
        .catch((e) => {
          handleHttpError(e, notify);
        });
    }
  };

  const addSerialNumber = (serialNumber) => {
    refInput.current.value = "";
    if (serialNumber && serialNumbers.includes(serialNumber)) {
      return;
    }
    setSerialNumbers([...serialNumbers, serialNumber ? serialNumber : "N/A"]);
  };

  const removeSerialNumber = (index) => {
    setSerialNumbers((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  return (
    <Form
      onSubmit={submit}
      record={{
        ...props.skuRecord,
      }}
    >
      <Box px={3} pt={2}>
        <Box mb={2}>
          <Typography variant="p">
            {translate(props.skuRecord ? "equip.editSku" : "equip.createSku")}
          </Typography>
        </Box>

        <ReferenceInput
          source="manufacturer.id"
          reference="manufacturers"
          sort={{ field: "name", order: "ASC" }}
        >
          <RaAutocompleteInput
            label="equip.manufacturer"
            validate={[required()]}
            field="name"
          />
        </ReferenceInput>
        <ReferenceInput
          source="storageLocation.id"
          reference="storageLocations"
          sort={{ field: "name", order: "ASC" }}
        >
          <RaAutocompleteInput label="equip.storageLocation" field="name" />
        </ReferenceInput>
        <NumberInput
          title={translate("equip.weightPerItem")}
          label="equip.weightPerItem"
          source="weight"
        />
        {props.skuRecord ? (
          <TextInput
            title={translate("equip.serialNumber")}
            label="equip.serialNumber"
            source="serialNumber"
          />
        ) : (
          <Box sx={{ display: "flex", alignItems: "baseline", mb: 3 }}>
            <TextField
              title={translate("equip.serialNumber")}
              label={translate("equip.serialNumber")}
              source="sku"
              validate={[required()]}
              inputRef={refInput}
              type="tel"
            />
            <Button
              sx={{
                color: (theme) =>
                  theme.palette.getContrastText(theme.palette.primary.main),
                backgroundColor: (theme) => theme.palette.primary.main,
                borderRadius: "50%",
                height: "48px",
                minWidth: "48px",
                padding: "0px!important",
                marginLeft: 3,
              }}
              onClick={() => addSerialNumber(refInput.current.value)}
            >
              <ContentAdd />
            </Button>
          </Box>
        )}
        <SerialNumberTable
          serialNumbers={serialNumbers}
          removeSerialNumber={removeSerialNumber}
        />
      </Box>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "inherit",
        }}
      >
        <Button
          size="small"
          variant="contained"
          type="submit"
          title={translate("equip.save")}
          aria-label={translate("equip.save")}
        >
          {translate("ra.action.save")}
        </Button>

        <Button
          onClick={() => {
            props.setDeliveryWindow("editSku");
          }}
          title={translate("equip.close")}
          aria-label={translate("equip.close")}
        >
          {translate("equip.close")}
        </Button>
      </Toolbar>
    </Form>
  );
};

const SerialNumberTable = ({ serialNumbers, removeSerialNumber }) => {
  const translate = useTranslate();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="left">
              {translate("equip.serialNumber")}
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serialNumbers.map((serialNumber, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="left">{serialNumber}</TableCell>
              <TableCell align="right">
                <Button onClick={() => removeSerialNumber(index)}>
                  <DeleteIcon color="error" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
