import {
    required,
    SimpleForm,
    TextInput,
    usePermissions,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaCreateView } from "../components/views/RaCreateView";

export const StorageLocationCreate = (props) => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaCreateView {...props} redirect={"list"}>
            <SimpleForm>
                <TextInput
                    title={translate("equip.name")}
                    label="equip.name"
                    source="name"
                    validate={[required()]}
                />
            </SimpleForm>
        </RaCreateView>
    );
};
