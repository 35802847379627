import {
    FunctionField,
    TextField,
    NumberField,
    usePermissions,
    useTranslate,
    useUpdateMany,
    useRefresh,
    useNotify,
    useListContext,
} from "react-admin";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import { AccessRightsError } from "../components/AccessRightsError";
import { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ConfirmationDialog } from "shared/src/components/ConfirmationDialog";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { Button, Typography } from "@mui/material";
import InboxIcon from "@mui/icons-material/Inbox";
import { RaEditButton } from "../components/buttons/RaEditButton";
import { RaListView } from "../components/views/RaListView";
import { Box } from "@mui/system";

export const ClipboardSkuList = () => {
    const [clearClipboardDialog, setClearClipboardDialog] = useState(false);
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource: "skus", attribute: "read" })) {
        return <AccessRightsError />;
    }
    return (
        <RaListView
            empty={<EmptyListComponent />}
            title="Clipboard"
            resource="skus"
            filter={{ onClipboard: 1 }}
            actions={
                <ToolbarWithHistory
                    export={true}
                    otherButtons={[
                        {
                            permissions: permissions.vote({
                                resource: "skus",
                                attribute: "edit",
                            }),
                            component: (
                                <Button
                                    startIcon={<HighlightOffIcon />}
                                    label={translate("equip.clearClipboard")}
                                    key={"cleanClipboardButton"}
                                    onClick={() =>
                                        setClearClipboardDialog(true)
                                    }
                                    title={translate("equip.clearClipboard")}
                                />
                            ),
                        },
                    ]}
                />
            }
        >
            {clearClipboardDialog && (
                <ClearClipboardDialog
                    setClearClipboardDialog={setClearClipboardDialog}
                />
            )}
            <DatagridWithHistory bulkActionButtons={false}>
                <TextField label="equip.labelNumber" source="labelNumber" />
                <TextField
                    label="equip.manufacturer"
                    source="manufacturer.name"
                />
                <TextField label="equip.supplier" source="supplier.name" />
                <NumberField
                    label="equip.purchasePrice"
                    source="arrival.purchasePrice"
                    options={{ style: "currency", currency: "EUR" }}
                />
                <NumberField
                    label="equip.currentPrice"
                    source="currentPrice"
                    options={{ style: "currency", currency: "EUR" }}
                    sortable={false}
                />
                <NumberField
                    label="equip.weight"
                    source="weight"
                    options={{
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "unit",
                        unit: "kilogram",
                    }}
                />
                <TextField label="equip.serialNumber" source="serialNumber" />
                <FunctionField
                    label="equip.status"
                    render={(record) => {
                        return (
                            translate(`equip.sku_status_${record.status}`) +
                            " / " +
                            translate(
                                `equip.sku_availability_${record.availability}`
                            )
                        );
                    }}
                />
                <RaEditButton title={translate("equip.edit")} />
            </DatagridWithHistory>
        </RaListView>
    );
};

const ClearClipboardDialog = ({ setClearClipboardDialog }) => {
    const { data } = useListContext();
    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();

    const [updateMany] = useUpdateMany(undefined, undefined, {
        onSettled: () => {
            refresh();
        },
        onError: (e) => {
            handleHttpError(e, notify);
        },
    });

    const clearClipboard = () => {
        const ids = data.map((sku) => sku.id);
        updateMany("skus", {
            ids: ids,
            data: { onClipboard: false },
        });
        setClearClipboardDialog(false);
    };

    return (
        <ConfirmationDialog
            onConfirm={clearClipboard}
            onCancel={() => setClearClipboardDialog(false)}
            label={`${translate("equip.confirmClearClipboard")}`}
        />
    );
};

const EmptyListComponent = () => {
    const translate = useTranslate();
    return (
        <Box
            sx={{
                color: "rgba(55,55,55,.55)",
                display: "flex",
                margin: "auto auto auto auto",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <InboxIcon sx={{ fontSize: 216 }} />
            <Typography sx={{ fontSize: 34 }}>
                {translate("equip.emptyClipboard")}
            </Typography>
        </Box>
    );
};
