export const rowStylesCallback = ({ chartWrapper, theme }) => {
    const graphContainer = chartWrapper.visualization.container;
    if (theme === "dark") {
        graphContainer.classList.add("dark-theme-chart");
    }

    var rects = graphContainer.getElementsByTagName("rect");
    Array.prototype.forEach.call(rects, function (rect) {
        if (rect.getAttribute("x") === "0" && rect.getAttribute("stroke") === "none") {
            if (rect.getAttribute("fill") === "#ffffff") {
                theme === "dark"
                    ? rect.setAttribute("fill", "#3d3d3d")
                    : rect.setAttribute("fill", "#fdfdfd");
            } else {
                theme === "dark"
                    ? rect.setAttribute("fill", "#827875")
                    : rect.setAttribute("fill", "#c8c8c8");
            }
        }
    });
};
