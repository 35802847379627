import {
    SimpleShowLayout,
    TextField,
    usePermissions,
    useResourceContext,
    useLocaleState,
    ReferenceField,
    NumberField,
    FunctionField,
    useTranslate,
    BooleanField,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { AccessRightsError } from "../components/AccessRightsError";
import { ReferenceManyFieldWithLinkSupport } from "../components/ReferenceManyFieldWithLinkSupport";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import { RaDateField } from "../components/fields/Ra/RaDateField";
import { RaShowView } from '../components/views/RaShowView';


export const EliminationShow = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const translate = useTranslate();
    const [locale] = useLocaleState();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaShowView {...props} actions={<ToolbarWithHistory back={true} />}>
            <SimpleShowLayout>
                <ReferenceField
                    label="equip.name"
                    source="productId"
                    reference="products"
                    link={false}
                >
                    <TextField source={`name[${locale}]`} />
                </ReferenceField>
                <RaDateField label="equip.date" source="date" />
                <TextField label="equip.user" source="createdBy.email" />
                <TextField label="equip.reason" source="reason" />
                <NumberField label="equip.quantity" source="quantity" />
                <FunctionField
                    label="equip.type"
                    render={(record) =>
                        translate(`equip.eliminationType_${record.type}`)
                    }
                />
                <BooleanField label="equip.individualSkus" source="hasSkus" />
            </SimpleShowLayout>
            <ReferenceManyFieldWithLinkSupport
                filter={{ elimination: 1 }}
                reference="skus"
                target="eliminations"
                addLabel={false}
            >
                <DatagridWithHistory
                    sx={{marginTop: 5}}
                    bulkActionButtons={false}
                >
                    <TextField label="equip.labelNumber" source="labelNumber" />
                    <TextField
                        label="equip.manufacturer"
                        source="manufacturer.name"
                    />
                    <TextField label="equip.supplier" source="supplier.name" />
                    <NumberField
                        label="equip.purchasePrice"
                        source="arrival.purchasePrice"
                        options={{
                            style: "currency",
                            currency: "EUR",
                        }}
                    />
                    <NumberField
                        label="equip.currentPrice"
                        source="currentPrice"
                        options={{
                            style: "currency",
                            currency: "EUR",
                        }}
                        sortable={false}
                    />
                    <NumberField
                        label="equip.weight"
                        source="weight"
                        options={{
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "unit",
                            unit: "kilogram",
                        }}
                    />
                    <FunctionField
                        label="equip.status"
                        render={(record) =>
                            translate(`equip.sku_status_${record.status}`)
                        }
                    />
                    <TextField
                        label="equip.serialNumber"
                        source="serialNumber"
                    />
                </DatagridWithHistory>
            </ReferenceManyFieldWithLinkSupport>
        </RaShowView>
    );
};
