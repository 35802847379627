import {
    TextInput,
    SelectArrayInput,
    usePermissions,
    useResourceContext,
    useTranslate,
    useNotify,
    useRefresh,
    useRedirect,
    useLocaleState,
    ImageInput,
    ImageField,
    useRecordContext,
} from "react-admin";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { SimpleFormWithPermissions } from "../components/SimpleFormWithPermissions";
import { AccessRightsError } from "../components/AccessRightsError";
import { roles } from "shared/src/authProvider";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaEditView } from "../components/views/RaEditView";

export const UserEdit = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    // eslint-disable-next-line
    const [locale, setLocale] = useLocaleState();
    const { isLoading, permissions } = usePermissions();
    const currentUserId = permissions.user.userId;

    const onSuccess = (data) => {
        if (currentUserId === data.id) {
            const language = data.locale.split("_")[0];
            setLocale(language);
            localStorage.setItem("auth", JSON.stringify(data));
        }
        notify(`Changes saved`);
        redirect("/users");
        refresh();
    };

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaEditView
            mutationOptions={{ onSuccess }}
            mutationMode="pessimistic"
            actions={
                <ToolbarWithHistory
                    back={true}
                    show={permissions.vote({
                        resource,
                        attribute: "read",
                    })}
                />
            }
            {...props}
        >
            <SimpleFormWithPermissions>
                <ConditionalTextInput
                    title={translate("equip.firstname")}
                    label="equip.firstname"
                    source="firstname"
                />
                <ConditionalTextInput
                    title={translate("equip.lastname")}
                    label="equip.lastname"
                    source="lastname"
                />
                <ConditionalTextInput
                    title={translate("equip.email")}
                    label="equip.email"
                    source="email"
                    type="email"
                />
                <SelectArrayInput
                    title={translate("equip.roles")}
                    label="equip.roles"
                    source="roles"
                    choices={roles}
                />
                <RaSelectInput
                    title={translate("equip.status")}
                    label="equip.status"
                    source="status"
                    required={true}
                    choices={[
                        {
                            id: "active",
                            name: translate("equip.active"),
                        },
                        {
                            id: "invitation_pending",
                            name: translate("equip.invitation_pending"),
                        },
                        {
                            id: "deactivated",
                            name: translate("equip.deactivated"),
                        },
                    ]}
                />
                <RaSelectInput
                    source="locale"
                    label="equip.language"
                    choices={[
                        {
                            id: "de_DE",
                            name: translate("equip.language_german"),
                        },
                        {
                            id: "en_EN",
                            name: translate("equip.language_english"),
                        },
                    ]}
                />
                <ImageInput
                    title={translate("equip.signature")}
                    label="equip.signature"
                    source="signature"
                    accept="image/*"
                    maxSize={5000000}
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleFormWithPermissions>
        </RaEditView>
    );
};

const ConditionalTextInput = (props) => {
    const record = useRecordContext();
    return <TextInput readOnly={(record.userIdentifier) !== record.email} {...props} />;
};
