import {
    BooleanInput,
    FileField,
    FileInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    TranslatableInputs,
    useCreate,
    usePermissions,
    useResourceContext,
    useNotify,
    useTranslate,
} from "react-admin";
import { Box } from "@mui/material";
import {
    queryToUrlOrigin,
    queryToFilter,
    queryToAttribute,
    queryToRedirectBack,
} from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { ProductImageManagement } from "./components/productImage/ProductImageManagement";
import { convertFileToBase64 } from "shared/src/utils/uploadHandler";
import { RaAutocompleteInput } from "../components/inputs/Ra/RaAutocompleteInput";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaCreateView } from '../components/views/RaCreateView';

export const ProductCreate = (props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const [create] = useCreate(undefined, undefined, {
        onError: (e) => {
            handleHttpError(e, notify);
        },
    });
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    const originUrl = queryToUrlOrigin(window.location.href);
    const originUrlFilter = queryToFilter(originUrl ? originUrl : "");
    const categoryId = originUrlFilter?.category
        ? originUrlFilter.category
        : null;
    const categoryRecord = categoryId
        ? { category: { id: +categoryId } }
        : null;

    const redirect = originUrl ? originUrl.split("#")[1] : false;

    const submit = (data) => {
        create("products", { data: data }, { returnPromise: true }).then(
            (response) => {
                if (data.files) {
                    convertFileToBase64(data.files).then((base64File) => {
                        fetch(`/products/${response.id}/image`, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                mimetype: data.files.rawFile.type,
                                files: [base64File.split(",")[1]],
                            }),
                        })
                            .then(() => {
                                queryToRedirectBack();
                            })
                            .catch((e) => {
                                handleHttpError(e, notify);
                            });
                    });
                } else {
                    queryToRedirectBack();
                }
            }
        );
    };

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }

    return (
        <RaCreateView {...props} record={categoryRecord} redirect={redirect}>
            <SimpleForm onSubmit={submit}>
                <Box display="flex">
                    <Box flex={1} mr="1em">
                        <p>{translate("equip.productImage")}</p>
                        <ProductImageManagement />
                        <FileInput
                            source="files"
                            title={translate("equip.allowedImageTypes")}
                            label="equip.allowedImageTypes"
                            accept="image/jpeg, image/png, image/gif"
                            multiple={false}
                            maxSize={209715200}
                            required
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                        <BooleanInput
                            title={translate("equip.isMassProduct")}
                            label="equip.isMassProduct"
                            source="isMassProduct"
                        />
                        <ReferenceInput
                            source="manufacturer.id"
                            reference="manufacturers"
                            sort={{ field: "name", order: "ASC" }}
                        >
                            <RaAutocompleteInput
                                label="equip.manufacturer"
                                validate={[required()]}
                                fullWidth
                                field="name"
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            source="supplier.id"
                            reference="suppliers"
                            sort={{ field: "name", order: "ASC" }}
                        >
                            <RaAutocompleteInput
                                label="equip.supplier"
                                validate={[required()]}
                                fullWidth
                                field="name"
                            />
                        </ReferenceInput>
                    </Box>

                    <Box flex={1} ml="1em">
                        <TextInput
                            title={translate("equip.originCountry")}
                            label="equip.originCountry"
                            source="originCountry"
                            validate={[required()]}
                            fullWidth
                        />
                        <NumberInput
                            title={translate("equip.carnetPrice")}
                            label="equip.carnetPrice"
                            source="carnetPrice"
                            sort={{ field: "name", order: "ASC" }}
                            fullWidth
                        />
                        <RaSelectInput
                            source="writeOffYears"
                            required
                            fullWidth
                            choices={[
                                { id: 5, name: 5 },
                                { id: 10, name: 10 },
                            ]}
                        />
                        <NumberInput
                            title={translate("equip.weight")}
                            label="equip.weight"
                            source="weight"
                        />
                        <ReferenceArrayInput
                            source="tags"
                            reference="tags"
                            label="equip.tags"
                            fullWidth
                        >
                            <RaAutocompleteInput
                                label={translate("equip.tags")}
                                field="name"
                                multiple={true}
                                onCreate={(value) => {
                                    return create(
                                        "tags",
                                        { data: { name: value } },
                                        { returnPromise: true }
                                    ).then((response) => {
                                        return response;
                                    });
                                }}
                            />
                        </ReferenceArrayInput>
                        <ReferenceInput
                            source="category.id"
                            reference="categories"
                            sort={{ field: "name", order: "ASC" }}
                        >
                            <RaAutocompleteInput
                                label="equip.category"
                                validate={[required()]}
                                fullWidth
                                field="name"
                            />
                        </ReferenceInput>

                        <TranslatableInputs locales={["de", "en"]}>
                            <TextInput
                                title={translate("equip.name")}
                                label="equip.name"
                                source="name"
                                validate={[required()]}
                                fullWidth
                            />
                        </TranslatableInputs>

                        <TranslatableInputs locales={["de", "en"]}>
                            <TextInput
                                title={translate("equip.description")}
                                label="equip.description"
                                source="description"
                                multiline
                                rows={3}
                                fullWidth
                            />
                        </TranslatableInputs>
                    </Box>
                </Box>
            </SimpleForm>
        </RaCreateView>
    );
};
