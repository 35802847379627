import {
    BooleanInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    TextInput,
    useCreate,
    TranslatableInputs,
    usePermissions,
    useResourceContext,
    FileInput,
    FileField,
    useNotify,
    useUpdate,
    useTranslate,
    useRecordContext,
} from "react-admin";
import { Box } from "@mui/material";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { SimpleFormWithPermissions } from "../components/SimpleFormWithPermissions";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { ProductImageManagement } from "./components/productImage/ProductImageManagement";
import { useNavigate, useLocation } from "react-router";
import { convertFileToBase64 } from "shared/src/utils/uploadHandler";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaAutocompleteInput } from "../components/inputs/Ra/RaAutocompleteInput";
import { RaAutocompleteArrayInput } from "../components/inputs/Ra/RaAutocompleteArrayInput";
import { RaEditView } from "../components/views/RaEditView";

export const ProductEdit = (props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const [create] = useCreate();
    const [update] = useUpdate(undefined, undefined, {
        onError: (e) => {
            handleHttpError(e, notify);
        },
        onSuccess: () => {
            navigate({
                pathname: `${location.pathname}/show`,
                search: `${location.search}`,
            });
        },
    });
    const navigate = useNavigate();
    const location = useLocation();
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    const submit = async (data) => {
        const files = data.files;
        delete data.files;
        //convert to base64
        if (files) {
            await convertFileToBase64(files).then(async (base64File) => {
                await fetch(`/products/${data.id}/image`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        mimetype: files.rawFile.type,
                        files: [base64File.split(",")[1]],
                    }),
                }).catch((e) => {
                    handleHttpError(e, notify);
                });
            });
        }
        update("products", {
            id: data.id,
            data: {
                ...data,
            },
        });
    };

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }

    return (
        <RaEditView
            {...props}
            actions={<ToolbarWithHistory back={true} show={true} />}
            mutationMode="pessimistic"
            redirect={false}
        >
            <SimpleFormWithPermissions onSubmit={submit}>
                <Box display="flex">
                    <Box flex={1} mr="1em">
                        <ProductImageManagement />
                        <FileInput
                            source="files"
                            title={translate("equip.allowedImageTypes")}
                            label="equip.allowedImageTypes"
                            accept={{
                                "image/jpeg": [".jpeg"],
                                "image/png": [".png"],
                                "image/gif": [".gif"],
                            }}
                            multiple={false}
                            maxSize={209715200}
                            required
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                        <BooleanInput
                            title={translate("equip.isMassProduct")}
                            label="equip.isMassProduct"
                            source="isMassProduct"
                        />
                        <LabelNumberInput />
                        <NumberInput
                            title={translate("equip.edispo.id")}
                            label="equip.edispo.id"
                            source="edispoId"
                            fullWidth
                        />
                        <RaSelectInput
                            label="equip.edispo.type"
                            source="edispoType"
                            fullWidth
                            choices={[
                                {
                                    id: "in_1a",
                                    name: "equip.edispo.in_1a",
                                },
                                {
                                    id: "in_1b",
                                    name: "equip.edispo.in_1b",
                                },
                            ]}
                        />
                        <ReferenceInput
                            source="manufacturer.id"
                            reference="manufacturers"
                            sort={{ field: "name", order: "ASC" }}
                        >
                            <RaAutocompleteInput
                                label="equip.manufacturer"
                                validate={[required()]}
                                fullWidth
                                field="name"
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            source="supplier.id"
                            reference="suppliers"
                            sort={{ field: "name", order: "ASC" }}
                        >
                            <RaAutocompleteInput
                                label="equip.supplier"
                                validate={[required()]}
                                fullWidth
                                field="name"
                            />
                        </ReferenceInput>
                    </Box>

                    <Box flex={1} ml="1em">
                        <TextInput
                            title={translate("equip.originCountry")}
                            label="equip.originCountry"
                            source="originCountry"
                            validate={[required()]}
                            fullWidth
                        />
                        <NumberInput
                            title={translate("equip.carnetPrice")}
                            label="equip.carnetPrice"
                            source="carnetPrice"
                            sort={{ field: "name", order: "ASC" }}
                            fullWidth
                        />
                        <RaSelectInput
                            source="writeOffYears"
                            required
                            fullWidth
                            choices={[
                                { id: 5, name: 5 },
                                { id: 10, name: 10 },
                            ]}
                        />
                        <NumberInput
                            title={translate("equip.weight")}
                            label="equip.weight"
                            source="weight"
                        />
                        <ReferenceArrayInput
                            source="tags"
                            reference="tags"
                            label="equip.tags"
                            fullWidth
                        >
                            <RaAutocompleteArrayInput
                                label={translate("equip.tags")}
                                field="name"
                                multiple={true}
                                onCreate={(value) => {
                                    return create(
                                        "tags",
                                        { data: { name: value } },
                                        { returnPromise: true }
                                    ).then((response) => {
                                        return response;
                                    });
                                }}
                            />
                        </ReferenceArrayInput>
                        <ReferenceInput
                            source="category.id"
                            reference="categories"
                            sort={{ field: "name", order: "ASC" }}
                        >
                            <RaAutocompleteInput
                                label="equip.category"
                                validate={[required()]}
                                fullWidth
                                field="name"
                            />
                        </ReferenceInput>
                        <TranslatableInputs locales={["de", "en"]}>
                            <TextInput
                                title={translate("equip.name")}
                                label="equip.name"
                                source="name"
                                validate={[required()]}
                                fullWidth
                            />
                        </TranslatableInputs>
                        <TranslatableInputs locales={["de", "en"]}>
                            <TextInput
                                title={translate("equip.description")}
                                label="equip.description"
                                source="description"
                                fullWidth
                                multiline
                                rows={3}
                            />
                        </TranslatableInputs>
                    </Box>
                </Box>
            </SimpleFormWithPermissions>
        </RaEditView>
    );
};

const LabelNumberInput = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    return record.isMassProduct ? (
        <TextInput
            title={translate("equip.labelNumber")}
            label="equip.labelNumber"
            source="labelNumber"
            validate={[required()]}
            fullWidth
        />
    ) : null;
};
