import productConverter from "./productConverter";
import dayjs from "dayjs";

const rentalItemConverter = {
    convertIn: (responseData) => {
        if (responseData.product) {
            responseData.product = productConverter.convertIn(
                responseData.product
            );
        }

        if (responseData._embedded?.rental) {
            responseData.rental = responseData._embedded.rental.data;
        }

        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }

        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        if (responseData.plannedReturnDate) {
            responseData.plannedReturnDate = dayjs(
                responseData.plannedReturnDate
            );
        }

        if (responseData.returnedDate) {
            responseData.returnedDate = dayjs(
                responseData.returnedDate
            );
        }

        if (responseData.earliestPlannedReturnDate) {
            responseData.earliestPlannedReturnDate = dayjs(
                responseData.earliestPlannedReturnDate
            );
        }

        if (responseData.rentalProcess?.signatureDate) {
            responseData.rentalProcess.signatureDate = dayjs(
                responseData.rentalProcess.signatureDate
            );
        }

        return responseData;
    },
    convertOut: (object) => {
        return object;
    },
};

export default rentalItemConverter;
