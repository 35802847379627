export default class Config {
    constructor() {
        const request = new Request("/config", {
            method: "GET",
            headers: new Headers({ "Accept": "application/json" }),
        });
        this.config = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                this.config = json;
            })
            .catch(() => {
                throw new Error("Network error");
            });
    }

    get(key) {
        if (this.config instanceof Promise) {
            return null;
        }

        return this.config[key] || null;
    }
}
