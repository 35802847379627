import { useState } from "react";
import {
    useRecordContext,
    useDataProvider,
    ListContextProvider,
    useGetManyReference,
    useNotify,
    useGetList,
} from "react-admin";
import { ChangeLogsDatagrid } from "../../../changeLogs/ChangeLogsList";
import { handleHttpError } from "shared/src/utils/errorHandler";

export const ChangeLogs = () => {
    const productRecord = useRecordContext();
    const dataProvider = useDataProvider();
    const [logs, setLogs] = useState([]);
    const notify = useNotify();

    useGetList(
        "changeLogs",
        {
            filter: {
                referenceType: `App\\Entity\\Product`,
                referenceId: productRecord.id,
            },
            pagination: { page: 1, perPage: 999 },
            sort: { field: "id", order: "DESC" },
        },
        {
            onSettled: (response) => {
                setLogs((prev) =>
                    [...response.data].sort((a, b) => a.id - b.id)
                );
            },
            onError: (e) => {
                handleHttpError(e, notify);
            },
        }
    );

    useGetManyReference(
        "skus",
        {
            target: "product",
            id: productRecord.id,
            filter: {
                [`@@record`]: productRecord,
            },
            pagination: {
                page: 1,
                perPage: 999,
            },
            sort: { field: "id", order: "DESC" },
        },
        {
            onSuccess: (response) => {
                response.data.forEach((el) => {
                    getSkuLogs(el);
                });
            },
            onError: (e) => {
                handleHttpError(e, notify);
            },
        }
    );

    const getSkuLogs = (skuRecord) => {
        dataProvider
            .getList("changeLogs", {
                filter: {
                    referenceType: `App\\Entity\\Sku`,
                    referenceId: skuRecord.id,
                },
                pagination: {
                    page: 1,
                    perPage: 999,
                },
                sort: { field: "id", order: "DESC" },
            })
            .then((response) => {
                setLogs((prev) =>
                    [...prev, ...response.data].sort((a, b) => a.id - b.id)
                );
            })
            .catch((e) => {
                handleHttpError(e, notify);
            });
    };

    const sort = { field: "createdAt", order: "DESC" };
    if (!logs) {
        return <></>;
    }
    return (
        <ListContextProvider
            value={{
                data: logs,
                total: logs.length,
                page: 1,
                perPage: logs.length,
                sort,
            }}
        >
            <ChangeLogsDatagrid />
        </ListContextProvider>
    );
};
