import {
    ReferenceInput,
    TextInput,
    Form,
    Toolbar,
    DateInput,
    useCreate,
    useUpdate,
    DeleteButton,
    useTranslate,
    usePermissions,
    useNotify,
    NumberInput,
} from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { RaAutocompleteInput } from "../../../../components/inputs/Ra/RaAutocompleteInput";

export const CreateSkuArrivalForm = (props) => {
    const [create] = useCreate();
    const [update] = useUpdate();
    const translate = useTranslate();
    const notify = useNotify();
    const { isLoading, permissions } = usePermissions();

    const submit = (data) => {
        if (props.arrivalData) {
            update(
                "arrivals",
                {
                    id: props.arrivalData.id,
                    data: {
                        purchaseOrderNumber: data.purchaseOrderNumber,
                        purchasePrice: data.purchasePrice,
                        orderDate: data.orderDate,
                        deliveryDate: data.deliveryDate,
                        comment: data.comment,
                    },
                    previousData: props.arrivalData,
                },
                {
                    onSettled: (response) => {
                        props.setArrivalData(response);
                        props.setDeliveryWindow("editSku");
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
        } else {
            create(
                "arrivals",
                {
                    data: {
                        product: data.id,
                        supplier: data.supplierId,
                        purchaseOrderNumber: data.purchaseOrderNumber,
                        purchasePrice: data.purchasePrice,
                        comment: data.comment,
                        orderDate: data.orderDate,
                        deliveryDate: data.deliveryDate,
                        quantity: 0,
                        defectQuantity: 0,
                    },
                },
                {
                    onSettled: (response) => {
                        props.setArrivalData(response);
                        props.setDeliveryWindow("editSku");
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
        }
    };

    const validateDate = (values) => {
        const errors = {};
        if (values.deliveryDate < values.orderDate) {
            errors.deliveryDate = translate("equip.validateDeliveryDate");
            errors.orderDate = translate("equip.validateOrderDate");
        }
        if (!values.supplierId) {
            errors.supplierId = translate("equip.error_empty_supplierId");
        }
        return errors;
    };

    if (isLoading) {
        return <></>;
    }
    return (
        <Form onSubmit={submit} record={props.arrivalData} validate={validateDate}>
            <Box px={3} pt={2}>
                <Box marginBottom={3}>
                    <Typography variant="p">
                        {translate(props.arrivalData ? "equip.editArrival" : "equip.newDelivery")}
                    </Typography>
                </Box>

                <Box display="flex">
                    <Box flex={1} mr="1em">
                        <TextInput
                            label="equip.purchaseOrderNumber"
                            title={translate("equip.purchaseOrderNumber")}
                            source="purchaseOrderNumber"
                            required
                            fullWidth
                        />
                        <NumberInput
                            label="equip.purchasePricePerItem"
                            title={translate("equip.purchasePricePerItem")}
                            source="purchasePrice"
                            fullWidth
                            defaultValue={props.arrivalData?.purchasePrice}
                            required
                        />
                        <DateInput
                            label="equip.orderDate"
                            title={translate("equip.orderDate")}
                            source="orderDate"
                            required
                        />
                        <DateInput
                            label="equip.deliveryDate"
                            title={translate("equip.deliveryDate")}
                            source="deliveryDate"
                            required
                        />
                    </Box>
                    <Box flex={1} ml="1em">
                        {!props.arrivalData && (
                            <ReferenceInput
                                record={props.arrivalData}
                                resource="arrivals"
                                source="supplierId"
                                reference="suppliers"
                                sort={{
                                    field: "name",
                                    order: "ASC",
                                }}
                            >
                                <RaAutocompleteInput
                                    label="equip.supplier"
                                    fullWidth
                                    isRequired={true}
                                    field="name"
                                />
                            </ReferenceInput>
                        )}
                        <TextInput
                            label="equip.comment"
                            title={translate("equip.comment")}
                            source="comment"
                            fullWidth
                            rows={3}
                            multiline
                        />
                    </Box>
                </Box>
            </Box>
            <Toolbar
                sx={{ background: "inherit", display: "flex", justifyContent: "space-between" }}
            >
                <Box>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        title={translate("equip.saveAndContinue")}
                        aria-label={translate("equip.saveAndContinue")}
                    >
                        {translate("equip.saveAndContinue")}
                    </Button>
                    {props.arrivalData &&
                        permissions.vote({
                            resource: "arrivals",
                            attribute: "delete",
                            subject: props.arrivalData,
                        }) && (
                            <DeleteButton
                                title={translate("equip.delete")}
                                aria-label={translate("equip.delete")}
                                resource="arrivals"
                                record={props.arrivalData}
                                redirect={false}
                                sx={{ marginLeft: 3 }}
                                onClick={() => {
                                    props.setDeliveryWindow();
                                    props.setArrivalData();
                                }}
                            />
                        )}
                </Box>
                <Box>
                    <Button
                        onClick={() => {
                            props.setDeliveryWindow();
                            props.setArrivalData();
                        }}
                        title={translate("equip.close")}
                        aria-label={translate("equip.close")}
                    >
                        {translate("equip.close")}
                    </Button>
                </Box>
            </Toolbar>
        </Form>
    );
};
