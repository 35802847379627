import { TopToolbar, FilterButton, useRecordContext, usePermissions } from "react-admin";
import { Breadcrumbs } from "./Breadcrumbs";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { BackButton } from "./buttons/BackButton";
import { RaEditButton } from "./buttons/RaEditButton";
import { RaCreateButton } from "./buttons/RaCreateButton";
import { RaExportButton } from "./buttons/RaExportButton";
import { RaShowButton } from "./buttons/RaShowButton";
import { RaDeleteButton } from "./buttons/RaDeleteButton";
import { DownloadButton } from "./buttons/DownloadButton";
import { EmailButton } from "./buttons/EmailButton";
import { PrintButton } from "./buttons/PrintButton";

export const ToolbarWithHistory = ({ breadcrumbs = true, ...props }) => {
    const record = useRecordContext();
    const { isLoading } = usePermissions();

    useEffect(() => {}, [record]);

    if (isLoading) {
        return <></>;
    }

    return (
        <Box>
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: -25,
                }}
            >
                {breadcrumbs && <Breadcrumbs record={record} />}
            </Box>
            <TopToolbar>
                {props.filter && <FilterButton />}
                {props.create && <RaCreateButton />}
                {props.export && <RaExportButton />}
                {props.edit && <RaEditButton />}
                {props.show && <RaShowButton />}
                {props.delete && <RaDeleteButton />}
                {props.back && <BackButton />}
                {props.download && (
                    <DownloadButton
                        downloadLink={props.downloadLink}
                        downloadLabel={props.downloadLabel}
                    />
                )}
                {props.email && (
                    <EmailButton emailLabel={props.emailLabel} emailLink={props.emailLink} />
                )}
                {props.print && <PrintButton setPrintLabels={props.setPrintLabels} />}
                {props.otherButtons
                    ? props.otherButtons.map((otherButton) => {
                          return otherButton.permissions && otherButton.component;
                      })
                    : null}
            </TopToolbar>
        </Box>
    );
};
