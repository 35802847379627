import { Box, Button, FormControl, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Select from "@mui/material/Select";
import { useTranslate } from "react-admin";

export const TimeframePagination = ({ startDate, updateUrl, period }) => {
    const translate = useTranslate();

    const handlePeriodChange = (event) => {
        const newPeriod = event.target.value;
        const newEndDate = startDate.add(newPeriod, "day");
        updateUrl(startDate, newEndDate);
    };

    const setPeriodBack = () => {
        const newStartDate = startDate.subtract(period, "day");
        updateUrl(newStartDate, startDate);
    };

    const setPeriodForward = () => {
        const newStartDate = startDate.add(period, "day");
        const newEndDate = startDate.add(period * 2, "day");
        updateUrl(newStartDate, newEndDate);
    };

    return (
        <Box marginTop={6} display="flex" justifyContent="left">
            <Box marginRight={1}>
                <Button
                    sx={{ marginTop: 1 }}
                    size="large"
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    onClick={setPeriodBack}
                    title={translate("equip.back")}
                    aria-label={translate("equip.back")}
                />
            </Box>
            <Box sx={{ width: 130 }}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>{translate("equip.period")}</InputLabel>
                    <Select
                        value={period}
                        title={translate("equip.period")}
                        label={translate("equip.period")}
                        onChange={handlePeriodChange}
                        fullWidth
                    >
                        <MenuItem
                            value={7}
                            title={translate("equip.week")}
                            aria-label={translate("equip.week")}
                        >
                            {translate("equip.week")}
                        </MenuItem>
                        <MenuItem
                            value={14}
                            title={translate("equip.twoWeek")}
                            aria-label={translate("equip.twoWeek")}
                        >
                            {translate("equip.twoWeek")}
                        </MenuItem>
                        <MenuItem
                            value={30}
                            title={translate("equip.month")}
                            aria-label={translate("equip.month")}
                        >
                            {translate("equip.month")}
                        </MenuItem>
                        {![7, 14, 30].includes(period) && (
                            <MenuItem
                                value={period}
                                title={translate("equip.custom")}
                                aria-label={translate("equip.custom")}
                            >
                                {translate("equip.custom")}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
            <Box marginLeft={1}>
                <Button
                    sx={{ marginTop: 1 }}
                    size="large"
                    variant="contained"
                    startIcon={<ArrowForwardIcon />}
                    onClick={setPeriodForward}
                    title={translate("equip.forward")}
                    aria-label={translate("equip.forward")}
                />
            </Box>
        </Box>
    );
};
