import {
    Form,
    Toolbar,
    TextField,
    NumberField,
    ReferenceField,
    SimpleShowLayout,
    useTranslate,
    usePermissions,
} from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { RaDateField } from "../../../../components/fields/Ra/RaDateField";

export const ViewMassArrival = (props) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
        <Form>
            <Box p={2}>
                <Box display="flex" justifyContent={"space-between"} alignItems="center">
                    <Typography variant="p" sx={{ marginLeft: 2 }}>
                        {translate("equip.viewArrival")}
                    </Typography>
                    <Button
                        onClick={() => props.setPrintLabels(true)}
                        size="small"
                        variant="text"
                        startIcon={<PrintIcon />}
                        title={translate("equip.printLabels")}
                        aria-label={translate("equip.printLabels")}
                        sx={{ marginRight: 1 }}
                    >
                        {translate("equip.printLabels")}
                    </Button>
                </Box>
                <Box>
                    <SimpleShowLayout record={props.arrivalData}>
                        <TextField label="equip.purchaseOrderNumber" source="purchaseOrderNumber" />

                        <RaDateField label="equip.orderDate" source="orderDate" />
                        <RaDateField label="equip.deliveryDate" source="deliveryDate" />

                        <NumberField
                            label="equip.purchasePricePerItem"
                            source="purchasePrice"
                            options={{ style: "currency", currency: "EUR" }}
                        />

                        <NumberField
                            label="equip.currentPrice"
                            source="currentPrice"
                            options={{ style: "currency", currency: "EUR" }}
                            sortable={false}
                        />
                        <NumberField label="equip.deliveredOk" source="quantity" />
                        <NumberField label="equip.deliveredDefect" source="defectQuantity" />

                        <ReferenceField
                            label="equip.supplier"
                            source="supplierId"
                            reference="suppliers"
                            link={false}
                        >
                            <TextField label="equip.name" source="name" />
                        </ReferenceField>
                        <TextField label="equip.comment" source="comment" />
                    </SimpleShowLayout>
                </Box>
            </Box>
            <Toolbar
                sx={{ display: "flex", justifyContent: "space-between", background: "inherit" }}
            >
                {permissions.vote({
                    resource: "arrivals",
                    attribute: "edit",
                    subject: props.arrivalData,
                }) && (
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={() => props.setDeliveryWindow("edit")}
                        title={translate("ra.action.edit")}
                        aria-label={translate("ra.action.edit")}
                        sx={{ marginLeft: 1 }}
                    >
                        {translate("ra.action.edit")}
                    </Button>
                )}
                <Button
                    onClick={() => props.setDeliveryWindow(null, props.setArrivalData())}
                    title={translate("equip.close")}
                    aria-label={translate("equip.close")}
                >
                    {translate("equip.close")}
                </Button>
            </Toolbar>
        </Form>
    );
};
