import {
    Filter,
    FunctionField,
    TextField,
    TextInput,
    usePermissions,
    useResourceContext,
    useTranslate,
    NumberField,
    ReferenceField,
    useLocaleState,
    BooleanField,
} from "react-admin";
import { DefaultPagination } from "../components/DefaultPagination";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaDateField } from "../components/fields/Ra/RaDateField";
import { RaListView } from "../components/views/RaListView";

const EliminationListFilter = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                title={translate("equip.reason")}
                label="equip.reason"
                source="reason"
                alwaysOn
            />
            <RaSelectInput
                source="type"
                choices={[
                    { id: "sale", name: "equip.eliminationType_sale" },
                    {
                        id: "scrapping",
                        name: "equip.eliminationType_scrapping",
                    },
                    { id: "loss", name: "equip.eliminationType_loss" },
                    {
                        id: "conversionOfFixedAssets",
                        name: "equip.eliminationType_conversionOfFixedAssets",
                    },
                ]}
                alwaysOn
            />
        </Filter>
    );
};

export const EliminationList = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const [locale] = useLocaleState();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaListView
            {...props}
            actions={<ToolbarWithHistory create={true} export={true} />}
            filters={<EliminationListFilter />}
            pagination={<DefaultPagination />}
            perPage={100}
            sort={{ field: "id", order: "DESC" }}
        >
            <DatagridWithHistory bulkActionButtons={false}>
                <ReferenceField
                    label="equip.name"
                    source="productId"
                    reference="products"
                    link={false}
                >
                    <TextField source={`name[${locale}]`} />
                </ReferenceField>
                <RaDateField label="equip.date" source="date" />
                <TextField label="equip.user" source="createdBy.email" />
                <TextField label="equip.reason" source="reason" />
                <NumberField label="equip.quantity" source="quantity" />
                <BooleanField label="equip.individualSkus" source="hasSkus" />
                <FunctionField
                    label="equip.type"
                    render={(record) => translate(`equip.eliminationType_${record.type}`)}
                />
            </DatagridWithHistory>
        </RaListView>
    );
};
