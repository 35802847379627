import {
    SimpleShowLayout,
    TextField,
    usePermissions,
    useResourceContext,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaShowView } from '../components/views/RaShowView';

export const ManufacturerShow = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaShowView
            actions={<ToolbarWithHistory back={true} edit={true} />}
            {...props}
        >
            <SimpleShowLayout>
                <TextField label="equip.name" source="name" />
            </SimpleShowLayout>
        </RaShowView>
    );
};
