import { SimpleShowLayout, FunctionField, useTranslate, usePermissions } from "react-admin";
import { Button, Table, TableRow, TableCell, TableBody } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PrintIcon from "@mui/icons-material/Print";
import { Box } from "@mui/system";

export const ProductTabContentInventory = ({ setDeliveryWindow, arrivalData, setPrintLabels }) => {
    const translate = useTranslate();
    const record = arrivalData;
    const resource = "arrivals";
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }
    return (
        <Box sx={{ maxWidth: 315 }}>
            {permissions.vote({
                resource,
                attribute: "create",
                subject: record,
            }) && (
                <Box
                    sx={{
                        position: "relative",
                        marginTop: 2,
                        marginBottom: 1,
                        marginLeft: 1,
                        marginRight: 1.5,
                        justifyContent: "space-between",
                        display: "flex",
                    }}
                >
                    <Button
                        onClick={() => setDeliveryWindow("edit")}
                        size="small"
                        variant="text"
                        startIcon={<LocalShippingIcon />}
                        title={translate("equip.newDelivery")}
                        aria-label={translate("equip.newDelivery")}
                    >
                        {translate("equip.newDelivery")}
                    </Button>
                    <Button
                        onClick={() => setPrintLabels(true)}
                        size="small"
                        variant="text"
                        startIcon={<PrintIcon />}
                        title={translate("equip.printLabels")}
                        aria-label={translate("equip.printLabels")}
                    >
                        {translate("equip.printLabels")}
                    </Button>
                </Box>
            )}

            <SimpleShowLayout sx={{ paddingLeft: 2 }}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell>{translate("equip.inventory")}</TableCell>
                            <TableCell align="right">
                                <FunctionField
                                    label="equip.inventory"
                                    render={(record) => `${record.quantity}`}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {translate("equip.rentedOut")}
                            </TableCell>
                            <TableCell align="right">
                                <FunctionField
                                    label="equip.rentedOut"
                                    render={(record) =>
                                        `${
                                            record.quantity -
                                            record.actualQuantity -
                                            record.lostQuantity
                                        }`
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {translate("equip.lost")}
                            </TableCell>
                            <TableCell align="right">
                                <FunctionField
                                    label="equip.lost"
                                    render={(record) => `${record.lostQuantity}`}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                border: 0,
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {translate("equip.actualQuantity")}
                            </TableCell>
                            <TableCell align="right">
                                <FunctionField
                                    label="equip.actualQuantity"
                                    render={(record) => `${record.actualQuantity}`}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </SimpleShowLayout>
        </Box>
    );
};
