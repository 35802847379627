import { AutocompleteInput, useTranslate } from "react-admin";
import { Box } from "@mui/material";

export const RaAutocompleteInput = ({
    label,
    field,
    optionText,
    ...props
}) => {
    const translate = useTranslate();

    const inputText = (choice) => {
        return choice[field];
    };

    const OptionText = (option) => {
        return (
            <Box sx={{ width: "100%" }} title={option[field]}>
                {optionText ? optionText(option) : option[field]}
            </Box>
        );
    };

    const filterToQuery = (searchText) => ({
        [field]: searchText,
    });

    return (
        <AutocompleteInput
            label={label}
            title={translate(label)}
            optionText={OptionText}
            inputText={inputText}
            filterToQuery={filterToQuery}
            {...props}
        />
    );
};
