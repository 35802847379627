import {
    NumberInput,
    ReferenceInput,
    required,
    usePermissions,
    useResourceContext,
    TextInput,
    SimpleForm,
    Toolbar,
    SaveButton,
    DeleteButton,
    useTranslate,
    useNotify,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaAutocompleteInput } from "../components/inputs/Ra/RaAutocompleteInput";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaEditView } from "../components/views/RaEditView";
import { handleHttpError } from "shared/src/utils/errorHandler";

export const SkuEdit = (props) => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaEditView
            {...props}
            mutationMode="pessimistic"
            actions={<ToolbarWithHistory back={true} show={true} />}
            redirect="show"
        >
            <SimpleForm {...props} toolbar={<ToolbarWithPermissions {...props} />}>
                <ReferenceInput
                    source="manufacturer.id"
                    reference="manufacturers"
                    sort={{ field: "name", order: "ASC" }}
                >
                    <RaAutocompleteInput
                        field="name"
                        label="equip.manufacturer"
                        validate={[required()]}
                    />
                </ReferenceInput>
                <ReferenceInput
                    source="supplier.id"
                    reference="suppliers"
                    sort={{ field: "name", order: "ASC" }}
                >
                    <RaAutocompleteInput
                        label="equip.supplier"
                        field="name"
                        validate={[required()]}
                    />
                </ReferenceInput>
                <ReferenceInput
                    source="storageLocation.id"
                    reference="storageLocations"
                    sort={{ field: "name", order: "ASC" }}
                >
                    <RaAutocompleteInput
                        label="equip.storageLocation"
                        field="name"
                        filterToQuery={(searchText) => ({
                            name: searchText,
                        })}
                    />
                </ReferenceInput>
                <NumberInput
                    title={translate("equip.weight")}
                    label="equip.weight"
                    source="weight"
                />
                <TextInput
                    title={translate("equip.serialNumber")}
                    label="equip.serialNumber"
                    source="serialNumber"
                />
                <RaSelectInput
                    source="status"
                    required
                    choices={[
                        {
                            id: "active",
                            name: "equip.sku_status_active",
                        },
                        {
                            id: "deactivated",
                            name: "equip.sku_status_deactivated",
                        },
                    ]}
                />
                <RaSelectInput
                    source="availability"
                    required
                    choices={[
                        {
                            id: "available",
                            name: "equip.sku_availability_available",
                        },
                        {
                            id: "rented_out",
                            name: "equip.sku_availability_rented_out",
                        },
                        {
                            id: "defect",
                            name: "equip.sku_availability_defect",
                        },
                        {
                            id: "lost",
                            name: "equip.sku_availability_lost",
                        },
                    ]}
                />
            </SimpleForm>
        </RaEditView>
    );
};

const ToolbarWithPermissions = (props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const resource = useResourceContext();
    const attribute = "delete";
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }

    return (
        <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
            <SaveButton title={translate("equip.save")} />
            {permissions.vote({ resource, attribute }) && (
                <DeleteButton
                    title={translate("equip.delete")}
                    mutationMode="pessimistic"
                    mutationOptions={{ onError: (error) => handleHttpError(error, notify) }}
                    redirect="/skus"
                />
            )}
        </Toolbar>
    );
};
