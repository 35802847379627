import {
    Button,
    fetchUtils,
    useLocaleState,
    useNotify,
    usePermissions,
    useRecordContext,
    useResourceContext,
} from "react-admin";
import { RaButtonWrapper } from "./RaButtonWrapper";
import { handleHttpError } from "shared/src/utils/errorHandler";
import EmailIcon from "@mui/icons-material/Email";

export const EmailButton = ({ emailLabel, emailLink }) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const { isLoading, permissions } = usePermissions();
    const [locale] = useLocaleState();
    const notify = useNotify();

    if (
        !record?.id ||
        isLoading ||
        !permissions.vote({
            resource,
            attribute: "read",
            subject: record,
        })
    ) {
        return <></>;
    }

    return (
        <RaButtonWrapper title={emailLabel}>
            <Button
                href={`${record._links[emailLink]}?_locale=${locale}`}
                onClick={async (event) => {
                    event.preventDefault();
                    const httpClient = fetchUtils.fetchJson;
                    await httpClient(event.target.href, {
                        method: "POST",
                    })
                        .then(() => {
                            notify("equip.emailSent", {
                                type: "success",
                            });
                        })
                        .catch((e) => handleHttpError(e, notify));
                }}
                label={emailLabel}
                color={"primary"}
            >
                <EmailIcon />
            </Button>
        </RaButtonWrapper>
    );
};
