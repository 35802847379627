import NoteAltIcon from "@mui/icons-material/NoteAlt";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import {
    Datagrid,
    ListContextProvider,
    useGetList,
    useNotify,
    useRecordContext,
    useTranslate,
    TextField,
    FunctionField,
    usePermissions,
    useDelete,
    useCreate,
    useUpdate,
    useRefresh,
} from "react-admin";
import { useState } from "react";
import { AddCommentDialog } from "./AddCommentDialog";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { ConfirmationDialog } from "shared/src/components/ConfirmationDialog";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { RaDateField } from "../../../components/fields/Ra/RaDateField";
import { Box } from "@mui/system";

export const Notes = () => {
    const sku = useRecordContext();
    const notify = useNotify();
    const translate = useTranslate();
    const { isLoading: permissionIsLoading, permissions } = usePermissions();
    const [dialog, setDialog] = useState(false);
    const [vdeDialog, setVdeDialog] = useState(false);
    const [recordToModify, setRecordToModify] = useState();
    const refresh = useRefresh();
    const [create] = useCreate();
    const [update] = useUpdate();
    const [deleteOne] = useDelete("skuNotes", {
        id: recordToModify?.id,
        previousData: recordToModify,
    });

    const submit = (event, vdeCheck) => {
        const comment = vdeCheck ? "VDE checked" : event.comment;
        const type = vdeCheck ? "vde" : "comment";
        if (recordToModify) {
            update("skuNotes", {
                id: recordToModify.id,
                data: {
                    comment: comment,
                    type: type,
                },
            });
            setRecordToModify();
            setDialog(false);
        } else {
            create(
                "skuNotes",
                {
                    data: {
                        sku: sku.id,
                        comment: comment,
                        type: type,
                    },
                },
                {
                    onSettled: () => {
                        refresh();
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
            setDialog(false);
            setVdeDialog(false);
        }
    };
    const closeDialog = () => {
        setDialog(false);
        setRecordToModify();
    };

    if (permissionIsLoading) {
        return <></>;
    }
    return (
        <Box>
            {permissions.vote({
                resource: "skus",
                attribute: "edit",
                subject: sku,
            }) && (
                <Box
                    sx={{
                        width: 330,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        my: 2,
                    }}
                >
                    <Button
                        variant="text"
                        onClick={() => setDialog(true)}
                        startIcon={<NoteAltIcon fontSize="large" />}
                        size="small"
                        title={translate("equip.addComments")}
                        aria-label={translate("equip.addComments")}
                    >
                        {translate("equip.addComments")}
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => setVdeDialog(true)}
                        startIcon={<FactCheckIcon fontSize="large" />}
                        size="small"
                        title={translate("equip.addVdeCheck")}
                        aria-label={translate("equip.addVdeCheck")}
                    >
                        {translate("equip.addVdeCheck")}
                    </Button>
                </Box>
            )}

            {dialog && (
                <AddCommentDialog
                    submit={submit}
                    dialog={dialog}
                    record={recordToModify}
                    closeDialog={closeDialog}
                />
            )}
            {vdeDialog && (
                <ConfirmationDialog
                    onConfirm={() => submit(null, true)}
                    onCancel={() => setVdeDialog(false)}
                    label={`${translate("equip.addVdeCheck")} ?`}
                />
            )}
            {recordToModify?.action === "delete" && (
                <ConfirmationDialog
                    onConfirm={() => {
                        deleteOne();
                        setRecordToModify();
                    }}
                    onCancel={() => setRecordToModify()}
                    label={translate("equip.confirmDelete")}
                />
            )}
            <NotesDatagrid setDialog={setDialog} setRecordToModify={setRecordToModify} />
        </Box>
    );
};

export const NotesDatagrid = ({ setRecordToModify, setDialog }) => {
    const sku = useRecordContext();
    const notify = useNotify();
    const sort = { field: "createdAt", order: "DESC" };
    const { data, total, isLoading, error } = useGetList("skuNotes", {
        filter: {
            sku: sku.id,
        },
        pagination: {
            page: 1,
            perPage: 999,
        },
        sort: { field: "id", order: "DESC" },
    });
    if (isLoading) {
        return <></>;
    }
    if (error) {
        handleHttpError(error, notify);
        return <></>;
    }
    return (
        <ListContextProvider
            value={{
                data: data,
                total: total,
                page: 1,
                perPage: 999,
                sort,
            }}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="comment" label="equip.comment" />
                <RaDateField source="createdAt" label="equip.date" />
                <FunctionField
                    label="equip.author"
                    render={(note) => `${note.createdBy.firstname} ${note.createdBy.lastname}`}
                />
                <EditButton setRecordToModify={setRecordToModify} setDialog={setDialog} />
                <DeleteButton setRecordToModify={setRecordToModify} />
            </Datagrid>
        </ListContextProvider>
    );
};

const DeleteButton = ({ setRecordToModify }) => {
    const note = useRecordContext();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }

    return (
        permissions.vote({
            resource: "skuNotes",
            attribute: "delete",
            subject: note,
        }) && (
            <Button
                onClick={() => setRecordToModify({ ...note, action: "delete" })}
                startIcon={<DeleteIcon />}
                color="error"
                title={translate("equip.delete")}
                aria-label={translate("equip.delete")}
            >
                {translate("equip.delete")}
            </Button>
        )
    );
};

const EditButton = ({ setRecordToModify, setDialog }) => {
    const note = useRecordContext();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }

    return note.type === "vde"
        ? null
        : permissions.vote({
              resource: "skuNotes",
              attribute: "edit",
              subject: note,
          }) && (
              <Button
                  onClick={() => {
                      setRecordToModify({ ...note, action: "edit" });
                      setDialog(true);
                  }}
                  startIcon={<EditIcon />}
                  title={translate("equip.edit")}
                  aria-label={translate("equip.edit")}
              >
                  {translate("equip.edit")}
              </Button>
          );
};
