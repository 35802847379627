import { DateField } from "react-admin";
import dayjs from "dayjs";

export const RaDateField = (props) => {
    return (
        <DateField
            {...props}
            transform={(date) =>
                dayjs.isDayjs(date) ? date.toDate() : new Date(date)
            }
        />
    );
};
