import {
    SimpleShowLayout,
    TextField,
    RichTextField,
    useResourceContext,
    usePermissions,
} from "react-admin";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaShowView } from "../components/views/RaShowView";

export const UserShow = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
            <RaShowView
                {...props}
                actions={
                    <ToolbarWithHistory
                        back={true}
                        edit={permissions.vote({ resource, attribute: "edit" })}
                    />
                }
            >
                <SimpleShowLayout>
                    <TextField label="equip.firstname" source="firstname" />
                    <TextField label="equip.lastname" source="lastname" />
                    <RichTextField
                        label="equip.email"
                        source="email"
                        type="email"
                    />
                </SimpleShowLayout>
            </RaShowView>
    );
};
