import * as React from "react";
import {
    Admin,
    Resource,
    resolveBrowserLocale,
    CustomRoutes,
} from "react-admin";
import { Route } from "react-router-dom";

import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import germanMessages from "ra-language-german";

// domain translations
import * as domainMessages from "shared/src/i18n/equip";

import authProvider from "shared/src/authProvider";
import EquipDataprovider from "shared/src/dataprovider";

import UserIcon from "@mui/icons-material/People";
import { UserList } from "./users/UserList";
import { UserShow } from "./users/UserShow";
import { UserEdit } from "./users/UserEdit";
import { UserCreate } from "./users/UserCreate";
import userConverter from "shared/src/converter/userConverter";

import ProductIcon from "@mui/icons-material/PhotoCamera";
import { ProductList } from "./products/ProductList";
import { ProductShow } from "./products/ProductShow";
import { ProductEdit } from "./products/ProductEdit";
import { ProductCreate } from "./products/ProductCreate";
import productConverter from "shared/src/converter/productConverter";

import ManufacturerIcon from "@mui/icons-material/Business";
import { ManufacturerList } from "./manufacturers/ManufacturerList";
import { ManufacturerShow } from "./manufacturers/ManufacturerShow";
import { ManufacturerEdit } from "./manufacturers/ManufacturerEdit";
import { ManufacturerCreate } from "./manufacturers/ManufacturerCreate";

import SupplierIcon from "@mui/icons-material/EmojiTransportation";
import { SupplierList } from "./suppliers/SupplierList";
import { SupplierShow } from "./suppliers/SupplierShow";
import { SupplierEdit } from "./suppliers/SupplierEdit";
import { SupplierCreate } from "./suppliers/SupplierCreate";

import StorageLocationIcon from "@mui/icons-material/Storage";
import { StorageLocationList } from "./storageLocations/StorageLocationList";
import { StorageLocationShow } from "./storageLocations/StorageLocationShow";
import { StorageLocationEdit } from "./storageLocations/StorageLocationEdit";
import { StorageLocationCreate } from "./storageLocations/StorageLocationCreate";

import SkuIcon from "@mui/icons-material/SwitchCamera";
import { SkuList } from "./skus/SkuList";
import { SkuShow } from "./skus/SkuShow";
import { SkuEdit } from "./skus/SkuEdit";
import skuConverter from "shared/src/converter/skuConverter";

import CategoryIcon from "@mui/icons-material/Category";
import { CategoryList } from "./categories/CategoryList";
import { CategoryShow } from "./categories/CategoryShow";
import { CategoryEdit } from "./categories/CategoryEdit";
import { CategoryCreate } from "./categories/CategoryCreate";

import categoryConverter from "shared/src/converter/categoryConverter";

import rentalIcon from "@mui/icons-material/FastForward";
import { RentalList } from "./rental/RentalList";
import { RentalShow } from "./rental/RentalShow";
import { RentalEdit } from "./rental/RentalEdit";
import { RentalCreate } from "./rental/RentalCreate";

import rentalConverter from "shared/src/converter/rentalConverter";
import rentalItemConverter from "shared/src/converter/rentalItemConverter";

import TagIcon from "@mui/icons-material/LocalOffer";
import { TagList } from "./tags/TagList";
import { TagShow } from "./tags/TagShow";
import { TagCreate } from "./tags/TagCreate";
import { TagEdit } from "./tags/TagEdit";

import mediaConverter from "shared/src/converter/mediaConverter";

import AppLayout from "./AppLayout";
import { ProductInfo } from "./productInfo/ProductInfo";

import ChangeLogsIcon from "@mui/icons-material/DataObject";
import { ChangeLogsList } from "./changeLogs/ChangeLogsList";
import changeLogConverter from "shared/src/converter/changeLogConverter";

import RentalLogsIcon from "@mui/icons-material/Assignment";
import { RentalLogsList } from "./rentalLogs/RentalLogsList";
import rentalLogConverter from "shared/src/converter/rentalLogConverter";

import EliminationIcon from "@mui/icons-material/LocalFireDepartment";
import { EliminationList } from "./eliminations/EliminationList";
import { EliminationShow } from "./eliminations/EliminationShow";
import eliminationConverter from "shared/src/converter/eliminationConverter";

import { ClipboardSkuList } from "./skusClipboard/ClipboardSkuList";
import AssignmentIcon from "@mui/icons-material/Assignment";

import TimelineIcon from '@mui/icons-material/Timeline';
import { RentalsTimeline } from "./rentalsTimeline/RentalsTimeline";

import arrivalConverter from "shared/src/converter/arrivalConverter";

const converters = {
    changeLogs: changeLogConverter,
    rentalLogs: rentalLogConverter,
    products: productConverter,
    skus: skuConverter,
    rentals: rentalConverter,
    rentalItems: rentalItemConverter,
    categories: categoryConverter,
    media: mediaConverter,
    users: userConverter,
    eliminations: eliminationConverter,
    arrivals: arrivalConverter,
};
const equipDataprovider = new EquipDataprovider(
    process.env.REACT_APP_BASEURL,
    converters
);

const messages = {
    de: { ...germanMessages, ...domainMessages.de },
    en: { ...englishMessages, ...domainMessages.en },
};
const i18nProvider = polyglotI18nProvider(
    (locale) => (messages[locale] ? messages[locale] : messages.en),
    resolveBrowserLocale(),
    { allowMissing: true }
);
const App = () => {
    return (
        <Admin
            layout={AppLayout}
            title="eQuip"
            dataProvider={equipDataprovider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            disableTelemetry
        >
            {(permissions) => [
                permissions.userMaySeeMenu("rentals") ? (
                    <Resource
                        name="rentals"
                        list={RentalList}
                        show={RentalShow}
                        edit={RentalEdit}
                        create={RentalCreate}
                        options={{ label: "equip.rentals" }}
                        icon={rentalIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("rentalLogs") ? (
                    <Resource
                        name="rentalLogs"
                        list={RentalLogsList}
                        options={{ label: "equip.rentalLogs" }}
                        icon={RentalLogsIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("rentalsTimeline") ? (
                    <Resource
                        name="rentalsTimeline"
                        list={RentalsTimeline}
                        options={{ label: "equip.rentalsTimeline" }}
                        icon={TimelineIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("products") ? (
                    <Resource
                        name="products"
                        show={ProductShow}
                        edit={ProductEdit}
                        create={ProductCreate}
                        options={{ label: "equip.products" }}
                    />
                ) : null,
                permissions.userMaySeeMenu("skus") ? (
                    <Resource
                        name="skus"
                        list={SkuList}
                        show={SkuShow}
                        edit={SkuEdit}
                        options={{ label: "equip.skus" }}
                        icon={SkuIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("categories") ? (
                    <Resource
                        name="categories"
                        list={CategoryList}
                        show={CategoryShow}
                        edit={CategoryEdit}
                        create={CategoryCreate}
                        options={{ label: "equip.products" }}
                        icon={CategoryIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("manufacturers") ? (
                    <Resource
                        name="manufacturers"
                        list={ManufacturerList}
                        show={ManufacturerShow}
                        edit={ManufacturerEdit}
                        create={ManufacturerCreate}
                        options={{ label: "equip.manufacturers" }}
                        icon={ManufacturerIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("suppliers") ? (
                    <Resource
                        name="suppliers"
                        list={SupplierList}
                        show={SupplierShow}
                        edit={SupplierEdit}
                        create={SupplierCreate}
                        options={{ label: "equip.suppliers" }}
                        icon={SupplierIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("storageLocations") ? (
                    <Resource
                        name="storageLocations"
                        list={StorageLocationList}
                        show={StorageLocationShow}
                        edit={StorageLocationEdit}
                        create={StorageLocationCreate}
                        options={{ label: "equip.storageLocations" }}
                        icon={StorageLocationIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("tags") ? (
                    <Resource
                        name="tags"
                        list={TagList}
                        show={TagShow}
                        create={TagCreate}
                        edit={TagEdit}
                        options={{ label: "equip.tags" }}
                        icon={TagIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("changeLogs") ? (
                    <Resource
                        name="changeLogs"
                        list={ChangeLogsList}
                        options={{ label: "equip.changeLogs" }}
                        icon={ChangeLogsIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("eliminations") ? (
                    <Resource
                        name="eliminations"
                        list={EliminationList}
                        show={EliminationShow}
                        options={{ label: "equip.eliminations" }}
                        icon={EliminationIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("users") ? (
                    <Resource
                        name="users"
                        list={UserList}
                        show={UserShow}
                        edit={UserEdit}
                        create={UserCreate}
                        options={{ label: "equip.users" }}
                        icon={UserIcon}
                    />
                ) : null,
                permissions.userMaySeeMenu("clipboard") ? (
                    <Resource
                        name="clipboard"
                        list={ClipboardSkuList}
                        options={{ label: "equip.clipboard" }}
                        icon={AssignmentIcon}
                    />
                ) : null,

                <Resource
                    name="product_info"
                    show={ProductShow}
                    options={{ label: "equip.products" }}
                    icon={ProductIcon}
                />,
                <CustomRoutes>
                    <Route path="/products" element={<ProductList />} />
                    <Route path="/product_info" element={<ProductInfo />} />
                </CustomRoutes>,
            ]}
        </Admin>
    );
};

export default App;
