import dayjs from "dayjs";

const changeLogConverter = {
    convertIn: (responseData) => {
        if (responseData.referenceName) {
            try {
                responseData.referenceName = JSON.parse(
                    responseData.referenceName
                );
            } catch (e) {}
        }
        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }
        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        return responseData;
    },
};

export default changeLogConverter;
