import {
    TextField,
    Filter,
    TextInput,
    usePermissions,
    useResourceContext,
    FunctionField,
    useTranslate,
} from "react-admin";
import { DefaultPagination } from "../components/DefaultPagination";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { DatagridWithHistory } from "../components/DatagridWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaDateField } from "../components/fields/Ra/RaDateField";
import { RaEditButton } from "../components/buttons/RaEditButton";
import { RaDeleteButton } from "../components/buttons/RaDeleteButton";
import { RaListView } from "../components/views/RaListView";

const ListFilter = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                title={translate("equip.name")}
                label="equip.name"
                source="search"
                alwaysOn
            />
            <RaSelectInput
                source="state"
                choices={[
                    {
                        id: "rental_inprogress",
                        name: "equip.rental_inprogress",
                    },
                    { id: "open", name: "equip.open" },
                    {
                        id: "return_inprogress",
                        name: "equip.return_inprogress",
                    },
                    {
                        id: "partially_returned",
                        name: "equip.partially_returned",
                    },
                    { id: "returned", name: "equip.returned" },
                    { id: "closed", name: "equip.closed" },
                ]}
                alwaysOn
            />
            <RaSelectInput
                label="equip.dunning.level"
                source="dunningLevel"
                choices={[
                    { id: 0, name: "equip.dunning.level_0" },
                    { id: 1, name: "equip.dunning.level_1" },
                    { id: 2, name: "equip.dunning.level_2" },
                    { id: 3, name: "equip.dunning.level_3" },
                ]}
                alwaysOn
            />
        </Filter>
    );
};

export const RentalList = (props) => {
    const resource = useResourceContext();
    const translate = useTranslate();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaListView
            {...props}
            actions={<ToolbarWithHistory export={true} />}
            filters={<ListFilter />}
            pagination={<DefaultPagination />}
            perPage={100}
            sort={{ field: "id", order: "DESC" }}
        >
            <DatagridWithHistory bulkActionButtons={false}>
                <FunctionField
                    label="ID"
                    source="id"
                    render={(record) =>
                        record.edispoId ? `${record.id} / ${record.edispoId}` : record.id
                    }
                />
                <TextField label="equip.name" source="name" />
                <TextField label="equip.renter" source="actualRentalProcess.renter.fullName" />
                <TextField label="equip.items" source="itemCount" sortable={false} />
                <FunctionField
                    label="equip.state"
                    source="state"
                    render={(record) => translate("equip." + record.state)}
                />
                <RaDateField label="equip.startDate" source="startDate" />
                <RaDateField label="equip.endDate" source="endDate" />
                <RaDateField
                    label="equip.signatureDate"
                    source="actualRentalProcess.signatureDate"
                />
                <FunctionField
                    label="equip.dunning.label"
                    render={(record) => {
                        let result = "";

                        if (record.daysOverdue > 0) {
                            result += `${record.daysOverdue} ${translate(
                                "equip." + (record.daysOverdue === 1 ? "day" : "days")
                            )}`;
                        } else if (null !== record.dunning) {
                            result += translate("equip.dunning.level_" + record.dunning.level);
                        }

                        if (null !== record.dunning && null !== record.daysOverdueDunning) {
                            result += ` (${translate(
                                "equip.dunning.level_" + record.dunning.level
                            )} ${record.daysOverdueDunning} ${translate(
                                "equip." + (record.daysOverdueDunning === 1 ? "day" : "days")
                            )})`;
                        }

                        return result;
                    }}
                    sortBy="endDate"
                />
                <RaEditButton />
                <RaDeleteButton />
            </DatagridWithHistory>
        </RaListView>
    );
};
