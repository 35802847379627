import {
    List,
    useResourceContext,
    usePermissions,
    FunctionField,
    useLocaleState,
    useTranslate,
    TextField,
    Filter,
    DateInput,
    Datagrid,
    ReferenceInput,
    TextInput,
} from "react-admin";
import { DefaultPagination } from "../components/DefaultPagination";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { AccessRightsError } from "../components/AccessRightsError";
import { RaSelectInput } from "../components/inputs/Ra/RaSelectInput";
import { RaAutocompleteInput } from "../components/inputs/Ra/RaAutocompleteInput";
import { RaDateField } from "../components/fields/Ra/RaDateField";

const ChangeLogsFilter = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <RaSelectInput
                alwaysOn
                label="equip.resource"
                source="referenceType"
                choices={[
                    {
                        id: "App\\Entity\\Sku",
                        name: translate("equip.sku"),
                    },
                    {
                        id: "App\\Entity\\Product",
                        name: translate("equip.product"),
                    },
                ]}
            />
            <TextInput
                title={translate("equip.field")}
                label="equip.field"
                source="field"
                alwaysOn
            />
            <DateInput
                title={translate("equip.from")}
                source="fromCreatedAt"
                label="equip.from"
                alwaysOn
            />
            <DateInput
                title={translate("equip.to")}
                source="toCreatedAt"
                label="equip.to"
                alwaysOn
            />
            <ReferenceInput
                alwaysOn
                source="createdBy"
                reference="users"
                sort={{ field: "userIdentifier", order: "ASC" }}
            >
                <RaAutocompleteInput
                    label="equip.user"
                    field="userIdentifier"
                />
            </ReferenceInput>
        </Filter>
    );
};

export const ChangeLogsDatagrid = () => {
    const translate = useTranslate();
    const [locale] = useLocaleState();

    const transformValue = (key, value) => {
        if (key === "availability") {
            value = translate(`equip.sku_availability_${value}`);
        }

        if (key === "status") {
            value = translate(`equip.sku_status_${value}`);
        }

        if (value?.date) {
            value = new Date(value.date).toLocaleDateString();
        }

        return value;
    };

    return (
        <Datagrid bulkActionButtons={false}
                  rowClick={(id, resource, record) => {
                      switch (record.referenceType.split(`App\\Entity\\`)[1]) {
                          case "Product":
                              return `/products/${record.referenceId}/show`;
                          case "Sku":
                              return `/skus/${record.referenceId}/show`;
                          default:
                              return false;
                      }
                  }}
        >
            <RaDateField source="createdAt" label="equip.date" />
            <TextField label="Id" source="referenceId" />
            <FunctionField
                label="equip.resource"
                render={(record) => {
                    let result= record.referenceType.split(`App\\Entity\\`)[1];
                        result += record.referenceName
                            ? ": " + record.referenceName[locale]
                            : "";

                    return result;
                }}
            />

            <FunctionField
                label="equip.field"
                render={(record) =>
                    Object.keys(JSON.parse(record.changes)).map((key) => (
                        <p key={record.id + key}>{translate(`equip.${key}`)}</p>
                    ))
                }
            />
            <FunctionField
                label="equip.from"
                render={(record) =>
                    Object.keys(JSON.parse(record.changes)).map((key) => {
                        const changes = JSON.parse(record.changes);
                        return (
                            <p key={record.id + key}>
                                {transformValue(key, changes[key][0])}
                            </p>
                        );
                    })
                }
            />
            <FunctionField
                label="equip.changed_to"
                render={(record) =>
                    Object.keys(JSON.parse(record.changes)).map((key) => {
                        const changes = JSON.parse(record.changes);
                        return (
                            <p key={record.id + key}>
                                {transformValue(key, changes[key][1])}
                            </p>
                        );
                    })
                }
            />
            <RaDateField source="createdAt" label="equip.date" showTime={true} />
            <FunctionField
                label="equip.user"
                render={(record) =>
                    record.createdBy ? record.createdBy.fullName : "System"
                }
            />
        </Datagrid>
    );
};

export const ChangeLogsList = (props) => {
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <List
            {...props}
            actions={<ToolbarWithHistory export={true} />}
            pagination={<DefaultPagination />}
            perPage={100}
            filters={<ChangeLogsFilter />}
            sort={{ field: "createdAt", order: "DESC" }}
        >
            <ChangeLogsDatagrid />
        </List>
    );
};
