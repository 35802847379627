export const queryFromHrefToFilter = (hash) => {
    const isFilter = hash.includes("filter=");
    if (isFilter) {
        const hrefFilter = hash.split("filter=")[1].split("&order")[0];
        const stringFilter = decodeURIComponent(hrefFilter)
        const filter = JSON.parse(stringFilter);
        if (filter) {
            return filter;
        }
        return null;
    }
    return null;
};

export const queryToFilter = (hash) => {
    const filter =
        localStorage?.["RaStore.products.listParams"] &&
        JSON.parse(localStorage["RaStore.products.listParams"])?.filter;
    if (filter) {
        return filter;
    }
    return {};
};

export const queryEditToCategory = (hash) => {
    try {
        const categoryId = hash.split("categories/")[1].split("?")[0];
        return { category: categoryId };
    } catch (err) {
        return null;
    }
};

export const queryCreateToCategory = (hash) => {
    try {
        const categoryId = hash.split("create/")[1].split("?")[0];
        return { category: categoryId };
    } catch (err) {
        return null;
    }
};

export const queryToUrlOrigin = () => {
    if (window.location.href.includes("from")) {
        const links = window.location.href.split("from=");
        const origin = links[links.length - 1];
        return origin;
    }
    return null;
};

export const queryToExpanded = (hash) => {
    if (hash.includes("expanded")) {
        const decodedUrl = decodeURI(hash);
        const expandedString = decodedUrl
            .split("expanded=")[1]
            .split("&")[0]
            .split("?")[0];
        return JSON.parse(expandedString);
    }
    return [];
};

export const queryToView = (url) => {
    let view;
    const hash = url ? url : window.location.hash;
    const firstParam = hash.split("/")[2]?.split("/")[0].split("?")[0];
    const secondParam = hash.split("/")[3]?.split("/")[0].split("?")[0];
    const id = parseInt(firstParam);
    switch (true) {
        case secondParam === "show":
            view = "ra.action.show";
            break;
        case id > -1:
            view = "ra.action.edit";
            break;
        case firstParam === "create":
            view = "ra.action.create";
            break;
        default:
            view = "equip.viewAll";
            break;
    }
    return view;
};

export const queryToAttribute = () => {
    const view = queryToView();
    switch (view) {
        case "ra.action.show":
            return "read";
        case "ra.action.edit":
            return "edit";
        case "ra.action.create":
            return "create";
        default:
            return "read";
    }
};

export const queryToRedirectBack = () => {
    const url = window.location.href;
    const linkHistory = url.split("from=");
    linkHistory.shift();
    const redirect = linkHistory.join("from=");
    const platform = url.includes("mobile#") ? "mobile#" : "#";
    if (redirect) {
        window.location.href = `${window.location.origin}/${platform}${redirect}`;
    }
};

export const queryToNoHashLink = () => {
    return window.location.href.split("#")[1] || "";
};

export const queryToHistory = () => {
    const links = queryToNoHashLink()?.split("?from=")?.reverse();
    const history = [];
    links.forEach((link, index) => {
        let hrefParts = links.slice(0, index + 1).reverse();
        history.push({ href: "#" + hrefParts.join("?from=") });
    });
    return history;
};
