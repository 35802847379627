import { TextInput, useTranslate } from "react-admin";

export const RaTextInput = ({ label, source, ...props }) => {
    const translate = useTranslate();

    return (
        <TextInput
            label={label || `equip.${source}`}
            source={source}
            title={translate(label || `equip.${source}`)}
            {...props}
        />
    );
};
