import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import {
    DeleteWithConfirmButton,
    Edit,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useTranslate,
    usePermissions,
    useResourceContext,
} from "react-admin";
import { Grid, Button } from "@mui/material";
import {
    queryEditToCategory,
    queryToAttribute,
    queryToRedirectBack,
    queryToUrlOrigin,
} from "shared/src/utils/decodeQuery";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { CategoryTree } from "./components/CategoryTree";
import { AccessRightsError } from "../components/AccessRightsError";
import { Box } from "@mui/system";

export const CategoryEdit = (props) => {
    const [disableDelete, setDisableDelete] = useState(true);
    const [filter, setFilter] = useState();
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const handleFilterChange = (filterInput) => {
        setFilter(filterInput.category ? filterInput : null);

        navigate({
            pathname: `/categories/${filterInput?.category}`,
            search: `?from=${queryToUrlOrigin()}`,
        });

        toggleDeleteBtn(filterInput?.category);
    };

    const toggleDeleteBtn = useCallback(
        (id) => {
            dataProvider
                .getManyReference("products", {
                    record: null,
                    filter: {
                        category: id,
                    },
                    pagination: {
                        page: 1,
                        perPage: 1,
                    },
                    sort: {
                        field: "id",
                        order: "ASC",
                    },
                })
                .then((response) => {
                    if (response.data.length === 0) {
                        dataProvider
                            .getChildNodes("categories", { parentId: id })
                            .then((response) => {
                                if (response.data.length === 0) {
                                    setDisableDelete(false);
                                } else {
                                    setDisableDelete(true);
                                }
                            })
                            .catch((e) => {});
                    } else {
                        setDisableDelete(true);
                    }
                })
                .catch((e) => {});
        },
        [dataProvider]
    );

    useEffect(() => {
        const queryFilter = queryEditToCategory(window.location.hash);
        setFilter(queryFilter);
        toggleDeleteBtn(queryFilter?.category);
    }, [toggleDeleteBtn]);

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <Grid container spacing={2}>
            <Grid item xxl={2} lg={3} md={4} sm={5} xs={6}>
                <CategoryTree
                    handleFilterChange={handleFilterChange}
                    filter={filter}
                    id={queryEditToCategory(window.location.hash)?.category}
                />
            </Grid>
            <Grid item xxl={10} lg={9} md={8} sm={7} xs={6}>
                <Edit
                    mutationMode="pessimistic"
                    {...props}
                    actions={<ToolbarWithHistory show={true} />}
                >
                    <SimpleForm toolbar={<EditToolbar disabledelete={disableDelete} />}>
                        <TextInput
                            label="equip.name"
                            source="name"
                            validate={[required()]}
                            title={translate("equip.name")}
                        />
                    </SimpleForm>
                </Edit>
            </Grid>
        </Grid>
    );
};

const EditToolbar = (props) => {
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }
    return (
        <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
            <Box>
                <SaveButton title={translate("equip.save")} aria-label={translate("equip.save")} />
                {permissions.vote({
                    resource: "categories",
                    attribute: "delete",
                }) && (
                    <DeleteWithConfirmButton
                        title={translate("equip.delete")}
                        aria-label={translate("equip.delete")}
                        sx={{ marginLeft: 3 }}
                        confirmContent={translate("equip.confirmDelete")}
                        disabled={props.disabledelete}
                    />
                )}
            </Box>
            <Button
                variant="text"
                onClick={queryToRedirectBack}
                title={translate("equip.cancel")}
                aria-label={translate("equip.cancel")}
            >
                {translate("ra.action.cancel")}
            </Button>
        </Toolbar>
    );
};
