import {convertFileToBase64} from "../utils/uploadHandler";

const userConverter = {
    convertOut: (object) => {
        // Freshly dropped file is a File object and must be converted to base64 string
        if (object.signature?.rawFile) {
            return new Promise((resolve) => {
                convertFileToBase64(object.signature).then((base64File) => {
                    resolve({
                        ...object,
                        signature: base64File,
                    });
                });
            });
        } else if (object.signature?.src) {
            object.signature = object.signature.src;
        }

        delete object.id;
        delete object.fullName;

        return object;
    },
    convertIn: (responseData) => {
        if (responseData?.signature) {
            responseData.signature = {title: responseData.fullName, src: responseData.signature};
        }

        return responseData;
    }
};

export default userConverter;
