import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            structuralSharing: false,
        },
        mutations: {
            retryDelay: 10000,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();

window.onerror = function (msg, url, line, columnNo, error) {
    const request = new Request("/error/log", {
        method: "POST",
        body: JSON.stringify({
            context: navigator.userAgent,
            details:
                url +
                ":" +
                line +
                " (" +
                columnNo +
                ")\n\n" +
                msg +
                (error ? "\n\n" + error?.stack : ""),
        }),
        headers: new Headers({ "Content-Type": "application/json", Accept: "application/json" }),
    });
    fetch(request);
};
